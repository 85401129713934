import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage } from '../../../../services/helpers.js';
import DelayedRedirect from '../../../../utils/DelayedRedirect';
import { Transition } from '../../../../utils/ModalHelpers';
import { Button, Link, FormControlLabel, Checkbox } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { LanguagesWithLanguageCode } from "./../../../../utils/constants";
import moment from 'moment';
import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

const snippetTypes = Object.freeze({
    SnippetMediaFileNames: 5,
    SnippetPDFLibrary: 6
});

const SnippetEditing = (props) => {
    const { snippetId, language, resources } = props;
    const [state, setState] = useState({
        id: snippetId === undefined ? 0 : snippetId,
        type: 0,
        language: language,
        title: "",
        content: "",
        isDefaultLocked: false,

        createdOn: "",
        createdByName: "",
        createdByAvatar: "",

        loading: snippetId !== undefined,
        requestInProgress: false,
        redirect: null,
        open: true,

        snippetWasCreated: false,
    });

    useEffect(() => {
        getData(state.id);
    }, [state.id]);

    const getData = (snippetId) => {
        fetch(`/api/mobopro/v1/snippets/${snippetId}`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                response.json().then(result => {
                    if (!response.ok) {
                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                    }
                    else {
                        setState(prevState => ({
                            ...prevState,
                            type: result.type,
                            language: result.language,
                            title: result.title,
                            content: result.content,
                            isDefaultLocked: result.isDefaultLocked,

                            createdOn: result.createdOn,
                            createdByName: result.createdBy.firstName + " " + result.createdBy.lastName,
                            createdByAvatar: result.createdBy.avatar,
                            fetchingData: false,
                            loading: false,
                        }));
                    }
                });
            });
    }

    const handleClose = () => {
        setState(prevState => ({ ...prevState, open: false, redirect: "/snippets" }));
    };

    const handleChange = e => {
        if (e.target.name === "isDefaultLocked") { setState(prevState => ({ ...prevState, isDefaultLocked: e.target.checked })); }
        else { setState(prevState => ({ ...prevState, [e.target.name]: e.target.value })); }
        checkIfRequestIsValid(false, state.typeValue);
    };

    const checkIfRequestIsValid = (displayErrorMessage, typeValue) => {
        var isValidRequest = true;

        if (state.title.trim().length <= 0) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidTitle"], "error");
            isValidRequest = false;
        }

        if ((!state.content || state.content.trim().length <= 0) && state.type !== snippetTypes.SnippetMediaFileNames && state.type !== snippetTypes.SnippetPDFLibrary) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidSnippet"], "error");
            isValidRequest = false;
        }

        if (typeValue === 0) {
            DisplayMessage(resources["InvalidType"], "error");
            return;
        }

        setState(prevState => ({ ...prevState, requestInProgress: !isValidRequest }));
        return isValidRequest;
    }

    const saveSnippet = () => {
        setState(prevState => ({ ...prevState, requestInProgress: true }));

        if (!checkIfRequestIsValid(true)) return;

        var newContent = state.content;
        if (state.type === snippetTypes.SnippetMediaFileNames || state.type === snippetTypes.SnippetPDFLibrary) {
            newContent = null;
        }

        const json = JSON.stringify({
            type: state.type,
            language: state.language,
            title: state.title,
            content: newContent,
            isDefaultLocked: state.isDefaultLocked
        });

        fetch('/api/mobopro/v1/snippets/' + state.id, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });

            }
            else {
                DisplayMessage(resources["SnippetSuccessfullyEdited"], "success");
                setState(prevState => ({ ...prevState, snippetWasCreated: true, redirect: "/snippets" }));
            }
        })
    }

    // render created by
    const renderCreatedByInfo = () => {
        if (state.id > 0) {
            return (<Form.Group className="d-flex mt-4">
                <div className="w-100 text-center">
                    <span className=" mr-2">
                        <img className="rounded-circle" alt="user-avatar" src={state.createdByAvatar != null ? state.createdByAvatar : "/static/media/defaultavatar.02624da3.png"} width="35" />
                    </span>
                    <span>
                        {`${resources["CreatedOn"]} ${moment(state.createdOn).format('DD/MM/YYYY').toLocaleString()} ${resources["By"]} ${state.createdByName}`}
                    </span>
                </div>
            </Form.Group>);
        }
        return;
    }

    const renderTypesDropdown = () => {
        var snippetsTypes = [
            { name: resources["SnippetServiceQuote"], value: 1 },
            { name: resources["SnippetExternalDialog"], value: 2 },
            { name: resources["SnippetExternalDossierChat"], value: 3 },
            { name: resources["SnippetInternalDossierChat"], value: 4 },
            { name: resources["SnippetMediaFileNames"], value: 5 },
            { name: resources["SnippetPDFLibrary"], value: 6 }].sort((a, b) => (a.name > b.name) ? 1 : -1);

        snippetsTypes.unshift({ name: resources["DefaultDropdownValue"], value: 0 });

        snippetsTypes = snippetsTypes.map(x => <option key={x.value} value={x.value}> {x.name}</option>);
        return snippetsTypes;
    }

    const handleChangeType = e => {
        setState(prevState => ({ ...prevState, type: parseInt(e.target.value), isDefaultLocked: false, file: undefined, base64file: "" }));
        checkIfRequestIsValid(false, parseInt(e.target.value));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        saveSnippet();
    };

    const generateLanguageOptions = () => {
        var languageOptions = LanguagesWithLanguageCode.map((x) => (
          <option key={x.code} value={x.code}>
            {x.Name}
          </option>
        ));
    
        return languageOptions;
      };

    const renderForm = () => {
        const { language, title, content, type, isDefaultLocked } = state;
        var helperTextTitle = title.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
        var helperTextContent = !content || content.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";

        return (
            <div>
                <Paper elevation={3} className="p-4 mt-3 mb-3">
                    <Form onSubmit={handleSubmit} >
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <FormControl className="col-6 helper-text-red" variant="outlined" onChange={handleChangeType}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-type-native-simple">{resources["Type"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    disabled={true}
                                    id="snippets-type"
                                    native
                                    label={resources["Type"]}
                                    value={type}
                                    inputProps={{
                                        name: 'type',
                                        id: 'snippets-type',
                                    }}>
                                    {renderTypesDropdown()}
                                </Select>
                            </FormControl>
                            <div className="col-3"></div>
                        </Form.Group>

                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <FormControl className="col-6 helper-text-red" variant="outlined" onChange={handleChange}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-type-native-simple">{resources["Language"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    id="snippets-language"
                                    native
                                    label={resources["Language"]}
                                    value={language}
                                    inputProps={{
                                        name: 'language',
                                        id: 'snippets-language',
                                    }}>
                                    {generateLanguageOptions()}
                                </Select>
                            </FormControl>
                            <div className="col-3"></div>
                        </Form.Group>
                        <Form.Group className="d-flex"  >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextTitle} id="snippet-title" value={title} label={resources["Title"]}
                                    helperText={resources["InputOfTitleIsMandatory"]}
                                    variant="outlined" name="title" onChange={handleChange} inputProps={{ maxLength: 100 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className={type === snippetTypes.SnippetPDFLibrary ? "d-none" : "d-flex"} >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextContent} id="snippet-contnet" value={content} multiline minRows={4} label={resources["Snippet"]}
                                    helperText={resources["InputOfSnippetIsMandatory"]} autoComplete="off"
                                    variant="outlined" name="content" onChange={handleChange}
                                    disabled={type === snippetTypes.SnippetMediaFileNames} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className={type !== snippetTypes.SnippetMediaFileNames && type !== snippetTypes.SnippetPDFLibrary ? "d-none" : "d-flex"} >
                            <div className="col-3 "> </div>
                            <ThemeProvider theme={SelectTheme}>
                                <FormControlLabel
                                    control={<Checkbox id="snippet-isDefaultLocked" checked={isDefaultLocked} onChange={handleChange} name="isDefaultLocked" color="primary" />}
                                    label={resources["IsDefaultLocked"]} />
                            </ThemeProvider>
                        </Form.Group>
                        <Form.Group className={type === snippetTypes.SnippetPDFLibrary ? "d-flex justify-content-center" : "d-none"}>
                            <ThemeProvider theme={SelectTheme}>
                                <Link href={content} title={resources["ViewPdfDocument"]} target='_blank'>
                                    {resources["ViewPdfDocument"]}
                                </Link>
                            </ThemeProvider>
                        </Form.Group>

                        {renderCreatedByInfo()}

                    </Form>
                </Paper>

            </div >
        );
    }

    const renderMain = () => {
        const { loading } = state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {renderForm()}
        </div>;
    }

    const { redirect, open, snippetWasCreated, outletId, requestInProgress } = state;

    return (
        <div>
            {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: snippetWasCreated, outletId } }} delay={250} />}
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className="position-sticky ">
                    <Toolbar>
                        <IconButton id="close-add-snippet" edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" style={{ flex: 1 }}>
                            {resources["EditSnippetTitle"]}
                        </Typography>
                        <Button id="snippet-save" color="inherit" disabled={requestInProgress} onClick={saveSnippet}>{resources["Save"]}</Button>
                    </Toolbar>
                </AppBar>
                <div className="container">
                    {renderMain()}
                </div>
            </Dialog>
        </div >
    );
}

export default SnippetEditing;