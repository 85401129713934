import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import PauseReasonsTable from '../components/WorkshopPlanning/General/PauseReasons/PauseReasonsTable';
import PauseReasonCreation from '../components/WorkshopPlanning/General/PauseReasons/PauseReasonCreation';
import { Permissions } from '../utils/Permissions';

export default function PauseReasonsRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/pausereasons' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<PauseReasonsTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} pauseReasonWasCreated={props && props.location && props.location.state && props.location.state.pauseReasonWasCreated} type={props && props.location && props.location.state && props.location.state.type} />)
            }} />
            <Switch>
                <Route exact path='/pausereasons/new' render={(props) => (<PauseReasonCreation resources={resources} type={props && props.location && props.location.state && props.location.state.type} pauseReasonId={props && props.location && props.location.state && props.location.state.pauseReasonId} />)} />
                <Route exact path='/pausereasons/:pauseReasonId' render={(props) => {
                    return (<PauseReasonCreation resources={resources} editedPauseReasonId={props.match.params.editedPauseReasonId} editedPauseReason={props.match.params.editedPauseReason} {...props} />)
                }} />
            </Switch>
        </>
    )
}