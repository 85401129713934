import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage } from '../../../../services/helpers.js';
import DelayedRedirect from '../../../../utils/DelayedRedirect';
import { Transition } from '../../../../utils/ModalHelpers';

import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

export default class MobilitySolutionsEditing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobilitySolutionId: this.props.mobilitySolutionId !== undefined ? parseInt(this.props.mobilitySolutionId) : 0,
            id: 0,
            outletId: this.props.outletId !== undefined ? parseInt(this.props.outletId) : 0,
            outletName: "",

            language: "nl",
            description: "",
            isVisibleInPlanning: false,
            shortDescription: "",

            modifiedOn: "",
            modifiedByName: "",
            modifiedByAvatar: "",
            selectedOutletId: 0,
            loading: this.props.editedMobilitySolutionId !== undefined,
            redirect: false,
            accessibleMoboOutlets: [],
            editedMobilitySolutionId: this.props.editedMobilitySolutionId,
            requestInProgress: false,
            redirect: null,
            open: true,
            mobilitySolutionWasModified: false,
            mobilitySolutions: this.props.mobilitySolutions !== undefined ? this.props.mobilitySolutions : [],
        };
    }

    componentDidMount() {
        this.getMobilitySolutionById(this.state.mobilitySolutionId, this.state.outletId);
    }

    handleClose = () => {
        this.setState({ open: false, redirect: "/mobilitysolutions" });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleChangeCheckBox = e => {
        this.setState({ [e.target.name]: e.target.checked });
    };

    handleTyping = e => {
        this.setState({ requestInProgress: false });
    };

    handleSubmit = () => {
        this.saveEditedMobilitySolution();
    }

    getMobilitySolutionById = (mobilitySolutionId, outletId) => {
        if (outletId <= 0 || mobilitySolutionId <= 0) return;

        if (this.state.mobilitySolutions !== [] && this.state.mobilitySolutions.length > 0) {

            for (let index = 0; index < this.state.mobilitySolutions.length; index++) {
                var item = this.state.mobilitySolutions[index];
                    if (item.id === mobilitySolutionId) {
                    this.setState({
                        language: item.language,
                        description: item.description,
                        isVisibleInPlanning: item.isVisibleInPlanning,
                        shortDescription: item.shortDescription,
                        outletId: item.moboOutletId,

                        mobilitySolution: item,
                        id: item.id,
                        loading: false,
                        modifiedOn: item.updatedOn !== null ? item.updatedOn : item.createdOn,
                        modifiedByName: item.updatedBy !== null ? item.updatedBy.firstName + " " + item.updatedBy.lastName : item.createdBy.firstName + " " + item.createdBy.lastName,
                        modifiedByAvatar: item.updatedBy != null ? item.updatedBy.avatar : item.createdBy.avatar,
                    });
                }
            }

        }
        else {
            const url = `/api/mobopro/v1/mobooutletmobilitysolutions/${outletId}/`;
            fetch(url, {
                method: 'get',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    })
                } else {
                    response.json().then(result => {

                        var existsMobilitySolution = false;
                        var rowData = [];

                        result.forEach(function (row) {

                            if (row.id === mobilitySolutionId) {
                                existsMobilitySolution = true;
                                rowData = row;
                            }
                        });

                        if (existsMobilitySolution) {
                            this.setState({
                                language: rowData.language,
                                description: rowData.description,
                                isVisibleInPlanning: rowData.isVisibleInPlanning,
                                shortDescription: rowData.shortDescription,
                                outletId: rowData.moboOutletId,

                                mobilitySolution: rowData,
                                id: rowData.id,
                                loading: false,
                                modifiedOn: rowData.updatedBy !== null ? rowData.updatedOn : rowData.createdOn,
                                modifiedByName: rowData.updatedBy !== null ? rowData.updatedBy.firstName + " " + rowData.updatedBy.lastName : rowData.createdBy.firstName + " " + rowData.createdBy.lastName,
                                modifiedByAvatar: rowData.updatedBy != null ? rowData.updatedBy.avatar : rowData.createdBy.avatar,
                            });
                        }
                        else {
                            this.setState({ open: false, redirect: "/mobilitysolutions" });
                        }
                    });
                }
            });
        }

    }

    saveEditedMobilitySolution = () => {
        this.setState({ requestInProgress: true });
        let resources = this.props.resources;

        if (this.state.description.trim().length <= 0) {
            DisplayMessage(resources["InvalidDescripton"], "error");
            return;
        }

        const { language, description, isVisibleInPlanning, shortDescription } = this.state;

        var json = JSON.stringify({
            language: language,
            description: description,
            isVisibleInPlanning: isVisibleInPlanning,
            shortDescription: shortDescription,
        });

        const url = `/api/mobopro/v1/mobooutletmobilitysolutions/${this.state.mobilitySolutionId}`;
        fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                if (!response.ok) {
                    response.json().then(result => {

                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                    });

                }
                this.setState({ requestInProgress: false });
                return response.json();
            })
            .then(result => {
                if (result.id !== undefined) {
                    this.setState({
                        updateInProgress: false,
                        mobilitySolutionWasModified: true,
                        redirect: "/mobilitysolutions"
                    });

                    DisplayMessage(resources["MobilitySolutionWasSaved"], "success");
                }
            });
    }

    renderOutlets = () => {

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outlets = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outlets;
    }

    renderModifiedByInfo = () => {
        let resources = this.props.resources;
        if (this.state.id > 0 && this.state.modifiedOn !== null) {
            return (<Form.Group className="d-flex mt-3">
                <div className="w-100">
                    <span className=" mr-2">
                        <img className="rounded-circle" alt="user-avatar" src={this.state.modifiedByAvatar != null ? this.state.modifiedByAvatar : "/static/media/defaultavatar.02624da3.png"} width="35" />
                    </span>
                    <span>
                        {`${resources["ModifiedOn"]} ${moment(this.state.modifiedOn).format('DD/MM/YYYY').toLocaleString()} ${resources["By"]} ${this.state.modifiedByName}`}
                    </span>
                </div>
            </Form.Group>);
        }
        return;
    }

    renderForm() {
        const { outletId, language, description, isVisibleInPlanning, shortDescription } = this.state;
        var helperTextDescription = description.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
        var resources = this.props.resources;

        return (
            <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Form>
                    <Form.Group style={{ display: "flex" }}>
                        <div className="col-md-3"></div>
                        <FormControl disabled={true} className="col-md-6 helper-text-red" variant="outlined" onChange={this.handleChangeOutlet}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="mobilitysolutions-outlet"
                                native
                                label={resources["Outlet"]}
                                value={outletId}
                                inputProps={{
                                    name: 'outlet',
                                    id: 'outlined-outlet-native-simple',
                                }}>
                                {this.renderOutlets()}
                            </Select>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChange}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-type-native-simple">{resources["Language"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="mobilitysolution-language"
                                native
                                label={resources["Language"]}
                                value={language}
                                inputProps={{
                                    name: 'language',
                                    id: 'mobilitysolution-language',
                                }}>
                                <option value={'nl'} key={'nl'}>{"Nederlands"}</option>
                                <option value={'fr'} key={'fr'}>{"Français"}</option>
                                <option value={'en'} key={'en'}>{"English"} </option>
                                <option value={'de'} key={'de'}>{"Deutsch"}</option>
                                <option value={'cs'} key={'cs'}>{"Czech"}</option>
                            </Select>
                        </FormControl>
                        <div className="col-3"></div>
                    </Form.Group>
                    
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className="col-6 helper-text-red" id="mobilitysolutions-shortDescription"
                                value={shortDescription} label={resources["ShortDescription"]}
                                variant="outlined" name="shortDescription" onChange={this.handleChange} inputProps={{ maxLength: 25 }} />
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className={"col-6 " + helperTextDescription} id="mobilitysolutions-description"
                                value={description} label={resources["Description"]}
                                helperText={resources["InputOfDescriptionIsMandatory"]}
                                variant="outlined" name="description" onChange={this.handleChange} inputProps={{ maxLength: 200 }} />
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group style={{ display: "flex" }} >
                        <div className="col-md-3 "> </div>
                        <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                            control={<Checkbox id="mobilitysolutions-is-active" checked={isVisibleInPlanning} onChange={this.handleChangeCheckBox} name="isVisibleInPlanning" color="primary" />}
                            label={resources["IsVisibleInPlanning"]} />
                        <div className="col-md-4"> </div>
                    </Form.Group>

                    {this.renderModifiedByInfo()}
                </Form>
            </Paper>
        );
    }

    renderMain() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {this.renderForm()}
        </div>;
    }

    render() {
        const { redirect, open, mobilitySolutionWasModified, outletId, description } = this.state;
        const { resources } = this.props;
        var isDisabled = description.trim().length <= 0;

        return (
            <div>
                {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: mobilitySolutionWasModified, outletId } }} delay={250} />}
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton id="close-edit-MobilitySolution" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["EditMobilitySolution"]}
                            </Typography>
                            <Button id="mobilitysolutions-save" disabled={isDisabled} color="inherit" onClick={this.handleSubmit}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        {this.renderMain()}
                    </div>
                </Dialog>
            </div >
        );
    }
}