import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import Close from "@material-ui/icons/Close";

const AppBarComponent = ({
  handleClose,
  handleSubmit,
  idName,
  disabled,
  title,
  resources,
}) => {
  return (
    <AppBar className="position-sticky">
      <Toolbar>
        <IconButton
          id={"close-new-" + idName}
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <Close />
        </IconButton>
        <Typography variant="h6" style={{ flex: 1 }}>
          {title}
        </Typography>
        <Button
          id={idName + "-save"}
          color="inherit"
          disabled={disabled}
          onClick={handleSubmit}
        >
          {resources["Save"]}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
