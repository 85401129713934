import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../../Loader.js';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Paper from '@material-ui/core/Paper';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { LocalizationTheme } from '../../../../utils/utils.js';
import { DisplayMessage } from '../../../../services/helpers.js';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';
import { DeleteForever } from '@material-ui/icons';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class SnippetsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {

            dataGrid: [],
            currentSnippetId: 0,
            fetchingData: true,
            showDelete: false,
            deletedItem: 0,
            editedSnippetId: undefined,
            editedSnippet: undefined,
            showEditSnippet: false,
            requestInProgress: false,
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid) {
            this.getData();
        }
        this.setState({ redirect: null });
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ fetchingData: true });
        let initialData = {};
        var resources = this.props.resources;

        initialData.columns = [
            { title: resources["Language"], width: "20%", field: 'language', filtering: false, customSort: (a, b) => a.language.toLowerCase().localeCompare(b.language.toLowerCase()) },
            { title: resources["Type"], width: "20%", field: 'type', filtering: false, customSort: (a, b) => a.type.toLowerCase().localeCompare(b.type.toLowerCase()) },
            { title: resources["Title"], width: "40%", field: 'title', filtering: false, customSort: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()) },

        ];

        initialData.actions = [
            {
                icon: () => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `snippets/${rowData.id}`, editedSnippetId: rowData.id, })
            },

            rowData => (!rowData.isDeletedValue && rowData.id === this.state.currentSnippetId ? {
                icon: () => <DeleteForever />,
                tooltip: resources["YouCantDeleteSnippet"],

            } : null),

            rowData => (!rowData.isDeletedValue && rowData.id !== this.state.currentSnippetId ? {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: () => this.handleShowDelete(rowData.id),

            } : null),
        ]

        fetch(`/api/mobopro/v1/snippets`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) { throw new Error(); }
                return response.json();
            }).then(result => {
                const currentRows = [];

                result.forEach(function (rowData) {
                    let type = "";
                    switch (rowData.type) {
                        case 1:
                            type = resources['SnippetServiceQuote'];
                            break;
                        case 2:
                            type = resources['SnippetExternalDialog'];
                            break;
                        case 3:
                            type = resources['SnippetExternalDossierChat'];
                            break;
                        case 4:
                            type = resources['SnippetInternalDossierChat'];
                            break;
                        case 5:
                            type = resources['SnippetMediaFileNames'];
                            break;
                        case 6:
                            type = resources['SnippetPDFLibrary'];
                            break;
                        default:
                            break;
                    }
                    currentRows.push({
                        id: rowData.id,
                        language: rowData.language,
                        title: rowData.title,
                        type: type,
                    });
                });

                initialData.data = currentRows;

                this.setState({
                    dataGrid: initialData,
                    fetchingData: false
                });
            });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: 0 });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }


    handleDeleteSnippet = () => {
        if (this.state.deletedItem > 0) {
            let resources = this.props.resources;
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/snippets/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        return response.json();
                    }

                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
            }).then(result => {
                DisplayMessage(resources["SnippetWasDeleted"], "success");
                this.getData();
                this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                this.handleCloseDelete();

            });
        }
    }

    render() {
        const { redirect, fetchingData } = this.state;
        const { resources } = this.props;

        if (redirect) {
            return <Redirect to={{
                pathname: redirect,
                state: { editedSnippetId: this.state.editedSnippetId, refreshGrid: true }
            }}
            />
        }

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["SnippetsTitle"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <Link to={{ pathname: "/snippets/new" }} style={{ textDecoration: 'none' }}>
                                <Button id="add-Snippet" variant="contained" color="primary">
                                    <i className="fas fa-plus mr-2" title={resources["AddNewSnippet"]} />
                                    {resources["AddNewSnippet"]}
                                </Button>
                            </Link >
                        </div>
                        <div style={{ clear: "both", }}></div>

                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={this.state.dataGrid.actions}
                                    options={{
                                        emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20]
                                    }}

                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteSnippet"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleDeleteSnippet} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>

                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                </div>
            </div >
        )
    };
}