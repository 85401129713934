import { Box, styled } from "@material-ui/core";

export const ModalContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "absolute",
  margin: "auto",
  width: 400,
  minHeight: 240,
  backgroundColor: theme.palette.backgroundColor.main,
  boxShadow: 24,
  borderRadius: 3,
  padding: theme.spacing(2),
  transformOrigin: "center center",
  transition: "cubic-bezier(.4,0,0,1.5) both",
}));
