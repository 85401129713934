import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const CheckboxComponent = ({ label, id, checked, onChange }) => {
  return (
    <FormControlLabel
      className="my-auto p-0"
      control={
        <Checkbox
          id={id}
          checked={checked}
          onChange={onChange}
          name={id}
          color="primary"
        />
      }
      label={label}
    />
  );
};

export default CheckboxComponent;
