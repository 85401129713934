import {
  Box,
  CircularProgress,
  Dialog,
  FormControl,
  FormHelperText,
  Paper,
  TextField,
} from "@material-ui/core";
import { PictureAsPdf } from "@material-ui/icons";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { DisplayMessage, Uuidv4 } from "../../../../services/helpers";
import {
  CheckIfSasIsValid,
  GenerateSAS,
  UploadFileInStorage,
} from "../../../../services/uploadHelpers";
import DelayedRedirect from "../../../../utils/DelayedRedirect";
import { Transition } from "../../../../utils/ModalHelpers";
import { LanguagesWithLanguageCode } from "../../../../utils/constants";
import DropdownComponent from "../../../Common/Dropdowns/DropdownComponent";
import AppBarComponent from "../../../Common/Headers/AppBarComponent";
import { createDisclaimer } from "./services/disclaimersApisService";

export default function DisclaimersCreation(props) {
  const { resources, accessibleMoboOutlets, outletId, language } = props;
  const [redirect, setRedirect] = useState(null);
  const [disclaimerWasCreated, setDisclaimerWasCreated] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [selectedOutlet, setSelectedOutlet] = useState(outletId);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const [disclaimerDescription, setDisclaimerDescription] = useState("");
  const [disclaimerPdf, setDisclaimerPdf] = useState(null);

  const [requestIsValid, setRequestIsValid] = useState(false);

  var dropzoneClassCustom = disclaimerPdf
    ? "dropzone-hidden"
    : `dropzone large ${disclaimerDescription ? "disabled" : ""}`;

  const handleClose = useCallback(() => {
    setDialogOpen(false);
    setRedirect("/disclaimers");
  }, []);

  const uploadFile = async (file, body, error) => {
    const sas = await GenerateSAS(body);
    if (!CheckIfSasIsValid(sas)) {
      DisplayMessage(resources["ErrorUploadingFileToStorage"], "error");
      return undefined;
    }
    var blobPath = await UploadFileInStorage(
      file,
      sas[0],
      "application/pdf",
      error
    );
    return blobPath;
  };

  const handleSubmit = async () => {
    if (!isRequestValid(true)) return;

    setRequestInProgress(true);

    var body = {};
    if (disclaimerDescription.trim().length > 0) {
      body = JSON.stringify({
        moboOutletId: selectedOutlet,
        type: selectedType,
        language: selectedLanguage,
        disclaimer: disclaimerDescription,
      });
    } else {
      var sasRequest = JSON.stringify({
        minutes: 5,
        entityType: "disclaimer",
        entityValue: selectedOutlet,
        fileNames: [`${Uuidv4()}.pdf`],
      });

      var blobPath = await uploadFile(
        disclaimerPdf,
        sasRequest,
        resources["ErrorOnLoadingFile"]
      );
      if (blobPath === undefined) {
        setRequestInProgress(false);
        return;
      }
      body = JSON.stringify({
        moboOutletId: selectedOutlet,
        type: selectedType,
        language: selectedLanguage,
        file: {
          blobRelativePath: blobPath,
          type: 4,
        },
      });
    }
    var result = await createDisclaimer(body);
    if (result !== undefined) {
      setDisclaimerWasCreated(true);
      DisplayMessage(resources["DisclaimerWasCreatedWithSuccess"], "success");
    }
    setRequestInProgress(false);
    setRedirect("/disclaimers");
  };

  const isRequestValid = (displayError) => {
    var valid = true;

    if (selectedType < 1 || selectedType > 4) {
      valid = false;
      if (displayError) DisplayMessage(resources["InvalidType"], "error");
    }
    if (
      disclaimerDescription.trim().length === 0 &&
      !disclaimerPdf
    ) {
      valid = false;
      if (displayError) DisplayMessage(resources["InvalidDisclaimer"], "error");
    }

    if (selectedOutlet === 0) {
      valid = false;
      if (displayError) DisplayMessage(resources["InvalidOutlet"], "error");
    }

    setRequestIsValid(valid);
    return valid;
  };

  useEffect(() => {
    isRequestValid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, selectedOutlet, disclaimerDescription, disclaimerPdf]);

  const generateOutletsOptions = useMemo(() => {
    const sortedOutlets =
      accessibleMoboOutlets?.sort((a, b) =>
        a.description.localeCompare(b.description)
      ) || [];
    return [
      { key: 0, name: resources["Select"] },
      ...sortedOutlets.map(({ id, description }) => ({
        key: id,
        name: description,
      })),
    ];
  }, [accessibleMoboOutlets, resources]);

  const typesOptions = useMemo(
    () => [
      { name: resources["Select"], key: 0 },
      { name: resources["DisclaimerType1"], key: 1 },
      { name: resources["DisclaimerType2"], key: 2 },
      { name: resources["DisclaimerType3"], key: 3 },
      { name: resources["DisclaimerType4"], key: 4 },
    ],
    [resources]
  );

  const languagesOptions = useMemo(() => {
    return LanguagesWithLanguageCode.map(({ code, Name }) => ({
      key: code,
      name: Name,
    }));
  }, []);

  const handlePreviewIcon = (fileObject, classes) => {
    const iconProps = {
      className: classes.image,
    };
    return <PictureAsPdf {...iconProps} />;
  };

  return (
    <Box>
      {redirect && (
        <DelayedRedirect
          to={{
            pathname: redirect,
            state: { outletId, refreshGrid: disclaimerWasCreated },
          }}
          delay={250}
        />
      )}
      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBarComponent
          idName="newDisclaimer"
          title={resources["NewDisclaimer"]}
          resources={resources}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          disabled={requestInProgress || !requestIsValid}
        />
        {requestInProgress ? (
          <Box className="centered-loader fadeIn">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="container fadeIn">
            <Box className="py-4 grey">
              <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Box className="d-flex form-group pt-3">
                  <Box className="col-4" />
                  <Box className="col-4 p-0">
                    <DropdownComponent
                      value={selectedOutlet}
                      handleChange={(e) =>
                        setSelectedOutlet(parseInt(e.target.value))
                      }
                      options={generateOutletsOptions}
                      labelText={resources["Outlet"]}
                      id="outlet"
                      error={selectedOutlet === 0}
                      helperText={resources["SelectionOfOutletIsMandatory"]}
                    />
                    <DropdownComponent
                      value={selectedType}
                      handleChange={(e) =>
                        setSelectedType(parseInt(e.target.value))
                      }
                      options={typesOptions}
                      labelText={resources["Type"]}
                      id="type"
                      error={selectedType === 0}
                      helperText={resources["SelectionOfTypeIsMandatory"]}
                    />
                    <DropdownComponent
                      value={selectedLanguage}
                      handleChange={(e) => setSelectedLanguage(e.target.value)}
                      options={languagesOptions}
                      labelText={resources["Language"]}
                      id="language"
                    />
                    <Box className="d-flex form-group">
                      <FormControl className="col-12" variant="outlined">
                        <FormHelperText
                          error={disclaimerDescription.trim().length === 0 && !disclaimerPdf}
                          style={{ marginBottom: "16px" }}
                        >
                          {resources["DisclaimerOrPdfFileIsMandatory"]}
                        </FormHelperText>
                        <TextField
                          id="disclaimers-description"
                          value={disclaimerDescription}
                          label={resources["Disclaimer"]}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="description"
                          onChange={(e) =>
                            setDisclaimerDescription(e.target.value)
                          }
                          disabled={disclaimerPdf}
                          multiline
                          minRows={5}
                        />
                      </FormControl>
                    </Box>
                    <Box
                      className="d-flex form-group"
                      style={{ justifyContent: "center" }}
                    >
                      <Box style={{ minWidth: "350px" }}>
                        <FormHelperText
                          style={{
                            marginLeft: 16,
                            marginTop: 0,
                            marginBottom: disclaimerPdf ? 16 : 0,
                          }}
                        >
                          {resources["PDFFile"]}
                        </FormHelperText>
                        <DropzoneArea
                          acceptedFiles={[".pdf"]}
                          filesLimit={1}
                          showAlerts={false}
                          onAlert={(message, variant) =>
                            variant === "error"
                              ? DisplayMessage(message, variant)
                              : undefined
                          }
                          showPreviews={true}
                          previewText=""
                          getPreviewIcon={handlePreviewIcon}
                          dropzoneClass={dropzoneClassCustom}
                          dropzoneParagraphClass={"dropzone-p"}
                          maxFileSize={30000000}
                          dropzoneText={
                            resources["DropPdfFileOrClickToSelectFile"]
                          }
                          onChange={(files) =>
                            setDisclaimerPdf(files[0] || null)
                          }
                          dropzoneProps={{ disabled: disclaimerDescription }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className="col-4" />
                </Box>
              </Paper>
            </Box>
          </Box>
        )}
      </Dialog>
    </Box>
  );
}
