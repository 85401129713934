import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../../Loader.js';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Paper from '@material-ui/core/Paper';
import Form from 'react-bootstrap/Form';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { LocalizationTheme } from '../../../../utils/utils.js';
import { GetUTCFormattedDate, DisplayMessage, TranslateDayOfWeekName } from '../../../../services/helpers.js';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';


import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/de';
import 'moment/locale/fr';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class PauseReasonsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: undefined,
            dataGrid: [],
            PauseReasonId: 0,
            fetchingData: true,
            showDelete: false,
            showUnDelete: false,
            deletedItem: 0,
            editedPauseReasonId: undefined,
            editedPauseReason: undefined,
            showEditPauseReason: false,
            requestInProgress: false,
            pauseReasons: []
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid && newProps.type !== undefined) {
            this.setState({ type: parseInt(newProps.type) });
            this.getData(parseInt(newProps.type));
        } else {
            if (newProps.refreshGrid) {
                this.getData();
            }
        }

        this.setState({ redirect: null });
    }

    componentDidMount() {
        this.getData();
    }

    getData = (typeId) => {
        this.setState({ fetchingData: true });
        let initialData = {};
        var resources = this.props.resources;
        var type = typeId != undefined ? typeId : this.state.type;
        var url = "";

        var language = this.props.language;

        initialData.columns = [
            { title: resources["Description"], width: "60%", field: 'description', filtering: false, customSort: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()) },
            {
                title: resources["ModifiedOn"], field: 'modifiedOn', options: { filter: true, sort: true },
                customSort: (a, b) => {
                    var a1 = moment(a.modifiedOn, "DD/MM/YYYY").toDate();
                    var b1 = moment(b.modifiedOn, "DD/MM/YYYY").toDate();
                    if (a1 < b1)
                        return 1;
                    else if (a1 > b1)
                        return -1;
                    else
                        return 0;
                }
            },
            { title: resources["Type"], width: "20%", field: 'type', filtering: false, customSort: (a, b) => a.type.toLowerCase().localeCompare(b.type.toLowerCase()) },
        ];

        initialData.actions = [
            {
                icon: () => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `/pausereasons/${rowData.id}`, editedPauseReasonId: rowData.id, })
            },

            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.handleShowDelete(rowData.id)
            }
        ];

        url = `/api/mobopro/v1/pausereasons`
        if (type != undefined && type != 0) {
            url = url + `?type=` + type;
        }

        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    });
                    this.setState({
                        fetchingData: false
                    });
                }
                else {
                    response.json().then(result => {

                        const currentRows = [];
                        const pauseReasons = [];

                        result.forEach(function (rowData) {

                            if (rowData.type === 3 || rowData.type === 4 || rowData.type === 6) {
                                pauseReasons.push(rowData);

                                let type = "";
                                switch (rowData.type) {
                                    case 3:
                                        type = resources['Dossiers'];
                                        break;
                                    case 4:
                                        type = resources['RepairOrderItems'];
                                        break;
                                    default:
                                        break;
                                }
                                currentRows.push({
                                    id: rowData.id,
                                    type: type,
                                    description: rowData.description,
                                    createdBy: rowData.createdBy,
                                    modifiedOn: `${moment(!rowData.modifiedOn ? rowData.createdOn : rowData.modifiedOn).format('DD/MM/YYYY HH:mm').toLocaleString()}`,
                                    modifiedBy: rowData.modifiedBy
                                });
                            }
                        });

                        initialData.data = currentRows;

                        this.setState({
                            dataGrid: initialData,
                            fetchingData: false,
                            pauseReasons: pauseReasons,
                        });

                    });
                }

            });

    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: 0 });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleDeletePauseReason = () => {
        if (this.state.deletedItem > 0) {
            let resources = this.props.resources;
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/pausereasons/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        return response.json();
                    }

                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
            }).then(result => {
                DisplayMessage(resources["PauseReasonWasDeleted"], "success");
                this.getData();
                this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                this.handleCloseDelete();

            });
        }
    }
    handleTypeChange = e => {
        this.setState({ [e.target.name]: parseInt(e.target.value), requestInProgress: false }, () => this.getData(this.state.outletId));
    };

    render() {
        const { redirect, fetchingData, type, editedPauseReasonId } = this.state;
        const { resources } = this.props;

        if (redirect) {
            return <Redirect to={{
                pathname: redirect,
                state: { editedPauseReasonId: editedPauseReasonId, editedPauseReason: this.state.pauseReasons.find(t => t.id === editedPauseReasonId), refreshGrid: true }
            }}
            />
        }

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["PauseReasons"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>

                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <Form.Group className="main-dropdown">
                            <FormControl className="col-md-12" variant="outlined" onChange={this.handleTypeChange}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-type-native-simple">{resources["Type"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    id="pausereason-type-dropdown"
                                    native
                                    label={resources["Type"]}
                                    value={type}
                                    inputProps={{
                                        name: 'type',
                                        id: 'pauseReason-type-dropdown',
                                    }}>
                                    <option value={0} key={0}>{resources["All"]}</option>
                                    <option value={3} key={3}>{resources["Dossiers"]}</option>
                                    <option value={4} key={4}>{resources["RepairOrderItems"]}</option>
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <Link to={{ pathname: "/pausereasons/new", state: { pauseReasonId: this.state.PauseReasonId, type: type } }} style={{ textDecoration: 'none' }}>
                                <Button id="add-pausereason" variant="contained" color="primary">
                                    <i className="fas fa-plus mr-2" title={resources["AddNewPauseReason"]} />
                                    {resources["AddNewPauseReason"]}
                                </Button>
                            </Link >
                        </div>
                        <div style={{ clear: "both", }}></div>

                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={this.state.dataGrid.actions}
                                    options={{
                                        emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20]
                                    }}

                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeletePauseReason"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleDeletePauseReason} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>

                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                </div>
            </div >
        )
    };
}