import React, { Component, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../../Loader.js';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import Form from 'react-bootstrap/Form';

import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

import Button from '@material-ui/core/Button';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Block from '@material-ui/icons/Block';
import { LocalizationTheme } from '../../../../utils/utils.js';
import { DisplayMessage } from '../../../../services/helpers.js';
import Paper from '@material-ui/core/Paper';
import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Block: forwardRef((props, ref) => <Block {...props} ref={ref} />)
};

export default class LinkIndicatorsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            fetchingData: true,
            showDelete: false,
            deletedItem: 0,
            accessibleMoboOutlets: [],
            requestInProgress: false
        };

        this.refreshTable = this.refreshTable.bind(this);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid) {
            this.refreshTable();
        }

        this.setState({ redirect: null });
    }

    componentDidMount() {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;
        var outlet = outlets[0].id;
        this.setState({ outletId: outlet })
        this.getData(outlet);
    }

    renderOutlets = () => {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    handleChangeOutlet = e => {
        this.setState({ outletId: e.target.value });
        this.getData(e.target.value);
    };

    getData = (outletId) => {
        this.setState({ fetchingData: true });
        let initialData = {};
        var resources = this.props.resources;
        initialData.columns = [
            {
                title: resources["Color"], width: "10%", field: 'color', options: { filter: true, sort: true }, render: rowData => {
                    if (/^[0-9A-F]{6}$/i.test(rowData.color)) {
                        return <div id={"color-" + rowData.color} className="color-card" style={{ backgroundColor: "#" + rowData.color }}>
                        </div>
                    }
                    else {
                        return <div id="color-none" className="color-card">
                            <Block />
                        </div>
                    }
                }
            },
            { title: resources["Description"], field: 'description', options: { filter: true, sort: true }, customSort: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()) },
            { title: resources["OutletUser"], field: 'outletUser', options: { filter: true, sort: true } },
        ];


        initialData.actions = [
            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.handleShowDelete(rowData.id)
            }
        ]

        var url = '/api/mobopro/v1/workshopplanningindicatorstooutletusers?moid=' + outletId;
        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) { throw new Error(); }
                return response.json();
            }).then(result => {
                const currentRows = [];

                result.forEach(function (item) {
                    var outletUserName = item.outletUser.firstName + " " + item.outletUser.lastName;
                    item.competences.forEach(function (competence) {
                        currentRows.push({
                            id: competence.id,
                            description: competence.description,
                            color: competence.colorHex,
                            outletUser: outletUserName
                        });
                    })
                });

                initialData.data = currentRows;

                this.setState({
                    dataGrid: initialData,
                    fetchingData: false
                });
            });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: undefined });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleDelete = () => {
        const { resources } = this.props;
        if (this.state.deletedItem > 0) {
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/workshopplanningindicatorstooutletusers/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        return response.json();
                    }

                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
            }).then(result => {
                this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                this.handleCloseDelete();
                DisplayMessage(resources["LinkedIndicatorsSuccessfullyDeleted"], "success");
                this.refreshTable();
            });
        }
    }

    refreshTable() {
        this.getData(this.state.outletId);
    }

    render() {
        const { fetchingData } = this.state;
        let resources = this.props.resources;

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["LinkedIndicators"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <Form.Group className="float-left  col-md-4 pl-0" onClick={this.handleTyping}>
                            <FormControl className="col-md-11" variant="outlined" onChange={this.handleChangeOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    native
                                    label={resources["Outlet"]}
                                    value={this.state.indicatorId}
                                    inputProps={{
                                        name: 'outlet',
                                        id: 'outlined-outlet-native-simple'
                                    }}>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <div style={{ float: "right", marginBottom: "10px" }}>

                            <Link to={{ pathname: "/linkedindicators/new", state: { outletId: this.state.outletId } }} style={{ textDecoration: 'none' }}>
                                <Button id="add-linked-indicator" variant="contained" color="primary">
                                    <i className="fas fa-plus mr-2" title={resources["AddLink"]} />
                                    {resources["AddLink"]}
                                </Button>
                            </Link >
                        </div>
                    </div>

                    <div className={(this.state.fetchingData ? 'fadeOut' : 'fadeIn')}>

                        <div style={{ clear: "both", }}></div>
                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={this.state.dataGrid.actions}
                                    options={{ emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20] }}
                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>

                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["MayWeProceedToDeleteThisLink"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button id="btnCancelDelete" className="btn btn-secondary" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }} onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary px-4" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }} onClick={this.handleDelete} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>
                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                </div>
            </div >
        )
    };
}