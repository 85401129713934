import {
  Box,
  CircularProgress,
  Dialog,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import DelayedRedirect from "../../../utils/DelayedRedirect";
import { Transition } from "../../../utils/ModalHelpers";
import { DisplayMessage } from "../../../services/helpers";
import {
  createAssetGroup,
  updateAssetGroup,
  getAssetGroupById,
} from "../../../services/AssetGroups/assetGroupApisService";
import { AssetGroupsRoute } from "../../../constants/routePaths";
import AppBarComponent from "../../Common/Headers/AppBarComponent";
import { AssetGroupsGenericTypes } from "../../../constants/assetGroupsGenericTypes";
import DropdownComponent from "../../Common/Dropdowns/DropdownComponent";
import TextFieldMandatoryComponent from "../../Common/Inputs/TextFieldMandatoryComponent";
import CheckboxComponent from "../../Common/Checkboxes/CheckboxComponent";
import FormGroup from "@material-ui/core/FormGroup";
import { GetSortedOptionsByName } from "./../../../services/helpers";
import ModifiedByInfo from "../../Common/Avatar/ModifiedByInfo";

const AssetGroupItem = ({ resources, defaultGenericType, assetGroupId }) => {
  const isEditMode = useMemo(
    () => assetGroupId && assetGroupId > 0,
    [assetGroupId]
  );

  const [redirect, setRedirect] = useState(null);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [formData, setFormData] = useState({
    selectedType: defaultGenericType,
    description: "",
    isWorkshop: true,
    isActive: true,
    hasFuelLevel:
      defaultGenericType === AssetGroupsGenericTypes.Car ||
      defaultGenericType === AssetGroupsGenericTypes.Motorbike,
    hasMileage:
      defaultGenericType === AssetGroupsGenericTypes.Car ||
      defaultGenericType === AssetGroupsGenericTypes.Motorbike,
  });
  const [requestIsValid, setRequestIsValid] = useState(false);
  const [modifiedBy, setModifiedBy] = useState({
    avatar: "",
    on: "",
    name: "",
  });

  const isRequestValid = useCallback(
    (displayError) => {
      const { selectedType, description } = formData;
      const valid =
        selectedType >= 1 && selectedType <= 4 && description.trim() !== "";

      if (!valid && displayError) {
        if (selectedType < 1 || selectedType > 4) {
          DisplayMessage(resources["InvalidType"], "error");
        }
        if (!description) {
          DisplayMessage(resources["InvalidDescription"], "error");
        }
      }

      setRequestIsValid(valid);
      return valid;
    },
    [formData, resources]
  );

  const fetchAssetGroup = useCallback(async () => {
    setRequestInProgress(true);
    const result = await getAssetGroupById(assetGroupId);
    if (result) {
      setFormData({
        selectedType: result.genericType,
        description: result.description,
        isWorkshop: result.isWorkshop,
        isActive: result.isActive,
        hasFuelLevel: result.hasFuelLevel,
        hasMileage: result.hasMileage,
      });
      setModifiedBy({
        avatar: result.modifiedByAvatar,
        on: result.modifiedOn,
        name: result.modifiedByName,
      });
    } else {
      setRedirect(AssetGroupsRoute);
    }
    setRequestInProgress(false);
  }, [assetGroupId]);

  useEffect(() => {
    if (isEditMode) {
      fetchAssetGroup();
    }
  }, [isEditMode, fetchAssetGroup]);

  useEffect(() => {
    isRequestValid(false);
  }, [formData, isRequestValid]);

  const handleClose = () => {
    setDialogOpen(false);
    setRedirect(AssetGroupsRoute);
  };

  const handleSubmit = async () => {
    if (!isRequestValid(true)) return;

    setRequestInProgress(true);
    const {
      selectedType,
      description,
      isWorkshop,
      isActive,
      hasFuelLevel,
      hasMileage,
    } = formData;
    const body = isEditMode
      ? JSON.stringify({
          description,
          hasFuelLevel,
          hasMileage,
          isWorkshop,
          isActive,
        })
      : JSON.stringify({
          genericType: selectedType,
          description,
          hasFuelLevel,
          hasMileage,
          isWorkshop,
          isActive,
        });

    const result = isEditMode
      ? await updateAssetGroup(assetGroupId, body)
      : await createAssetGroup(body);

    if (result) {
      setRefreshGrid(true);
      const successMessage = isEditMode
        ? resources["AssetGroupWasUpdatedWithSuccess"]
        : resources["AssetGroupWasCreatedWithSuccess"];
      DisplayMessage(successMessage, "success");
    }
    setRedirect(AssetGroupsRoute);
  };

  const typesOptions = useMemo(
    () => [
      {
        name: resources["ProDealerAdmin.Car"],
        key: AssetGroupsGenericTypes.Car,
      },
      {
        name: resources["ProDealerAdmin.Bike"],
        key: AssetGroupsGenericTypes.Bike,
      },
      {
        name: resources["ProDealerAdmin.MotorBike"],
        key: AssetGroupsGenericTypes.Motorbike,
      },
    ],
    [resources]
  );

  const handleFormChange = (key, value) => {
    setFormData((prev) => {
      if(key === "selectedType"){
        switch (value) {
          case AssetGroupsGenericTypes.Car:
            return {...prev, [key]: value, "hasFuelLevel": true, "hasMileage": true};
          case AssetGroupsGenericTypes.Bike:
            return {...prev, [key]: value, "hasFuelLevel": false, "hasMileage": false};
          case AssetGroupsGenericTypes.Motorbike:
            return {...prev, [key]: value, "hasFuelLevel": true, "hasMileage": true};
          default:
            return {...prev, [key]: value}
        }
      }
      return {
        ...prev,
        [key]: value,
      }
    });
  };

  return (
    <Box>
      {redirect && (
        <DelayedRedirect
          to={{ pathname: redirect, state: { refreshGrid: refreshGrid } }}
          delay={250}
        />
      )}
      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBarComponent
          idName="assetGroup"
          title={
            isEditMode
              ? resources["EditAssetGroup"]
              : resources["NewAssetGroup"]
          }
          resources={resources}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          disabled={requestInProgress || !requestIsValid}
        />
        {requestInProgress ? (
          <Box className="centered-loader fadeIn">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="container fadeIn">
            <Box className="py-4 grey">
              <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Box className="d-flex form-group pt-3">
                  <Box className="col-3" />
                  <Box className="col-6">
                    <DropdownComponent
                      value={formData.selectedType}
                      handleChange={(e) =>
                        handleFormChange(
                          "selectedType",
                          parseInt(e.target.value)
                        )
                      }
                      options={GetSortedOptionsByName(typesOptions)}
                      labelText={resources["Type"]}
                      id="type"
                      disable={isEditMode}
                    />
                    <TextFieldMandatoryComponent
                      value={formData.description}
                      handleChange={(e) =>
                        handleFormChange("description", e.target.value)
                      }
                      labelText={resources["Description"]}
                      id="description"
                      maxLength={50}
                      helperText={resources["InputOfDescriptionIsMandatory"]}
                    />
                    <FormGroup>
                      <CheckboxComponent
                        label={resources["ProDealerAdmin.AssetHasFuelLevel"]}
                        id={"asset-hasFuelLevel"}
                        checked={formData.hasFuelLevel}
                        onChange={(e) =>
                          handleFormChange("hasFuelLevel", e.target.checked)
                        }
                      />
                      <CheckboxComponent
                        label={resources["ProDealerAdmin.AssetHasMileage"]}
                        id={"asset-hasMileage"}
                        checked={formData.hasMileage}
                        onChange={(e) =>
                          handleFormChange("hasMileage", e.target.checked)
                        }
                      />
                      <CheckboxComponent
                        label={
                          resources["ProDealerAdmin.AssetAvailableToWorkshop"]
                        }
                        id={"asset-availableToWorkshop"}
                        checked={formData.isWorkshop}
                        onChange={(e) =>
                          handleFormChange("isWorkshop", e.target.checked)
                        }
                      />
                      <CheckboxComponent
                        label={resources["ProDealerAdmin.IsActive"]}
                        id={"asset-isActive"}
                        checked={formData.isActive}
                        onChange={(e) =>
                          handleFormChange("isActive", e.target.checked)
                        }
                      />
                    </FormGroup>
                  </Box>
                  <Box className="col-3" />
                </Box>
                {isEditMode && (
                  <ModifiedByInfo
                    resources={resources}
                    modifiedByAvatar={modifiedBy.avatar}
                    modifiedOn={modifiedBy.on}
                    modifiedByName={modifiedBy.name}
                  />
                )}
              </Paper>
            </Box>
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

export default AssetGroupItem;
