import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'toastr/build/toastr.min.css';
import { DisplayMessage } from '../../../../services/helpers.js';
import DelayedRedirect from '../../../../utils/DelayedRedirect';
import { Transition } from '../../../../utils/ModalHelpers';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

export default class LinkIndicatorsCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            outletId: 0,
            outletUserId: 0,
            indicatorId: 0,
            outletUsers: [],
            indicators: [],
            redirect: null,
            linkedIndicatorWasCreated: false
        };
    }

    componentDidMount() {
        if (this.props.accessibleMoboOutlets === undefined) return;

        this.getOutletUsers();

        const { outletId, accessibleMoboOutlets } = this.props;

        var outlets = accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1);
        var preSelectedOutlet = outletId ?? outlets[0].id;

        this.setState({ outletId: preSelectedOutlet }, () => {
            this.getIndicators();
        });
    }

    handleClose = () => {
      this.setState({ open: false, redirect: "/linkedindicators" });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleChangeOutlet = e => {
        this.setState({ outletId: e.target.value }, () => {
            this.getIndicators();
        });
    };

    handleChangeOutletUser = e => {
        this.setState({ outletUserId: e.target.value });
    };

    handleChangeIndicator = e => {
        this.setState({ indicatorId: e.target.value });
    };

    getOutletUsers() {
        var url = '/api/mobopro/v1/outletusers?includedeleted=false';
        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) { throw new Error(); }
                return response.json();
            }).then(result => {
                const outletUsersInfo = [];
                result.forEach(function (item) {
                    var outletUserName = item.firstName + " " + item.lastName;
                    outletUsersInfo.push({
                        id: item.id,
                        name: outletUserName
                    });
                });
                var outletUsersInfoSorted = outletUsersInfo.sort((a, b) => (a.name > b.name) ? 1 : -1);
                if (outletUsersInfoSorted !== undefined) {
                    this.setState({ outletUsers: outletUsersInfoSorted, outletUserId: outletUsersInfoSorted[0].id });
                }
            });
    }

    getIndicators() {
        const { outletId } = this.state;

        var url = '/api/mobopro/v1/workshopplanningindicators?moid=' + outletId + "&isCompetence=true";
        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) { throw new Error(); }
                return response.json();
            }).then(result => {
                const indicators = [];
                result.forEach(function (item) {
                    indicators.push({
                        id: item.id,
                        description: item.description
                    });
                });
                var indicatorsSorted = indicators.sort(function (a, b) {
                    return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
                });
                if (indicatorsSorted.length !== 0) {
                    this.setState({ indicators: indicatorsSorted, indicatorId: indicatorsSorted[0].id });
                }
                else {
                    this.setState({
                        indicators: indicatorsSorted
                    });
                }
            });
    }

    handleSubmit = () => {
        this.setState({ requestInProgress: true });
        const { resources } = this.props;

        var json = JSON.stringify({
            moboOutletId: this.state.outletId,
            outletUserId: this.state.outletUserId,
            workshopPlanningIndicatorId: this.state.indicatorId
        });

        var url = '/api/mobopro/v1/workshopplanningindicatorstooutletusers';
        fetch(url,
            {
                method: 'post',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
                body: json
            })
            .then(response => {
                response.json().then(result => {
                    if (!response.ok) {
                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                        this.setState({ requestInProgress: false });
                    }
                    else {
                        DisplayMessage(resources["LinkedIndicatorsSuccessfullyCreated"], "success");
                        this.setState({ requestInProgress: false, redirect: (response.ok ? '/linkedindicators' : null), linkedIndicatorWasCreated: response.ok });
                    }
                })
            });
    }

    renderOutlets = () => {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id.toString()}> {x.description}</option>);
        return outletOptions;
    }

    renderOutletUsers = () => {
        return this.state.outletUsers.map(x => <option key={x.id} value={x.id.toString()}> {x.name}</option>);
    }

    renderIndicators = () => {
        return this.state.indicators.map(x => <option key={x.id} value={x.id.toString()}> {x.description}</option>);
    }

    renderForm() {
        const handleSubmit = (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit();
        };
        var resources = this.props.resources;

        return (
            <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Form onSubmit={handleSubmit} >

                    <Form.Group className="pt-3" style={{ display: "flex" }}>
                        <div className="col-md-3"></div>
                        <FormControl className="col-md-6" variant="outlined" onChange={this.handleChangeOutlet}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="linked-indicators-outlet">{resources["Outlet"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="linked-indicators-outlet"
                                native
                                label={resources["Outlet"]}
                                value={this.state.outletId}
                                inputProps={{
                                    name: 'outlet',
                                    id: 'linked-indicators-outlet',
                                }}>
                                {this.renderOutlets()}
                            </Select>
                        </FormControl>
                    </Form.Group>

                    <Form.Group style={{ display: "flex" }}>
                        <div className="col-md-3"></div>
                        <FormControl className="col-md-6" variant="outlined" onChange={this.handleChangeOutletUser}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="linked-indicators-outlet-user">{resources["OutletUser"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="linked-indicators-outlet-user"
                                native
                                label={resources["OutletUser"]}
                                value={this.state.outletUserId}
                                inputProps={{
                                    name: 'outletUser',
                                    id: 'linked-indicators-outlet-user',
                                }}>
                                {this.renderOutletUsers()}
                            </Select>
                        </FormControl>
                    </Form.Group>

                    <Form.Group style={{ display: "flex" }}>
                        <div className="col-md-3"></div>
                        <FormControl disabled={this.state.indicators.length === 0} className="col-md-6" variant="outlined" onChange={this.handleChangeIndicator}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="linked-indicators-indicator">{resources["Indicator"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="linked-indicators-indicator"
                                native
                                label={resources["Indicator"]}
                                value={this.state.indicatorId}
                                inputProps={{
                                    name: 'indicator',
                                    id: 'linked-indicators-indicator',
                                }}>
                                {this.renderIndicators()}
                            </Select>
                        </FormControl>
                    </Form.Group>
                </Form>
            </Paper >
        );
    }

    renderMain() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            <div className="pt-4">
                {this.renderForm()}
            </div>
        </div>;
    }

    render() {
        const { redirect, open, linkedIndicatorWasCreated } = this.state;
        const { resources } = this.props;

        return (
            <div>
                {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: linkedIndicatorWasCreated } }} delay={250} />}
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton id="close-add-linked-indicator" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["NewLink"]}
                            </Typography>
                            <Button id="linked-indicators-save" color="inherit" onClick={this.handleSubmit}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        {this.renderMain()}
                    </div>
                </Dialog>
            </div >
        );
    }
}