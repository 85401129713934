import * as React from 'react'
import { Redirect } from 'react-router'

export default class DelayedRedirect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeToRedirect: false
        }

        this.timeout = null;
    }

    componentDidMount() {
        this.timeout = setTimeout(() => {
            this.setState({
                timeToRedirect: true,
            })
        }, this.props.delay)
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        const { delay, ...props } = this.props
        const { timeToRedirect } = this.state

        if (timeToRedirect) {
            return <Redirect {...props} />
        }

        return null;
    }
}
