import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import OpeningHoursTable from '../components/WorkshopPlanning/General/OpeningHours/OpeningHoursTable';
import OpeningHoursEditing from '../components/WorkshopPlanning/General/OpeningHours/OpeningHoursEditing';
import OpeningHoursCreation from '../components/WorkshopPlanning/General/OpeningHours/OpeningHoursCreation';
import { Permissions } from '../utils/Permissions';

export default function OpeningHoursRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/openinghours' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<OpeningHoursTable resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
                    refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} outletId={props && props.location && props.location.state && props.location.state.outletId} />)
            }} />
            <Switch>
                <Route exact path='/openinghours/new' render={(props) => (<OpeningHoursCreation outletId={props && props.location && props.location.state && props.location.state.outletId} resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)} />
                <Route exact path='/openinghours/:outletId/:openingHourId' render={(props) => {
                    return (<OpeningHoursEditing resources={resources} outletId={props.match.params.outletId} openingHourId={props.match.params.openingHourId} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}  {...props} />)
                }} />
            </Switch>
        </>
    )
}