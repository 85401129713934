
import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MollieButton from '../../mollie-button.png';
import toastr from 'toastr';
import ButtonBase from '@material-ui/core/ButtonBase';
import qs from 'qs';
import { DisplayMessage } from '../../services/helpers.js';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';


export default class PaymentConnections extends Component {
    constructor(props) {
        super(props);
        var connectionIsSuccessful = qs.parse(props.location.search, { ignoreQueryPrefix: true }).successful
        if (connectionIsSuccessful == "true") {
            DisplayMessage(props.resources["PaymentConnectionSuccessful"], 'success', 100);
        }
        else if (connectionIsSuccessful == "false") {
            DisplayMessage(props.resources["PaymentConnectionFailed"], 'error', 100);
        }

        this.state = {
            fetchingData: true,
            paymentConnections: [],
            connectionRequestInProgress: false
        }
    }

    componentWillMount() {
        this.retrievePaymentConnections();
    }

    retrievePaymentConnections = () => {
        this.setState({ fetchingData: true }, () => {
            var publicKey = localStorage.getItem('publicKey');
            var authorizationToken = localStorage.getItem('authorizationToken');
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'PublicKey': publicKey, 'Authorization-token': authorizationToken }
            };

            fetch("/api/mobopro/v1/payments/connections", requestOptions)
                .then(response => {
                    response.json().then(data => {
                        if (!response.ok) {
                            for (var i = 0; i < data.errors.length; i++) {
                                toastr.error(data.errors[i]);
                            }
                            this.setState({ fetchingData: false, paymentConnections: [] })
                        }
                        else {
                            this.setState({ fetchingData: false, paymentConnections: data })
                        }
                    })
                });
        })
    }

    connectPayment = (moboCompanyId) => {
        const { connectionRequestInProgress } = this.state;
        if (connectionRequestInProgress) return;

        this.setState({ connectionRequestInProgress: true }, () => {
            var publicKey = localStorage.getItem('publicKey');
            var authorizationToken = localStorage.getItem('authorizationToken');
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'PublicKey': publicKey, 'Authorization-token': authorizationToken }
            };

            fetch(`/api/mobopro/v1/payments/connect?moboCompanyId=${moboCompanyId}`, requestOptions)
                .then(response => {
                    response.json().then(data => {
                        if (!response.ok) {
                            for (var i = 0; i < data.errors.length; i++) {
                                toastr.error(data.errors[i]);
                            }
                            this.setState({ connectionRequestInProgress: false });
                        }
                        else {
                            document.location.href = data.redirectUrl;
                        }
                    })
                });
        })

    }

    renderList = () => {
        const { paymentConnections } = this.state;
        var items = paymentConnections.map((paymentConnection, index) => {
            return (
                <React.Fragment>
                    <ListItem key={index}>
                        <ListItemText primary={paymentConnection.moboCompanyDescription} />
                        {paymentConnection.connectedOn == null ? (
                            <ListItemAvatar >
                                <ButtonBase focusRipple onClick={() => this.connectPayment(paymentConnection.moboCompanyId)}>
                                    <img src={MollieButton} width={200} />
                                </ButtonBase>
                            </ListItemAvatar>
                        ) : `Connected on ${moment(paymentConnection.connectedOn).format('DD/MM/YYYY')}`}
                    </ListItem>
                    {index != paymentConnections.length - 1 && <Divider className="my-2" />}
                </React.Fragment>
            )
        })

        return (
            <List component="nav">
                {items}
            </List>
        )
    }

    render() {
        const { fetchingData } = this.state;
        const { resources } = this.props;

        if (fetchingData) return (<div className="offcentered-loader"><CircularProgress /></div>)

        return (
            <div className="position-relative py-4">
                <h3>{resources["PaymentConnectionsTitle"]}</h3>
                <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                    <Box style={{ maxWidth: "1000px" }} >
                        <Paper elevation={3} className="p-3 mt-3">
                            {this.renderList()}
                        </Paper>
                    </Box>
                </div>
            </div>
        )
    }
}