import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage, GetDayOfWeekName } from '../../../../services/helpers.js';
import DelayedRedirect from '../../../../utils/DelayedRedirect';
import { Transition } from '../../../../utils/ModalHelpers';

import { Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";
import { TimePicker } from 'antd';
import DeleteIcon from '@material-ui/icons/Delete';
const format = 'HH:mm';

export default class OpeningHoursEditing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openingHourId: parseInt(this.props.openingHourId),
            id: 0,
            outletId: parseInt(this.props.outletId),
            outletName: "",

            workshopOpenFrom: null,
            workshopOpenTill: null,
            workshopDropFrom: null,
            workshopDropTill: null,
            workshopDropCloseNoonFrom: null,
            workshopDropCloseNoonTill: null,
            isWorkshopClosed: false,
            dayOfWeek: "",

            modifiedOn: "",
            modifiedByName: "",
            modifiedByAvatar: "",
            selectedOutletId: 0,
            loading: this.props.editedOpeningHourId !== undefined,
            redirect: false,
            accessibleMoboOutlets: [],
            editedOpeningHourId: this.props.editedOpeningHourId,
            requestInProgress: false,
            redirect: null,
            open: true,
            openingHourWasModified: false,
            openingHour: [],
            onlineOpenFrom: null,
            onlineOpenTill: null
        };
    }

    componentDidMount() {
        if (this.props.location !== undefined && this.props.location.state !== undefined && this.props.location.state.editedOpeningHour !== undefined) {
            this.getEditegOpeningHourDetails(this.props.location.state.editedOpeningHour);
        } else {
            this.getOpeningHourById(this.state.openingHourId, this.state.outletId);
        }
    }

    handleClose = () => {
        this.setState({ open: false, redirect: "/openinghours" });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };


    handleChangeTimetableInput = (value, inputType) => {
        this.setState({ [inputType]: value === '' ? null : value });
    }

    clearTime = (from, till) => {
        this.setState({ [from]: null, [till]: null });
    }

    handleChangeCheckBox = e => {
        this.setState({ [e.target.name]: e.target.checked });
    };

    handleTyping = e => {
        this.setState({ requestInProgress: false });
    };
    handleSubmit = () => {
        this.saveEditedOpeningHour();
    }

    getEditegOpeningHourDetails = (editedOpeningHour) => {
        let resources = this.props.resources;
        var dayOfWeekName = GetDayOfWeekName(editedOpeningHour.dayOfWeek, resources);
        this.setState({
            openingHour: editedOpeningHour,
            id: editedOpeningHour.id,
            outletId: editedOpeningHour.moboOutletId,
            workshopOpenFrom: editedOpeningHour.workshopOpenFrom,
            workshopOpenTill: editedOpeningHour.workshopOpenTill,
            workshopDropFrom: editedOpeningHour.workshopDropFrom,
            workshopDropTill: editedOpeningHour.workshopDropTill,
            workshopDropCloseNoonFrom: editedOpeningHour.workshopDropCloseNoonFrom,
            workshopDropCloseNoonTill: editedOpeningHour.workshopDropCloseNoonTill,
            isWorkshopClosed: editedOpeningHour.isWorkshopClosed,
            dayOfWeek: dayOfWeekName,
            loading: false,
            modifiedOn: editedOpeningHour.updatedBy !== null ? editedOpeningHour.updatedOn : editedOpeningHour.createdOn,
            modifiedByName: editedOpeningHour.updatedBy !== null ? editedOpeningHour.updatedBy.firstName + " " + editedOpeningHour.updatedBy.lastName : editedOpeningHour.createdBy.firstName + " " + editedOpeningHour.createdBy.lastName,
            modifiedByAvatar: editedOpeningHour.updatedBy != null ? editedOpeningHour.updatedBy.avatar : editedOpeningHour.createdBy.avatar,
            onlineOpenFrom: editedOpeningHour.onlineOpenFrom,
            onlineOpenTill: editedOpeningHour.onlineOpenTill
        });
    }

    getOpeningHourById = (openingHourId, outletId) => {
        let resources = this.props.resources;
        const url = `/api/mobopro/v1/mobooutletopeninghours/${outletId}`;
        fetch(url, {
            method: 'get',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
        }).then(response => {
            if (!response.ok) {
                response.json().then(data => {
                    for (var i = 0; i < data.errors.length; i++) {
                        DisplayMessage(data.errors[i], "error");
                    }
                })
            } else {
                response.json().then(result => {
                    var existsOpeningHour = false;
                    var rowData = [];
                    result.forEach(function (row) {
                        if (row.id === openingHourId) {
                            existsOpeningHour = true;
                            rowData = row;
                        }
                    });
                    if (existsOpeningHour) {
                        var dayOfWeekName = GetDayOfWeekName(rowData.dayOfWeek, resources);

                        this.setState({
                            openingHour: rowData,
                            id: rowData.id,
                            outletId: rowData.moboOutletId,
                            workshopOpenFrom: rowData.workshopOpenFrom,
                            workshopOpenTill: rowData.workshopOpenTill,
                            workshopDropFrom: rowData.workshopDropFrom,
                            workshopDropTill: rowData.workshopDropTill,
                            workshopDropCloseNoonFrom: rowData.workshopDropCloseNoonFrom,
                            workshopDropCloseNoonTill: rowData.workshopDropCloseNoonTill,
                            isWorkshopClosed: rowData.isWorkshopClosed,
                            dayOfWeek: dayOfWeekName,
                            loading: false,
                            modifiedOn: rowData.updatedBy !== null ? rowData.updatedOn : rowData.createdOn,
                            modifiedByName: rowData.updatedBy !== null ? rowData.updatedBy.firstName + " " + rowData.updatedBy.lastName : rowData.createdBy.firstName + " " + rowData.createdBy.lastName,
                            modifiedByAvatar: rowData.updatedBy != null ? rowData.updatedBy.avatar : rowData.createdBy.avatar,
                            onlineOpenFrom: rowData.onlineOpenFrom,
                            onlineOpenTill: rowData.onlineOpenTill
                        });
                    }
                    else {
                        this.setState({ open: false, redirect: "/openinghours" });
                    }
                });
            }
        });
    }

    saveEditedOpeningHour = () => {
        this.setState({ requestInProgress: true });
        let resources = this.props.resources;

        const { openingHour, openingHourId, workshopOpenFrom, workshopOpenTill, workshopDropFrom, workshopDropTill, workshopDropCloseNoonFrom, workshopDropCloseNoonTill, isWorkshopClosed, dayOfWeek, onlineOpenFrom, onlineOpenTill } = this.state;

        if (isWorkshopClosed === false && (workshopOpenFrom === null || workshopOpenTill == null || workshopOpenFrom > workshopOpenTill)) {
            DisplayMessage(resources["InvalidWorkshopOpenTimeInterval"], "error");
            return;
        }

        if (isWorkshopClosed === false && (workshopDropFrom === null || workshopDropTill == null || workshopDropFrom > workshopDropTill)) {
            DisplayMessage(resources["InvalidWorkshopDropTimeInterval"], "error");
            return;
        }

        if (isWorkshopClosed === false && (workshopDropCloseNoonFrom !== null && workshopDropCloseNoonTill !== null && workshopDropCloseNoonFrom > workshopDropCloseNoonTill)) {
            DisplayMessage(resources["InvalidWorkshopOpenTimeInterval"], "error");
            return;
        }
        if (isWorkshopClosed === false && (onlineOpenFrom !== null && onlineOpenTill !== null && onlineOpenFrom > onlineOpenTill)) {
            DisplayMessage(resources["InvalidOnlineOpenTimeInterval"], "error");
            return;
        }

        var json = JSON.stringify({
            workshopOpenFrom: workshopOpenFrom === null ? "8:00" : workshopOpenFrom,
            workshopOpenTill: workshopOpenTill === null ? "17:00" : workshopOpenTill,
            workshopDropFrom: workshopDropFrom === null ? "8:00" : workshopDropFrom,
            workshopDropTill: workshopDropTill === null ? "16:00" : workshopDropTill,
            workshopDropCloseNoonFrom: workshopDropCloseNoonFrom === null ? openingHour.workshopDropCloseNoonFrom : workshopDropCloseNoonFrom,
            workshopDropCloseNoonTill: workshopDropCloseNoonTill === null ? openingHour.workshopDropCloseNoonTill : workshopDropCloseNoonTill,
            isWorkshopClosed: isWorkshopClosed,
            onlineOpenFrom: onlineOpenFrom,
            onlineOpenTill: onlineOpenTill
        });

        const url = `/api/mobopro/v1/mobooutletopeninghours/${openingHourId}`;
        fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                if (!response.ok) {
                    response.json().then(result => {

                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                    });

                }
                this.setState({ requestInProgress: false });
                return response.json();
            })
            .then(result => {
                if (result.id !== undefined) {
                    this.setState({
                        updateInProgress: false,
                        openingHourWasModified: true,
                        redirect: "/openinghours"
                    });

                    DisplayMessage(resources["OpeningHourWasSaved"], "success");
                }
            });
    }

    renderOutlets = () => {

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outlets = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outlets;
    }

    renderModifiedByInfo = () => {
        let resources = this.props.resources;
        if (this.state.id > 0 && this.state.modifiedOn !== null) {
            return (<Form.Group className="d-flex mt-3">
                <div className="w-100">
                    <span className=" mr-2">
                        <img className="rounded-circle" alt="user-avatar" src={this.state.modifiedByAvatar != null ? this.state.modifiedByAvatar : "/static/media/defaultavatar.02624da3.png"} width="35" />
                    </span>
                    <span>
                        {`${resources["ModifiedOn"]} ${moment(this.state.modifiedOn).format('DD/MM/YYYY HH:mm').toLocaleString()} ${resources["By"]} ${this.state.modifiedByName}`}
                    </span>
                </div>
            </Form.Group>);
        }
        return;
    }

    renderForm() {
        const { workshopOpenFrom, workshopOpenTill, workshopDropFrom, workshopDropTill, workshopDropCloseNoonFrom, workshopDropCloseNoonTill, isWorkshopClosed, dayOfWeek, onlineOpenFrom, onlineOpenTill } = this.state;

        var resources = this.props.resources;
        var displayTimetable = isWorkshopClosed ? "none" : "flex";

        return (
            <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Form>
                    <Form.Group style={{ display: "flex" }}>
                        <div className="col-md-3"></div>
                        <FormControl disabled={true} className="col-md-6 helper-text-red" variant="outlined" onChange={this.handleChangeOutlet}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="openinghours-outlet"
                                native
                                label={resources["Outlet"]}
                                value={this.state.outletId}
                                inputProps={{
                                    name: 'outlet',
                                    id: 'outlined-outlet-native-simple',
                                }}>
                                {this.renderOutlets()}
                            </Select>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="pt-3" style={{ display: "flex" }} onClick={this.handleTyping}>
                        <div className="col-md-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField disabled={true} className={"col-6 "} id="openinghours-dayOfWeek" value={dayOfWeek} label={resources["DayOfWeek"]}
                                variant="outlined" name="dayOfWeek" onChange={this.handleChange} inputProps={{ maxLength: 100 }} />
                        </ThemeProvider>
                    </Form.Group>


                    <Form.Group style={{ display: "flex" }} >
                        <div className="col-md-3 "> </div>
                        <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                            control={<Checkbox id="openinghours-is-active" checked={isWorkshopClosed} onChange={this.handleChangeCheckBox} name="isWorkshopClosed" color="primary" />}
                            label={resources["IsWorkshopClosed"]} />
                        <div className="col-md-4"> </div>
                    </Form.Group>
                    <Grid container spacing={2} className="mb-5" style={{ display: displayTimetable }}>
                        <Grid item xs={5}>
                            <Typography variant="h6"> </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h6" variant="caption" display="block">{resources["From"]}<span > </span></Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h6" variant="caption" display="block">{resources["Till"]}<span > </span></Typography>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <React.Fragment>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={3} >
                                <Typography variant="subtitle1">{resources["WorkshopOpen"]}<span className="validation-red"> *</span></Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} format={format} name="workshopOpenFrom" value={workshopOpenFrom !== null ? moment(workshopOpenFrom, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(timeString, "workshopOpenFrom")} />
                            </Grid>
                            <Grid item xs={2}>
                                <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} format={format} name="workshopOpenTill" value={workshopOpenTill !== null ? moment(workshopOpenTill, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(timeString, "workshopOpenTill")} />
                            </Grid>

                            <Grid item xs={3} style={{ textAlign: "left" }}>
                                <IconButton size="small"><DeleteIcon onClick={() => this.clearTime("workshopOpenFrom", "workshopOpenTill")} color="primary" /></IconButton>
                            </Grid>
                        </React.Fragment>
                        <React.Fragment>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={3} >
                                <Typography variant="subtitle1">{resources["WorkshopDrop"]}<span className="validation-red"> *</span></Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} label="test" format={format} name="workshopDropFrom" value={workshopDropFrom !== null ? moment(workshopDropFrom, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(timeString, "workshopDropFrom")} />
                            </Grid>
                            <Grid item xs={2}>
                                <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} format={format} name="workshopDropTill" value={workshopDropTill !== null ? moment(workshopDropTill, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(timeString, "workshopDropTill")} />
                            </Grid>

                            <Grid item xs={3} style={{ textAlign: "left" }}>
                                <IconButton size="small"><DeleteIcon onClick={() => this.clearTime("workshopDropFrom", "workshopDropTill")} color="primary" /></IconButton>
                            </Grid>
                        </React.Fragment>
                        <React.Fragment>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={3} >
                                <Typography variant="subtitle1">{resources["WorkshopDropCloseNoon"]}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} format={format} name="workshopDropCloseNoonFrom" value={workshopDropCloseNoonFrom !== null ? moment(workshopDropCloseNoonFrom, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(timeString, "workshopDropCloseNoonFrom")} />
                            </Grid>
                            <Grid item xs={2}>
                                <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} format={format} name="workshopDropCloseNoonTill" value={workshopDropCloseNoonTill !== null ? moment(workshopDropCloseNoonTill, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(timeString, "workshopDropCloseNoonTill")} />
                            </Grid>

                            <Grid item xs={3} style={{ textAlign: "left" }}>
                                <IconButton size="small"><DeleteIcon onClick={() => this.clearTime("workshopDropCloseNoonFrom", "workshopDropCloseNoonTill")} color="primary" /></IconButton>
                            </Grid>
                        </React.Fragment>
                        <React.Fragment>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={3} >
                                <Typography variant="subtitle1">{resources["OnlineOpen"]}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} format={format} name="onlineOpenFrom" value={onlineOpenFrom !== null ? moment(onlineOpenFrom, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(timeString, "onlineOpenFrom")} />
                            </Grid>
                            <Grid item xs={2}>
                                <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} format={format} name="onlineOpenTill" value={onlineOpenTill !== null ? moment(onlineOpenTill, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(timeString, "onlineOpenTill")} />
                            </Grid>

                            <Grid item xs={3} style={{ textAlign: "left" }}>
                                <IconButton size="small"><DeleteIcon onClick={() => this.clearTime("onlineOpenFrom", "onlineOpenTill")} color="primary" /></IconButton>
                            </Grid>
                        </React.Fragment>

                    </Grid>

                    {this.renderModifiedByInfo()}
                </Form>
            </Paper>
        );
    }

    renderMain() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {this.renderForm()}
        </div>;
    }

    render() {
        const { redirect, open, openingHourWasModified, outletId } = this.state;
        const { resources } = this.props;

        return (
            <div>
                {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: openingHourWasModified, outletId } }} delay={250} />}
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton id="close-edit-OpeningHour" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["EditOpeningHour"]}
                            </Typography>
                            <Button id="openinghours-save" color="inherit" onClick={this.handleSubmit}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        {this.renderMain()}
                    </div>
                </Dialog>
            </div >
        );
    }
}