export const CreateAssetGroupUrl = `api/mobopro/v1/assets/groups`;
export const GetAssetGroupsUrl = `api/mobopro/v1/assets/groups?onlyactive=false`;
export const DeleteAssetGroupUrl = (id) => {
  return `api/mobopro/v1/assets/groups/${id}`;
};
export const UpdateAssetGroupUrl = (id) => {
  return `api/mobopro/v1/assets/groups/${id}`;
};
export const GetAssetGroupByIdUrl = (id) => {
  return `api/mobopro/v1/assets/groups/${id}`;
};

