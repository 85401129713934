import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import ChatGroupsTable from '../components/General/ChatGroups/ChatGroupsTable';
import ChatGroupsCreation from '../components/General/ChatGroups/ChatGroupCreation';
import ChatGroupsEditing from '../components/General/ChatGroups/ChatGroupEditing';
import { Permissions } from '../utils/Permissions';

export default function ChatGroupsRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/chatgroups' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.AccountManagement]} resources={resources} render={(props) => {
                return (<ChatGroupsTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} linkAgendaWasCreated={props && props.location && props.location.state && props.location.state.linkAgendaWasCreated} />)
            }} />
            <Switch>
                <Route exact path='/chatgroups/new' render={(props) => (<ChatGroupsCreation resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} outletId={props && props.location && props.location.state && props.location.state.outletId} />)} />
                <Route exact path='/chatgroups/:chatgroupId' render={(props) => {
                    return (<ChatGroupsEditing resources={resources} chatgroupId={props.match.params.chatgroupId} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} {...props} />)
                }} />
            </Switch>
        </>
    )
}