import { Box, CircularProgress, Typography } from "@material-ui/core";
import React from "react";

const Header = ({ title, loading }) => {
  return (
    <Box className="position-relative py-4">
      <Typography variant="h3">{title}</Typography>
      {loading && (
        <Box className="offcentered-loader fadeIn">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default Header;
