import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage, ExcludeSpecialCharactersFromInputTypeNumber } from '../../../services/helpers.js';
import DelayedRedirect from '../../../utils/DelayedRedirect';
import { Transition } from '../../../utils/ModalHelpers';
import { Button, FormHelperText } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";
import { DropzoneArea } from 'material-ui-dropzone';
import Link from '@material-ui/core/Link';
import Rodal from 'rodal';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

export default class OutletUserEditing extends Component {
    constructor(props) {
        super(props);

        var outletUserId = this.props.outletUserId !== undefined ? parseInt(this.props.outletUserId) : undefined;
        this.state = {
            id: outletUserId,
            email: "",
            firstName: "",
            lastName: "",
            initials: "",
            language: "nl-be",
            gender: "m",
            phone: "",
            dmsReference: "",
            dmsReference2: "",
            clockReference: "",
            planningReference: "",

            pin: "",
            password: "",
            workPreparationRoles: [],
            workPreparationRoleId: 0,

            createdOn: "",
            createdByName: "",
            createdByAvatar: "",

            profileImageUri: "",
            internalDialogName: "",
            internalPhoneNumber: "",
            file: undefined,
            initialFiles: [],
            isActive: false,

            loading: outletUserId !== undefined,
            accessibleMoboOutlets: [],
            requestInProgress: false,
            redirect: false,
            open: true,

            outletUserWasCreated: false,

            showRodalOutlets: false,
            initialOutlets: [],
            mainOutlet: 0,
            selectedOutlets: [],
            selectedOutletsName: "",
            selectedOutletsChecked: [],
            selectedOutletsCheckedName: "",

            roles: [],
            showRodalRoles: false,
            selectedRoles: [],
            selectedRolesName: "",
            selectedRolesChecked: [],
            selectedRolesCheckedName: "",

            permissions: [],
            showRodalPermissions: false,
            selectedPermissions: [],
            selectedPermissionsName: "",

            chatGroups: [],
            showRodalChatGroups: false,
            selectedChatGroups: [],
            selectedChatGroupsName: "",
            selectedChatGroupsChecked: [],
            selectedChatGroupsCheckedName: "",

            dossierStacks: [],
            showRodalDossierStacks: false,
            selectedDossierStacks: [],
            selectedDossierStacksName: "",
            selectedDossierStacksChecked: [],
            selectedDossierStacksCheckedName: "",

            showRodalNewPin: false,
            showRodalResetPassword: false,
            showRodalNewPassword: false,

            loadingRoles: true,
            loadingPermissions: true,
            loadingChatgroups: true,
            loadingDossierStacks: true
        };

    }

    componentDidMount() {
        this.getWorkPreparationRoles();
        this.getRoles();
        this.getPermissions();
        this.getChatGroups();
        this.getDossierStacks();
        this.getData(this.state.id);
    }

    getData = (outletUserId) => {
        fetch(`/api/mobopro/v1/outletuser/${outletUserId}`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                response.json().then(result => {
                    if (!response.ok) {
                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                    }
                    else {

                        var selectedOutlets = [];
                        var selectedOutletsName = "";
                        var selectedOutletsChecked = [];
                        var selectedOutletsCheckedName = "";
                        var mainOutlet = 0;

                        var selectedRoles = [];
                        var selectedRolesName = "";
                        var selectedRolesChecked = [];
                        var selectedRolesCheckedName = "";

                        var selectedPermissions = [];
                        var selectedPermissionsName = "";
                        var selectedPermissionsChecked = [];
                        var selectedPermissionsCheckedName = "";

                        var selectedChatGroups = [];
                        var selectedChatGroupsName = "";
                        var selectedChatGroupsChecked = [];
                        var selectedChatGroupsCheckedName = "";

                        var selectedDossierStacks = [];
                        var selectedDossierStacksName = "";
                        var selectedDossierStacksChecked = [];
                        var selectedDossierStacksCheckedName = "";
                        var initialOutlets = [];

                        result.moboOutlets.forEach(item => {
                            if (item.linked === true) {
                                selectedOutlets.push(item.id);
                                selectedOutletsChecked.push(item.id);
                                selectedOutletsName = selectedOutletsName + (selectedOutletsName !== "" ? ", " + item.description : item.description);
                                selectedOutletsCheckedName = selectedOutletsCheckedName + (selectedOutletsCheckedName !== "" ? ", " + item.description : item.description);
                                initialOutlets.push(item.id);
                                if (item.isMainOutlet === true) mainOutlet = item.id;
                            }
                        });

                        result.roles.forEach(item => {
                            if (item.linked === true) {
                                selectedRoles.push(item.id);
                                selectedRolesChecked.push(item.id);
                                selectedRolesName = selectedRolesName + (selectedRolesName !== "" ? ", " + item.description : item.description);
                                selectedRolesCheckedName = selectedRolesCheckedName + (selectedRolesCheckedName !== "" ? ", " + item.description : item.description);
                            }
                        });

                        result.permissions.forEach(item => {
                            if (item.linked === true) {
                                selectedPermissions.push(item.id);
                                selectedPermissionsChecked.push(item.id);
                                selectedPermissionsName = selectedPermissionsName + (selectedPermissionsName !== "" ? ", " + item.description : item.description);
                                selectedPermissionsCheckedName = selectedPermissionsCheckedName + (selectedPermissionsCheckedName !== "" ? ", " + item.description : item.description);
                            }
                        });

                        result.chatGroups.forEach(item => {
                            selectedChatGroups.push(item.id);
                            selectedChatGroupsChecked.push(item.id)
                            selectedChatGroupsName = selectedChatGroupsName + (selectedChatGroupsName !== "" ? ", " + item.description : item.description);
                            selectedChatGroupsCheckedName = selectedChatGroupsCheckedName + (selectedChatGroupsCheckedName !== "" ? ", " + item.description : item.description);
                        });

                        result.dossierStacks.forEach(item => {
                            selectedDossierStacks.push(item.stackId);
                            selectedDossierStacksChecked.push(item.stackId);
                            selectedDossierStacksName = selectedDossierStacksName + (selectedDossierStacksName !== "" ? ", " + item.description : item.description);
                            selectedDossierStacksCheckedName = selectedDossierStacksCheckedName + (selectedDossierStacksCheckedName !== "" ? ", " + item.description : item.description);
                        });

                        result.workPreparationRoleId = result.workPreparationRole !== null && result.workPreparationRole !== undefined ? result.workPreparationRole.id : undefined;

                        this.setState({
                            id: result.id,
                            createdOn: result.createdOn,
                            createdByName: result.createdBy,
                            createdByAvatar: result.createdByAvatar,

                            firstName: result.firstName,
                            lastName: result.lastName,
                            email: result.username,
                            isActive: result.isActive,
                            isDeleted: result.isDeleted,
                            dmsReference: result.dmsReference,
                            dmsReference2: result.dmsReference2,
                            clockReference: result.clockReference,
                            planningReference: result.planningReference,

                            initials: result.initials,
                            phone: result.phone ?? "",
                            internalPhoneNumber: result.internalPhoneNumber ?? "",
                            profileImageUri: result.avatar,
                            language: result.culture ?? "nl-be",

                            gender: result.gender,
                            internalDialogName: result.internalDialogName,
                            workPreparationRoleId: result.workPreparationRoleId,

                            selectedOutlets,
                            selectedOutletsName,
                            selectedOutletsChecked,
                            selectedOutletsCheckedName,
                            mainOutlet,
                            initialOutlets,
                            outletsWasUpdated: false,

                            selectedRoles,
                            selectedRolesName,
                            selectedRolesChecked,
                            selectedRolesCheckedName,
                            rolesWasUpdated: false,

                            selectedPermissions,
                            selectedPermissionsName,
                            selectedPermissionsChecked,
                            selectedPermissionsCheckedName,
                            permissionsWasUpdated: false,

                            selectedChatGroups,
                            selectedChatGroupsName,
                            selectedChatGroupsChecked,
                            selectedChatGroupsCheckedName,
                            chatGroupsWasUpdated: false,

                            selectedDossierStacks,
                            selectedDossierStacksName,
                            selectedDossierStacksChecked,
                            selectedDossierStacksCheckedName,
                            dossierStacksWasUpdated: false,

                            fetchingData: false,
                            loading: false,
                        });
                    }
                });
            });
    }

    handleClose = () => {
        this.setState({ open: false, redirect: "/outletusers" });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value }, () => this.checkIfRequestIsValid(false));
    };

    handleChangeName = e => {
        this.setState({ [e.target.name]: e.target.value, initials: "" }, () => this.checkIfRequestIsValid(false));
    };

    handleChangeChatName = e => {
        this.setState({ [e.target.name]: e.target.value.trim() }, () => this.checkIfRequestIsValid(false));
    };

    handleChangeWorkPreparationRoles = e => {
        this.setState({ workPreparationRoleId: parseInt(e.target.value) });
    };

    checkIfEmailIsValid(displayErrorMessage) {
        let resources = this.props.resources;

        var filterEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,63})$/;
        var isEmailValid = filterEmail.test(this.state.email);

        if (!isEmailValid) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidEmail"], "error");
        }

        return isEmailValid;
    }

    checkIfPinIsValid() {
        var pin = this.state.pin;
        if (pin < 1000 || pin > 9999) return false;

        var filterPin = /^[0-9]{4}$/;
        var isPinValid = filterPin.test(this.state.pin);

        return isPinValid;
    }

    checkIfPasswordIsValid() {
        const regexForValidation = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[%!@#$%^&*()?>.<,:;'|}\]{\[_~`+\-=\\""])(?!.*\s).{8,14}$/;
        var password = this.state.password;

        return regexForValidation.test(password);
    }

    checkIfInternalDialogNameIsValid(internalDialogName) {
        if (internalDialogName === "") return true;

        var filterChatName = /^[a-zA-Z\u00C0-\u00FF0-9\s]*$/;
        return filterChatName.test(internalDialogName);
    }

    checkIfPhoneIsValid(phone) {
        if (phone === "") return true;

        var filterPhone = /[0-9+]{1,20}$/;
        return filterPhone.test(phone);
    }

    checkIfRequestIsValid(displayErrorMessage) {
        let resources = this.props.resources;
        var isValidRequest = true;

        isValidRequest = this.checkIfEmailIsValid(displayErrorMessage);

        if (this.state.firstName.trim().length <= 0) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidFirstName"], "error");
            isValidRequest = false;
        }

        if (this.state.lastName.trim().length <= 0) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidLastName"], "error");
            isValidRequest = false;
        }

        if (this.state.password !== undefined && this.state.password.length > 0) {
            if (!this.checkIfPasswordIsValid()) {
                if (displayErrorMessage) DisplayMessage(resources["InvalidPassword"], "error");
                isValidRequest = false;
            }
        }

        if (this.state.pin !== "" && this.state.pin.length > 0) {
            var isPinValid = this.checkIfPinIsValid();
            if (!isPinValid) {
                if (displayErrorMessage) DisplayMessage(resources["InvalidPin"], "error");
                isValidRequest = false;
            }
        }

        if (this.state.phone !== undefined && this.state.phone !== "") {
            let isPhoneValid = this.checkIfPhoneIsValid(this.state.phone);
            if (!isPhoneValid) {
                if (displayErrorMessage) DisplayMessage(resources["InvalidPhone"], "error");
                isValidRequest = false;
            }
        }

        if (this.state.internalPhoneNumber !== undefined && this.state.internalPhoneNumber !== "") {
            let isPhoneValid = this.checkIfPhoneIsValid(this.state.internalPhoneNumber);
            if (!isPhoneValid) {
                if (displayErrorMessage) DisplayMessage(resources["InvalidInternalPhoneNumber"], "error");
                isValidRequest = false;
            }
        }

        if (this.state.internalDialogName !== undefined) {
            var isInternaDialogNameValid = this.checkIfInternalDialogNameIsValid(this.state.internalDialogName);
            if (!isInternaDialogNameValid) {
                if (displayErrorMessage) DisplayMessage(resources["InvalidChatName"], "error");
                isValidRequest = false;
            }
        }

        if (this.state.mainOutlet < 1 || this.state.selectedRoles.length < 1) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidOutletsOrRoles"], "error");
            isValidRequest = false;
        }

        this.setState({ requestInProgress: !isValidRequest });

        return isValidRequest;
    }

    generateInitials = (a) => {
        a = a.replace('.', '');
        a = a.replace('Jr', '');
        a = a.replace('Sr', '');
        a = a.replace('-', ' ');

        var c = a.split(" ");
        var res = c.map((a, b) => b < c.length - 1 ? a.substring(0, 1) : a.substring(0, 1))
        return res.join("").toUpperCase();
    }

    getWorkPreparationRoles = () => {
        const url = "/api/mobopro/v1/workpreparations/roles";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const workPreparationRoles = [];

                result.forEach(function (item) {
                    workPreparationRoles.push({
                        id: item.id,
                        description: item.description,
                        isActive: item.isActive
                    });
                });

                var workPreparationRolesActive = workPreparationRoles.filter(x => x.isActive === true);
                var workPreparationRolesSorted = workPreparationRolesActive.sort((a, b) => (a.description > b.description) ? 1 : -1);


                this.setState({
                    workPreparationRoles: workPreparationRolesSorted,
                });
            });
    }

    //(1) : input of personal data
    saveOutletUser = () => {
        this.setState({ requestInProgress: true });

        let resources = this.props.resources;

        if (!this.checkIfRequestIsValid(true)) return;
        var outletUserId = this.state.id;
        var json = JSON.stringify({
            id: outletUserId,
            username: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            initials: this.state.initials === "" ? this.generateInitials(this.state.firstName.trim() + " " + this.state.lastName.trim()) : this.state.initials,
            phone: this.state.phone === "" ? undefined : this.state.phone,
            internalPhoneNumber: this.state.internalPhoneNumber === "" ? undefined : this.state.internalPhoneNumber,
            gender: this.state.gender,
            internalDialogName: this.state.internalDialogName === "" ? undefined : this.state.internalDialogName,
            culture: this.state.language,
            dmsReference: this.state.dmsReference,
            dmsReference2: this.state.dmsReference2,
            clockReference: this.state.clockReference,
            planningReference: this.state.planningReference,
            active: true,
            workPreparationRoleId: this.state.workPreparationRoleId !== undefined && this.state.workPreparationRoleId > 0 ? this.state.workPreparationRoleId : undefined
        });

        fetch('/api/mobopro/v1/outletuser', {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                })
            }
            else {
                DisplayMessage(resources["OutletUserWasSaved"], "success");

                // (2) Save outlet user profile image
                this.handleSaveOutletUserProfileImage(outletUserId);

                // (3) Save linked outlets
                if (this.state.outletsWasUpdated === true) this.handleSaveOutletUserOutlets(outletUserId);

                // (4) Save linked roles & permissions
                if (this.state.rolesWasUpdated === true || this.state.permissionsWasUpdated === true) this.handleSaveOutletUserRolesAndPermissions(outletUserId);

                // (5) Save linked chat groups
                if (this.state.chatGroupsWasUpdated === true) this.handleSaveOutletUserChatGroups(outletUserId);

                // (6) Save linked dossier stacks
                if (this.state.dossierStacksWasUpdated === true) this.handleSaveOutletUserDossierStacks(outletUserId);

                this.setState({ outletUserWasCreated: true, redirect: "/outletusers" });
            }
        })
    }

    handleSaveOutletUserProfileImage = (outletId) => {
        let resources = this.props.resources;

        if (outletId < 1 || this.state.file === undefined) return;

        var formData = new FormData();
        formData.append("image", this.state.file);

        fetch(`/api/mobopro/v1/outletuserimage/${outletId}`, {
            method: 'PUT',
            headers: new Headers({
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: formData
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["OutletUserImageWasSaved"], "success");
            }
        });
    }

    handleSaveOutletUserOutlets = (outletId) => {
        let resources = this.props.resources;

        if (outletId < 1 || this.state.mainOutlet < 1) return;

        var json = JSON.stringify({
            id: outletId,
            mainMoboOutletId: this.state.mainOutlet,
            moboOutletIds: this.state.selectedOutlets,
        });


        fetch(`/api/mobopro/v1/outletuseroutlets`, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });

            }
            else {
                DisplayMessage(resources["OutletUserOutletsWasSaved"], "success");
            }
        });
    }

    handleSaveOutletUserRolesAndPermissions = (outletId) => {
        let resources = this.props.resources;

        if (outletId < 1 || this.state.selectedRolesChecked.length === 0) return;

        var json = JSON.stringify({
            id: outletId,
            roleIDs: this.state.selectedRoles,
            permissionIDs: this.state.selectedPermissions,
        });


        fetch(`/api/mobopro/v1/outletuserrolesandpermissions`, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });

            }
            else {
                DisplayMessage(resources["OutletUserRolesAndPermissionsWasSaved"], "success");
            }
        });
    }

    handleSaveOutletUserChatGroups = (outletId) => {
        let resources = this.props.resources;

        if (outletId < 1) return;

        var json = JSON.stringify({
            outletUserId: outletId,
            internalChatGroups: this.state.selectedChatGroups,
        });

        fetch(`/api/mobopro/v1/outletuserchatgroups`, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });

            }
            else {
                DisplayMessage(resources["OutletUserChatGroupsWasSaved"], "success");
            }
        });
    }

    handleSaveOutletUserDossierStacks = (outletId) => {
        let resources = this.props.resources;

        if (outletId < 1) return;

        var json = JSON.stringify({
            id: outletId,
            stackIds: this.state.selectedDossierStacks,
        });

        fetch(`/api/mobopro/v1/outletuserdossierstackaccess`, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });

            }
            else {
                DisplayMessage(resources["OutletUserDossierStackAccessWasSaved"], "success");
            }
        });
    }

    // Mobo Outlets
    isAnyOutletRemoved = e => {
        const { initialOutlets, selectedOutletsChecked } = this.state;

        const selectedOutletsSet = new Set(selectedOutletsChecked);
    
        for (let i = 0; i < initialOutlets.length; i++) {
            if (!selectedOutletsSet.has(initialOutlets[i])) {
                this.setState({
                    initialOutlets: selectedOutletsChecked
                })
                return true;
            }
        }
    
        return false;
    };

    handleSelectOutlet = e => {
        var { mainOutlet, selectedOutletsChecked, selectedOutletsCheckedName } = this.state;
        var selectedOutletId = parseInt(e.target.name);

        var outletDesc = this.props.accessibleMoboOutlets.find(x => x.id === selectedOutletId).description;

        if (e.target.checked) {
            selectedOutletsCheckedName = selectedOutletsCheckedName + (selectedOutletsCheckedName !== "" ? ", " + outletDesc : outletDesc);
            this.setState({
                selectedOutletsChecked: [...this.state.selectedOutletsChecked, selectedOutletId],
                selectedOutletsCheckedName,
                requestInProgress: mainOutlet < 1,
                outletsWasUpdated: true,
                chatGroupsWasUpdated: true,
                dossierStacksWasUpdated: true,
            });
        }
        else {
            var index = selectedOutletsChecked.indexOf(selectedOutletId);
            if (selectedOutletId !== mainOutlet && index !== -1) {
                selectedOutletsChecked = selectedOutletsChecked.filter((_, i) => i !== index);
                selectedOutletsCheckedName = selectedOutletsCheckedName.replace(", " + outletDesc, "").replace(outletDesc + ", ", "").replace(outletDesc, "");
                this.setState({
                    selectedOutletsChecked,
                    selectedOutletsCheckedName,
                    requestInProgress: mainOutlet < 1,
                    outletsWasUpdated: true,
                    chatGroupsWasUpdated: true,
                    dossierStacksWasUpdated: true,
                });
            }

        }

    };

    handleChangeMainOutlet = e => {
        var { selectedOutletsChecked, selectedOutletsCheckedName } = this.state;
        var mainOutlet = parseInt(e.target.value);

        if (selectedOutletsChecked.indexOf(mainOutlet) === -1 && mainOutlet > 0) {
            selectedOutletsChecked.push(mainOutlet);
            if (selectedOutletsCheckedName !== "") selectedOutletsCheckedName = selectedOutletsCheckedName + ", ";

            selectedOutletsCheckedName = selectedOutletsCheckedName + this.props.accessibleMoboOutlets.find(x => x.id === mainOutlet).description;
        }

        this.setState({
            selectedOutletsChecked,
            selectedOutletsCheckedName,
            mainOutlet,
            requestInProgress: mainOutlet < 1,
            outletsWasUpdated: true,
            chatGroupsWasUpdated: true,
            dossierStacksWasUpdated: true
        });

    };

    renderSelectMainOutlet = () => {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        outlets = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outlets;
    }

    renderSelectOutlets = () => {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;
        outlets = outlets.map(x =>

            <Form.Group className=" mb-0 text-left" >
                <FormControlLabel className="col-md-11 text-right label-mt my-auto p-0"
                    control={<Checkbox id="indicators-is-active" style={{ padding: "5px 9px!important" }} checked={this.state.selectedOutletsChecked.indexOf(x.id) !== -1}
                        onChange={this.handleSelectOutlet} name={x.id} value={x.id} key={x} color="primary" />}
                    label={x.description} />
            </Form.Group>
        );
        return outlets;
    }

    handleShowRodalOutlets = (showModal) => {
        var { selectedOutlets, selectedOutletsName } = this.state;
        if (showModal === false) this.setState({ showRodalOutlets: showModal, selectedOutletsChecked: selectedOutlets, selectedOutletsCheckedName: selectedOutletsName });
        else this.setState({ showRodalOutlets: showModal });

    }

    handleConfirmSelectedOutlets = () => {
        let resources = this.props.resources;

        var { selectedOutletsChecked, selectedOutletsCheckedName } = this.state;

        if (this.state.selectedOutletsChecked.length > 0) {
            this.getChatGroups();
            this.getDossierStacks();
            var numberOfChatGroups = this.state.selectedChatGroups.length;
            var numberOfDossierStacks = this.state.selectedDossierStacks.length;
            if(this.isAnyOutletRemoved()){
                this.setState({
                    showRodalOutlets: false,
                    selectedChatGroups: [],
                    selectedChatGroupsName: "",
                    selectedChatGroupsChecked: [],
                    selectedChatGroupsCheckedName: "",
                    selectedDossierStacks: [],
                    selectedDossierStacksName: "",
                    selectedDossierStacksChecked: [],
                    selectedDossierStacksCheckedName: "",
                    selectedOutlets: selectedOutletsChecked,
                    selectedOutletsName: selectedOutletsCheckedName,
                    loadingChatgroups: true,
                    loadingDossierStacks: true,
                });

                if (numberOfChatGroups > 0) DisplayMessage(resources["SelectedChatGoupsWasReset"], "info");
                if (numberOfDossierStacks > 0) DisplayMessage(resources["SelectedDossierStacksWasReset"], "info");
            }
            else{
                this.setState({
                    showRodalOutlets: false,
                    selectedOutlets: selectedOutletsChecked,
                    selectedOutletsName: selectedOutletsCheckedName,
                    loadingChatgroups: true,
                    loadingDossierStacks: true,
                });
            }

        }
    }



    //Roles
    handleSelectRoles = e => {
        var { selectedRolesChecked, selectedRolesCheckedName, roles, selectedRoles } = this.state;

        var selectedRoleId = parseInt(e.target.name);
        var roleDesc = roles.find(x => x.id === selectedRoleId).description;

        if (e.target.checked) {
            selectedRolesCheckedName = selectedRolesCheckedName + (selectedRolesCheckedName !== "" ? ", " + roleDesc : roleDesc);
            this.setState({ selectedRolesChecked: [...this.state.selectedRolesChecked, selectedRoleId], selectedRolesCheckedName, requestInProgress: selectedRoles.length < 1, rolesWasUpdated: true });
        }
        else {
            var index = selectedRolesChecked.indexOf(selectedRoleId);
            if (index !== -1) {
                selectedRolesChecked = selectedRolesChecked.filter((_, i) => i !== index);
                selectedRolesCheckedName = selectedRolesCheckedName.replace(", " + roleDesc, "").replace(roleDesc + ", ", "").replace(roleDesc, "");
                this.setState({ selectedRolesChecked, selectedRolesCheckedName, requestInProgress: selectedRoles.length < 1, rolesWasUpdated: true });
            }

        }
    };

    getRoles = () => {

        const url = "/api/mobopro/v1/outletuserroles/";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const roles = [];

                result.forEach(function (item) {
                    roles.push({
                        id: item.id,
                        description: item.description,
                    });
                });
                var rolesSorted = roles.sort((a, b) => (a.description > b.description) ? 1 : -1);

                this.setState({
                    roles: rolesSorted,
                    loadingRoles: false,
                });

            });
    }

    renderSelectRoles = () => {
        var rolesSorted = this.state.roles;

        var rolesMapped = rolesSorted.map(x =>

            <Form.Group className=" mb-0 text-left" >
                <FormControlLabel className="col-md-11 text-right label-mt my-auto p-0"
                    control={<Checkbox id="indicators-is-active" style={{ padding: "5px 9px!important" }} checked={this.state.selectedRolesChecked.indexOf(x.id) !== -1}
                        onChange={this.handleSelectRoles} name={x.id} color="primary" />}
                    label={x.description} />
            </Form.Group>
        );
        return rolesMapped;
    }

    handleShowRodalRoles = (showModal) => {
        var { selectedRoles, selectedRolesName } = this.state;
        if (showModal === false) this.setState({ showRodalRoles: showModal, selectedRolesChecked: selectedRoles, selectedRolesCheckedName: selectedRolesName });
        else this.setState({ showRodalRoles: showModal });
    }

    handleConfirmSelectedRoles = () => {
        var { selectedRolesChecked, selectedRolesCheckedName } = this.state;

        if (this.state.selectedRolesChecked.length > 0) {
            this.setState({ showRodalRoles: false, selectedRoles: selectedRolesChecked, selectedRolesName: selectedRolesCheckedName });
        }
    }

    //Permissions
    handleSelectPermissions = e => {
        var { selectedPermissionsChecked, selectedPermissionsCheckedName, permissions } = this.state;

        var selectedPermissionId = parseInt(e.target.name);
        var permissionDesc = permissions.find(x => x.id === selectedPermissionId).description;

        if (e.target.checked) {
            selectedPermissionsCheckedName = selectedPermissionsCheckedName + (selectedPermissionsCheckedName !== "" ? ", " + permissionDesc : permissionDesc);
            this.setState({ selectedPermissionsChecked: [...this.state.selectedPermissionsChecked, selectedPermissionId], selectedPermissionsCheckedName, permissionsWasUpdated: true });
        }
        else {
            var index = selectedPermissionsChecked.indexOf(selectedPermissionId);
            if (index !== -1) {
                selectedPermissionsChecked = selectedPermissionsChecked.filter((_, i) => i !== index);
                selectedPermissionsCheckedName = selectedPermissionsCheckedName.replace(", " + permissionDesc, "").replace(permissionDesc + ", ", "").replace(permissionDesc, "");
                this.setState({ selectedPermissionsChecked, selectedPermissionsCheckedName, permissionsWasUpdated: true });
            }
        }
    };

    getPermissions = () => {
        const url = "/api/mobopro/v1/outletuserpermissions";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const permissions = [];

                result.forEach(function (item) {
                    permissions.push({
                        id: item.id,
                        description: item.description,
                    });
                });
                var permissionsSorted = permissions.sort((a, b) => (a.description > b.description) ? 1 : -1);

                this.setState({
                    permissions: permissionsSorted,
                    loadingPermissions: false,
                });

            });
    }

    renderPermissions = () => {
        var permissionsSorted = this.state.permissions;

        var permissionsMapped = permissionsSorted.map(x =>

            <Form.Group className=" mb-0 text-left" >
                <FormControlLabel className="col-md-11 text-right label-mt my-auto p-0"
                    control={<Checkbox id="indicators-is-active" style={{ padding: "5px 9px!important" }} checked={this.state.selectedPermissionsChecked.indexOf(x.id) !== -1}
                        onChange={this.handleSelectPermissions} name={x.id} color="primary" />}
                    label={x.description} />
            </Form.Group>
        );
        return permissionsMapped;
    }

    handleShowRodalPermissions = (showModal) => {
        var { selectedPermissions, selectedPermissionsName } = this.state;
        if (showModal === false) this.setState({ showRodalPermissions: showModal, selectedPermissionsChecked: selectedPermissions, selectedPermissionsCheckedName: selectedPermissionsName });
        else this.setState({ showRodalPermissions: showModal });
    }

    handleConfirmSelectedPermissions = () => {
        var { selectedPermissionsChecked, selectedPermissionsCheckedName } = this.state;

        if (this.state.selectedPermissionsChecked.length >= 0) {
            this.setState({ showRodalPermissions: false, selectedPermissions: selectedPermissionsChecked, selectedPermissionsName: selectedPermissionsCheckedName });
        }
    }


    //ChatGroups
    handleSelectChatGroups = e => {
        var { selectedChatGroupsChecked, selectedChatGroupsCheckedName, chatGroups } = this.state;

        var selectedChatGroupId = parseInt(e.target.name);
        var chatGroupDesc = chatGroups.find(x => x.id === selectedChatGroupId).description;

        if (e.target.checked) {
            selectedChatGroupsCheckedName = selectedChatGroupsCheckedName + (selectedChatGroupsCheckedName !== "" ? ", " + chatGroupDesc : chatGroupDesc);
            this.setState({ selectedChatGroupsChecked: [...this.state.selectedChatGroupsChecked, selectedChatGroupId], selectedChatGroupsCheckedName, chatGroupsWasUpdated: true });
        }
        else {
            var index = selectedChatGroupsChecked.indexOf(selectedChatGroupId);
            if (index !== -1) {
                selectedChatGroupsChecked = selectedChatGroupsChecked.filter((_, i) => i !== index);
                selectedChatGroupsCheckedName = selectedChatGroupsCheckedName.replace(", " + chatGroupDesc, "").replace(chatGroupDesc + ", ", "").replace(chatGroupDesc, "");
                this.setState({ selectedChatGroupsChecked, selectedChatGroupsCheckedName, chatGroupsWasUpdated: true });
            }

        }
    };

    getChatGroups = (showModal) => {

        var selectedOutlets = this.state.selectedOutletsChecked;
        if (selectedOutlets.length < 1) return;
        const url = "/api/mobopro/v1/internalchatgroups?includenonactive=false";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const chatGroups = [];

                result.forEach(function (item) {

                    if (item.moboOutletID === null || selectedOutlets.includes(item.moboOutletID)) {
                        chatGroups.push({
                            id: item.id,
                            description: item.description,
                        });
                    }
                });
                var chatGroupsSorted = chatGroups.sort((a, b) => (a.description > b.description) ? 1 : -1);

                this.setState({
                    chatGroups: chatGroupsSorted,
                    loadingChatgroups: false,

                });
            });
    }

    renderChatGroups = () => {
        var chatGroupsSorted = this.state.chatGroups;

        var chatGroupsMapped = chatGroupsSorted.map(x =>

            <Form.Group className=" mb-0 text-left" >
                <FormControlLabel className="col-md-11 text-right label-mt my-auto p-0"
                    control={<Checkbox id="indicators-is-active" style={{ padding: "5px 9px!important" }} checked={this.state.selectedChatGroupsChecked.indexOf(x.id) !== -1}
                        onChange={this.handleSelectChatGroups} name={x.id} color="primary" />}
                    label={x.description} />
            </Form.Group>
        );
        return chatGroupsMapped;
    }

    handleShowRodalChatGroups = (showModal) => {
        var { selectedChatGroups, selectedChatGroupsName } = this.state;

        if (this.state.selectedOutletsChecked.length > 0 && this.state.chatGroups.length < 1) {
            this.getChatGroups(true);

            this.setState({ showRodalChatGroups: showModal });
        }
        else if (showModal === false) this.setState({ showRodalChatGroups: showModal, selectedChatGroupsChecked: selectedChatGroups, selectedChatGroupsCheckedName: selectedChatGroupsName });
        else this.setState({ showRodalChatGroups: showModal });
    }

    handleConfirmSelectedChatGroups = () => {
        var { selectedChatGroupsChecked, selectedChatGroupsCheckedName } = this.state;
        this.setState({ showRodalChatGroups: false, selectedChatGroups: selectedChatGroupsChecked, selectedChatGroupsName: selectedChatGroupsCheckedName });
    }

    //DossierStacks
    getDossierStacksCheckedNames = (selectedDossierStacksChecked, dossierStacks) => {
        var selectedDossierStacksCheckedNameList = [];
        selectedDossierStacksChecked.forEach(id => {
            var dossierStacksCheckedNameDesc = dossierStacks.find(x => x.id === id)?.description;
            if (dossierStacksCheckedNameDesc && dossierStacksCheckedNameDesc.length > 0) {
                   selectedDossierStacksCheckedNameList.push(dossierStacksCheckedNameDesc);
            }
        });
        return selectedDossierStacksCheckedNameList;
    }

    handleSelectDossierStacks = e => {
        var { selectedDossierStacksChecked, selectedDossierStacksCheckedName, dossierStacks } = this.state;

        var selectedDossierStackId = parseInt(e.target.name);
        var dossierStackDesc = dossierStacks.find(x => x.id === selectedDossierStackId)?.description;

        if (e.target.checked) {
            let selectedDossierStacksCheckedNameList = this.getDossierStacksCheckedNames(selectedDossierStacksChecked, dossierStacks);
            if (dossierStackDesc && dossierStackDesc.length > 0) {
              selectedDossierStacksCheckedNameList.push(dossierStackDesc);
            }
            selectedDossierStacksCheckedName = selectedDossierStacksCheckedNameList.join(", ");

            this.setState({ selectedDossierStacksChecked: [...this.state.selectedDossierStacksChecked, selectedDossierStackId], selectedDossierStacksCheckedName, dossierStacksWasUpdated: true });
        }
        else {
            var index = selectedDossierStacksChecked.indexOf(selectedDossierStackId);
            if (index !== -1) {
                selectedDossierStacksChecked = selectedDossierStacksChecked.filter((_, i) => i !== index);

                let selectedDossierStacksCheckedNameList = this.getDossierStacksCheckedNames(selectedDossierStacksChecked, dossierStacks);

                selectedDossierStacksCheckedName = selectedDossierStacksCheckedNameList.join(", ");
                this.setState({ selectedDossierStacksChecked, selectedDossierStacksCheckedName, dossierStacksWasUpdated: true });
            }
        }
    };

    getDossierStacks = () => {
        var selectedOutlets = this.state.selectedOutletsChecked;
        if (selectedOutlets.length < 1) return;
        var outlets = this.props.accessibleMoboOutlets;

        const url = "/api/mobopro/v1/dossierstacks?isSettings=true";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const dossierStacks = [];

                result.forEach(function (item) {

                    if (item.outletId === null || selectedOutlets.includes(item.outletId)) {
                        dossierStacks.push({
                            id: item.id,
                            description: item.description,
                            fullDescription: item.description + (item.outletId === null ? "" : " (" + outlets.find(x => x.id === item.outletId).description + ") "),
                        });
                    }
                });
                var dossierStacksSorted = dossierStacks.sort((a, b) => (a.fullDescription > b.fullDescription) ? 1 : -1);

                this.setState({
                    dossierStacks: dossierStacksSorted,
                    loadingDossierStacks: false,
                });
            });
    }

    renderDossierStacks = () => {
        var dossierStacksSorted = this.state.dossierStacks;

        var dossierStacksMapped = dossierStacksSorted.map(x =>

            <Form.Group className=" mb-0 text-left" >
                <FormControlLabel className="col-md-11 text-right label-mt my-auto p-0"
                    control={<Checkbox id="indicators-is-active" style={{ padding: "5px 9px!important" }} checked={this.state.selectedDossierStacksChecked.indexOf(x.id) !== -1}
                        onChange={this.handleSelectDossierStacks} name={x.id} color="primary" />}
                    label={x.fullDescription} />
            </Form.Group>
        );
        return dossierStacksMapped;
    }

    handleShowRodalDossierStacks = (showModal) => {
        var { selectedDossierStacks, selectedDossierStacksName } = this.state;

        if (this.state.selectedOutletsChecked.length > 0 && this.state.dossierStacks.length < 1) {
            this.getDossierStacks(true);
            this.setState({ showRodalDossierStacks: showModal });
        }
        else if (showModal === false) this.setState({ showRodalDossierStacks: showModal, selectedDossierStacksChecked: selectedDossierStacks, selectedDossierStacksCheckedName: selectedDossierStacksName });
        else this.setState({ showRodalDossierStacks: showModal });
    }

    handleConfirmSelectedDossierStacks = () => {
        var { selectedDossierStacksChecked, selectedDossierStacksCheckedName } = this.state;
        this.setState({ showRodalDossierStacks: false, selectedDossierStacks: selectedDossierStacksChecked, selectedDossierStacksName: selectedDossierStacksCheckedName, requestInProgress: !this.checkIfRequestIsValid(false) });
    };

    //New pin
    handleShowRodalNewPin = (showModal) => {
        this.setState({ showRodalNewPin: showModal });
        if (showModal === false) {
            this.setState({ pin: "" }, () => { this.checkIfRequestIsValid(false); });
        }
    }

    handleSaveNewPin = () => {
        let resources = this.props.resources;

        var filterPin = /^[0-9]{4}$/;
        var isPinValid = filterPin.test(this.state.pin);
        if (!isPinValid) {
            DisplayMessage(resources["InvalidPin"], "error");
            return;
        }

        var json = JSON.stringify({
            id: this.state.id,
            pin: this.state.pin,
        });

        fetch(`/api/mobopro/v1/pin `, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });

            }
            else {
                DisplayMessage(resources["NewPinWasSaved"], "success");
                this.setState({ showRodalNewPin: false });
            }
        });
    }

    //Reset password
    handleShowRodalResetPassword = (showModal) => {
        this.setState({ showRodalResetPassword: showModal });
    }

    handleSaveResetedPassword = () => {
        let resources = this.props.resources;

        fetch(`/api/mobopro/v1/passwordreset/${this.state.id}`, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        }).then(response => {

            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });

            }
            else {
                DisplayMessage(resources["NewPasswordWasSentByEmail"], "success");
                this.setState({ showRodalResetPassword: false });
            }
        });
    }

    //New password
    handleShowRodalNewPassword = (showModal) => {
        this.setState({ showRodalNewPassword: showModal });
        if (showModal === false) {
            this.setState({ password: "" }, () => { this.checkIfRequestIsValid(false); });
        }
    }

    handleSaveNewPassword = () => {
        let resources = this.props.resources;

        if (!this.checkIfPasswordIsValid()) {
            DisplayMessage(resources["InvalidPassword"], "error");
            return;
        }

        var json = JSON.stringify({
            password: this.state.password
        });

        fetch(`/api/mobopro/v1/updatepassword/${this.state.id}`, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["NewPasswordWasSaved"], "success");
                this.setState({ showRodalNewPassword: false });
            }
        });
    }

    // render created by
    renderCreatedByInfo = () => {
        let resources = this.props.resources;
        if (this.state.id > 0) {
            return (<Form.Group className="d-flex mt-4">
                <div className="w-100 text-center">
                    <span>
                        {`${resources["CreatedOn"]} ${moment(this.state.createdOn).format('DD/MM/YYYY').toLocaleString()} ${resources["By"]} ${this.state.createdByName}`}
                    </span>
                </div>
            </Form.Group>);
        }
        return;
    }

    //dropzone
    handleFileChange = (files) => {
        var firstfile = files.length > 0 ? files[0] : undefined;

        if (firstfile === undefined) return;
        this.setState({
            file: firstfile
        });
    }

    removeProfileImage = () => {
        this.setState({ profileImageUri: "", file: undefined });
    }

    renderProfileImage = (displayImage) => {
        var resources = this.props.resources;

        if (this.state.profileImageUri !== null && this.state.profileImageUri !== "")
            return (<div className="profile">
                <img alt='profile' src={this.state.profileImageUri} className="profile-image" height={100} />
                <button className="delete-image" type="button" onClick={this.removeProfileImage}>
                    <i className="fas fa-trash-alt"></i>
                    <span className="MuiTouchRipple-root-2476">
                    </span></button>
            </div>)
        else {
            return (
                <ThemeProvider theme={SelectTheme}>
                    <DropzoneArea className="col-4"
                        acceptedFiles={['image/*']}
                        filesLimit={1}
                        showAlerts={false}
                        showPreviews={true}
                        dropzoneClass={displayImage}
                        dropzoneParagraphClass={"dropzone-p"}
                        previewText={""}
                        maxFileSize={10000000}
                        dropzoneText={resources["ProfileImage"]}
                        onChange={this.handleFileChange}
                        clearOnUnmount={false}
                        key={1}
                        showFileNames={true}
                    />
                </ThemeProvider>)
        }
    }

    renderWorkPreparationRoles = () => {

        const { workPreparationRoles } = this.state;
        if (workPreparationRoles === undefined) return;

        var wpRoles = workPreparationRoles.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return wpRoles;

    }

    renderForm() {
        const handleSubmit = (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.saveOutletUser();
        };
        const { mainOutlet, email, firstName, lastName, language,
            initials, gender, phone, internalPhoneNumber, internalDialogName, pin, file, workPreparationRoleId, dmsReference, dmsReference2, clockReference, planningReference, loadingChatgroups, loadingDossierStacks,
            loadingPermissions, loading, loadingRoles, password } = this.state;

        var resources = this.props.resources;
        var helperTextEmail = !this.checkIfEmailIsValid(false) ? "helper-text-red" : "helper-text-grey";
        var helperTextFirstName = firstName.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
        var helperTextLastName = lastName.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
        var disableSavePin = !this.checkIfPinIsValid();
        var disableSavePassword = !this.checkIfPasswordIsValid();
        var helperTextPassword = disableSavePassword ? resources["InvalidPassword"] : "";
        var helperTextPhone = !this.checkIfPhoneIsValid(phone, false) ? resources["InvalidPhone"] : '';
        var helperTextInternalPhoneNumber = !this.checkIfPhoneIsValid(internalPhoneNumber, false) ? resources["InvalidInternalPhoneNumber"] : '';
        var helperTextInternalDialogName = !this.checkIfInternalDialogNameIsValid(internalDialogName, false) ? resources["InvalidChatName"] : '';

        var selectedOutlets = this.state.selectedOutlets.length > 0 ? this.state.selectedOutletsName : resources["NoneSelectedYet"];
        var selectedRoles = this.state.selectedRoles.length > 0 ? this.state.selectedRolesName : resources["NoneSelectedYet"];
        var selectedPermissions = this.state.selectedPermissions.length > 0 ? this.state.selectedPermissionsName : resources["NoneSelectedYet"];
        var selectedChatGroups = this.state.selectedChatGroups.length > 0 ? this.state.selectedChatGroupsName : resources["NoneSelectedYet"];
        var selectedDossierStacks = this.state.selectedDossierStacks.length > 0 ? this.state.selectedDossierStacksName : resources["NoneSelectedYet"];
        var displayImage = file !== undefined ? "dropzone-hidden" : "dropzone";

        var displayChatGroups = "col-4 text-right float-right  " + (this.state.mainOutlet > 0 ? " pointer " : "  disabled-hover text-secondary");
        var displayDossierStacks = "col-4 text-right float-right  " + (this.state.mainOutlet > 0 ? " pointer " : "  disabled-hover text-secondary");
        return (
            <div>
                <Paper elevation={3} className="p-4 mt-3 mb-3">
                    <Form onSubmit={handleSubmit} >
                        <Form.Group className="pt-3 d-flex"  >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <div className="text-center col-6">
                                    <button className="btn btn-secondary" id="newPin" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                        type="button" onClick={this.handleShowRodalNewPin} >{resources["NewPin"]}</button>
                                    <button className="btn btn-secondary ml-2" id="resetPassword" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                        type="button" onClick={this.handleShowRodalResetPassword} >{resources["ResetPassword"]}</button>
                                    <button className='btn btn-secondary ml-2' id='newPassword' style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                        type='button' onClick={this.handleShowRodalNewPassword}> {resources["NewPassword"]} </button>
                                </div>
                            </ThemeProvider >
                        </Form.Group>

                        <Form.Group className="pt-3 d-flex"  >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextEmail} id="outletuser-email" value={email} label={resources["Email"]}
                                    helperText={resources["InputOfEmailIsMandatory"]} autoComplete="off"
                                    variant="outlined" name="email" onChange={this.handleChange} inputProps={{ maxLength: 200 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className=" d-flex"  >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextFirstName} id="outletuser-firstname" value={firstName} label={resources["FirstName"]}
                                    helperText={resources["InputOfFirstNameIsMandatory"]}
                                    variant="outlined" name="firstName" onChange={this.handleChangeName} inputProps={{ maxLength: 100 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex"  >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextLastName} id="outletuser-lastname" value={lastName} label={resources["LastName"]}
                                    helperText={resources["InputOfLastNameIsMandatory"]}
                                    variant="outlined" name="lastName" onChange={this.handleChangeName} inputProps={{ maxLength: 100 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex"  >
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-initials" value={initials} label={resources["Initials"]}
                                    variant="outlined" name="initials" onChange={this.handleChange} inputProps={{ maxLength: 10 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChange}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-type-native-simple">{resources["Language"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    id="outletusers-language"
                                    native
                                    label={resources["Language"]}
                                    value={language}
                                    inputProps={{
                                        name: 'language',
                                        id: 'outletusers-language',
                                    }}>
                                    <option value={'nl-be'} key={'nl-be'}>{"Nederlands (BE)"}</option>
                                    <option value={'nl-nl'} key={'nl-nl'}>{"Nederlands (NL)"}</option>
                                    <option value={'fr-be'} key={'fr-be'}>{"Français"}</option>
                                    <option value={'en-gb'} key={'en-gb'}>{"English"} </option>
                                    <option value={'de-de'} key={'de-de'}>{"Deutsch"}</option>
                                    <option value={'cs-cz'} key={'cs-cz'}>{"Czech"}</option>
                                </Select>
                            </FormControl>
                            <div className="col-3"></div>
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChange}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-type-native-simple">{resources["Gender"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    id="outletusers-gender"
                                    native
                                    label={resources["Gender"]}
                                    value={gender}
                                    inputProps={{
                                        name: 'gender',
                                        id: 'outletusers-gender',
                                    }}>
                                    <option value="m" key="m">{resources["Male"]}</option>
                                    <option value="f" key="f">{resources["Female"]}</option>
                                </Select>
                            </FormControl>
                            <div className="col-3"></div>
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-phone" value={phone} label={resources["Phone"]}
                                    helperText={helperTextPhone}
                                    variant="outlined" name="phone" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-internalphone" value={internalPhoneNumber} label={resources["InternalPhoneNumber"]}
                                    helperText={helperTextInternalPhoneNumber}
                                    variant="outlined" name="internalPhoneNumber" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-dms-reference" value={dmsReference} label={resources["DmsReference"]}
                                    variant="outlined" name="dmsReference" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-dms-reference-2" value={dmsReference2} label={resources["DmsReference2"]}
                                    variant="outlined" name="dmsReference2" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-clock-reference" value={clockReference} label={resources["ClockReference"]}
                                    variant="outlined" name="clockReference" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-planning-reference" value={planningReference} label={resources["PlanningReference"]}
                                    variant="outlined" name="planningReference" onChange={this.handleChange} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className="col-3 helper-text-red" id="outletuser-chatname" value={internalDialogName} label={resources["ChatName"]}
                                    helperText={helperTextInternalDialogName}
                                    variant="outlined" name="internalDialogName" onChange={this.handleChangeChatName} inputProps={{ maxLength: 20 }} />
                            </ThemeProvider >
                        </Form.Group>

                        {/* workPreparation */}
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChangeWorkPreparationRoles}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-type-native-simple">{resources["WorkPreparationRole"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    id="outletusers-workpreparation-role"
                                    native
                                    label={resources["WorkPreparationRole"]}
                                    value={workPreparationRoleId}
                                    inputProps={{
                                        name: 'workPreparation-role',
                                        id: 'outletusers-workPreparation-role-id',
                                    }}>
                                    <option value={0}>{resources["Select"]}</option>
                                    {this.renderWorkPreparationRoles()}
                                </Select>
                            </FormControl>
                            <div className="col-3"></div>
                        </Form.Group>

                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            {this.renderProfileImage(displayImage)}
                        </Form.Group>

                        <div >
                            <Form.Group className="d-flex"  >
                                <div className="col-3"></div>
                                <div className="col-6 ml-0 p-0 ">
                                    <hr className="MuiDivider-root mt-2 mb-2" />
                                    <ThemeProvider theme={SelectTheme}>
                                        <InputLabel className="col-6 text-left float-left"><b>{resources["Outlets"]}  <em className="text-danger">*</em></b></InputLabel>
                                    </ThemeProvider >
                                    <Link className="col-4 text-right float-right pointer" variant="body2" id="selectedOutlets" onClick={() => this.handleShowRodalOutlets(true)}> {resources["Select"]}   </Link>
                                    <FormHelperText style={{ clear: "both" }}>{selectedOutlets}</FormHelperText>
                                </div>
                            </Form.Group>
                            <Form.Group className="d-flex"  >
                                <div className="col-3"></div>
                                <div className="col-6 ml-0 p-0 ">
                                    <hr className="MuiDivider-root  mb-2" />
                                    <ThemeProvider theme={SelectTheme}>
                                        <InputLabel className="col-6 text-left float-left"><b>{resources["Roles"]}  <em className="text-danger">*</em></b></InputLabel>
                                    </ThemeProvider >
                                    <Link className="col-4 text-right float-right pointer" variant="body2" id="selectedRoles" onClick={() => this.handleShowRodalRoles(true)}>  {resources["Select"]}   </Link>
                                    <FormHelperText style={{ clear: "both" }}>{selectedRoles}</FormHelperText>
                                </div>
                            </Form.Group>
                            <Form.Group className="d-flex"  >
                                <div className="col-3"></div>
                                <div className="col-6 ml-0 p-0  ">
                                    <hr className="MuiDivider-root  mb-2" />
                                    <ThemeProvider theme={SelectTheme}>
                                        <InputLabel className="col-6 text-left float-left "><b>{resources["Permissions"]}</b>  </InputLabel>
                                    </ThemeProvider >
                                    <Link className="col-4 text-right float-right pointer" variant="body2" id="selectedPermissions" onClick={() => this.handleShowRodalPermissions(true)}> {resources["Select"]}   </Link>
                                    <FormHelperText style={{ clear: "both" }}>{selectedPermissions}</FormHelperText>
                                </div>
                            </Form.Group>
                            <Form.Group className="d-flex"  >
                                <div className="col-3"></div>
                                <div className="col-6 ml-0 p-0  ">
                                    <hr className="MuiDivider-root mb-2" />
                                    <ThemeProvider theme={SelectTheme}>
                                        <InputLabel className="col-6 text-left float-left"><b>{resources["ChatGroups"]}</b>  </InputLabel>
                                    </ThemeProvider >
                                    <Link className={displayChatGroups} variant="body2" id="selectedChatGroups" onClick={this.state.mainOutlet > 0 ? () => this.handleShowRodalChatGroups(true) : () => { return true }}> {resources["Select"]}   </Link>
                                    <FormHelperText style={{ clear: "both" }}>{selectedChatGroups}</FormHelperText>
                                </div>
                            </Form.Group>
                            <Form.Group className="d-flex"  >
                                <div className="col-3"></div>
                                <div className="col-6 ml-0 p-0  ">
                                    <hr className="MuiDivider-root mb-2" />
                                    <ThemeProvider theme={SelectTheme}>
                                        <InputLabel className="col-6 text-left float-left"><b>{resources["DossierStacks"]}</b>  </InputLabel>
                                    </ThemeProvider >
                                    <Link className={displayDossierStacks} variant="body2" id="selectedDossierStacks" onClick={this.state.mainOutlet > 0 ? () => this.handleShowRodalDossierStacks(true) : () => { return true }}> {resources["Select"]}   </Link>
                                    <FormHelperText style={{ clear: "both" }}>{selectedDossierStacks}</FormHelperText>
                                </div>
                            </Form.Group>
                        </div>

                        {this.renderCreatedByInfo()}

                    </Form>
                </Paper>

                {/* select Mobo Outlets for outletUser */}
                <Rodal visible={this.state.showRodalOutlets} onClose={() => this.handleShowRodalOutlets(false)} width={500} height={550}>
                    {(loading) ? (<div className="centered-loader"><CircularProgress /></div>) :
                        <div>
                            <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                                <InputLabel className="text-center"> {resources["Outlets"]}</InputLabel>
                            </div>
                            <div className="modal-body mb-0 py-2" style={{ height: "430px", maxHeight: "430px" }}>

                                <Form.Group className=" overflow-auto" style={{ maxHeight: "300px" }}  >
                                    {this.renderSelectOutlets()}
                                </Form.Group>

                                <hr className="MuiDivider-root mb-2 " />
                                <Form.Group className="pt-3 " style={{ minHeight: "75px" }} >
                                    <div className="col-3"></div>
                                    <div className="float-left col-9 text-left p-0 ml-0">
                                        <FormControl style={{ width: "310px" }} variant="outlined" >
                                            <InputLabel htmlFor="outlined-outlets">{resources["MainOutlet"]} </InputLabel>
                                            <Select
                                                label={resources["MainOutlet"]}
                                                native
                                                value={mainOutlet}
                                                name="mainOutlet"
                                                inputProps={{
                                                    name: 'mainOutlet',
                                                    id: 'outlined-outlets',
                                                }}
                                                onChange={this.handleChangeMainOutlet}>
                                                <option value={0}>{resources["DefaultDropdownValue"]}</option>
                                                {this.renderSelectMainOutlet()}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <FormHelperText className="color-red" style={{ display: `${this.state.mainOutlet < 1 ? "block" : 'none'}` }} >{resources["SelectionOfMainOutletIsMandatory"]}</FormHelperText>

                                </Form.Group>
                            </div>

                            <div className="modal-footer mt-0 pt-0"  >
                                <button className="btn btn-primary" id="btOkOutlets" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                                    onClick={() => this.handleConfirmSelectedOutlets()} disabled={this.state.selectedOutletsChecked.length < 1}>{resources["Ok"]}</button>

                            </div>
                            <span className="rodal-close"></span>

                        </div>}
                </Rodal>

                {/* select Roles  */}
                <Rodal visible={this.state.showRodalRoles} onClose={() => this.handleShowRodalRoles(false)} width={500} height={550}>
                    {(loadingRoles) ? (<div className="centered-loader"><CircularProgress /></div>) :
                        <div>
                            <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                                <InputLabel className="text-center"> {resources["Roles"]}</InputLabel>
                            </div>
                            <div className="modal-body mb-0 py-2" >

                                <Form.Group className=" overflow-auto" style={{ height: "400px", maxHeight: "400px" }}  >
                                    {this.renderSelectRoles()}
                                </Form.Group>

                            </div>

                            <div className="modal-footer mt-0 pt-0"  >
                                <button className="btn btn-primary" id="btnOkRoles" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                                    onClick={() => this.handleConfirmSelectedRoles()} disabled={this.state.selectedRolesChecked.length < 1}>{resources["Ok"]}</button>

                            </div>
                            <span className="rodal-close"></span>
                        </div>}
                </Rodal>

                {/* select Permissions */}
                <Rodal visible={this.state.showRodalPermissions} onClose={() => this.handleShowRodalPermissions(false)} width={500} height={550}>
                    {(loadingPermissions) ? (<div className="centered-loader"><CircularProgress /></div>) :
                        <div>
                            <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                                <InputLabel className="text-center"> {resources["Permissions"]}</InputLabel>
                            </div>
                            <div className="modal-body mb-0 py-2" >

                                <Form.Group className=" overflow-auto" style={{ height: "400px", maxHeight: "400px" }}  >
                                    {this.renderPermissions()}
                                </Form.Group>

                            </div>

                            <div className="modal-footer mt-0 pt-0"  >
                                <button className="btn btn-primary" id="btnOkPermissions" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                                    onClick={() => this.handleConfirmSelectedPermissions()} >{resources["Ok"]}</button>

                            </div>
                            <span className="rodal-close"></span>
                        </div>}
                </Rodal>

                {/* select ChatGroups */}

                <Rodal visible={this.state.showRodalChatGroups} onClose={() => this.handleShowRodalChatGroups(false)} width={500} height={550}>
                    {(loadingChatgroups) ? (<div className="centered-loader"><CircularProgress /></div>) :
                        <div>

                            <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                                <InputLabel className="text-center"> {resources["ChatGroups"]}</InputLabel>
                            </div>
                            <div className="modal-body mb-0 py-2" >


                                <Form.Group className=" overflow-auto" style={{ height: "400px", maxHeight: "400px" }}  >
                                    {this.renderChatGroups()}
                                </Form.Group>

                            </div>

                            <div className="modal-footer mt-0 pt-0"  >
                                <button className="btn btn-primary" id="btnOkChatGroups" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                                    onClick={() => this.handleConfirmSelectedChatGroups()} >{resources["Ok"]}</button>

                            </div>
                            <span className="rodal-close"></span>
                        </div>}
                </Rodal>

                {/* select DossierStacks */}
                <Rodal visible={this.state.showRodalDossierStacks} onClose={() => this.handleShowRodalDossierStacks(false)} width={500} height={550}>
                    {(loadingDossierStacks) ? (<div className="centered-loader"><CircularProgress /></div>) :
                        <div>
                            <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                                <InputLabel className="text-center"> {resources["DossierStacks"]}</InputLabel>
                            </div>
                            <div className="modal-body mb-0 py-2" >
                                <Form.Group className=" overflow-auto" style={{ height: "400px", maxHeight: "400px" }}  >
                                    {this.renderDossierStacks()}
                                </Form.Group>
                            </div>
                            <div className="modal-footer mt-0 pt-0"  >
                                <button className="btn btn-primary" id="btnOkDossierStacks" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                                    onClick={() => this.handleConfirmSelectedDossierStacks()} >{resources["Ok"]}</button>
                            </div>
                            <span className="rodal-close"></span>
                        </div>}
                </Rodal>


                {/* New Pin */}
                <Rodal visible={this.state.showRodalNewPin} onClose={() => this.handleShowRodalNewPin(false)} >

                    <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                        <InputLabel className="text-center"> {resources["NewPin"]}</InputLabel>
                    </div>
                    <div className="modal-body mt-2 pb-2" >

                        <Form.Group className=" overflow-auto"   >
                            <Form.Group className="mt-2 d-flex">
                                <div className="col-4 mt-2 ">
                                    <InputLabel className="text-center"> {resources["Pin"]}</InputLabel>
                                </div>
                                <ThemeProvider theme={SelectTheme}>
                                    <TextField className="col-4 helper-text-red" id="outletuser-pin" value={pin} label={resources["Pin"]}
                                        onKeyDown={ExcludeSpecialCharactersFromInputTypeNumber(true)}
                                        variant="outlined" name="pin" onChange={this.handleChange} type="number" inputProps={{ min: 1000, max: 9999 }} />
                                </ThemeProvider >
                            </Form.Group>
                        </Form.Group>

                    </div>

                    <div className="modal-footer mt-0 pt-0"  >
                        <button className="btn btn-secondary" id="btnCancel" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                            onClick={() => this.handleShowRodalNewPin(false)} >{resources["Cancel"]}</button>
                        <button className="btn btn-primary" id="btnSaveNewPin" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                            onClick={() => this.handleSaveNewPin()} disabled={disableSavePin}>{resources["Save"]}</button>
                    </div>
                    <span className="rodal-close"></span>
                </Rodal>

                {/* reset password */}
                <Rodal visible={this.state.showRodalResetPassword} onClose={() => this.handleShowRodalResetPassword(false)} className="rodal" >

                    <div className="modal-header text-center" style={{ borderBottom: "1px solid white" }}></div>
                    <div className="modal-body" style={{ margin: "20px" }}>{resources["AreYouSureYouWantToResetPassword"]}</div>
                    <div className="modal-footer" style={{ borderTop: "1px solid white" }}>
                        <button className="btn btn-secondary" id="btnCancel" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                            onClick={() => this.handleShowRodalResetPassword(false)} >{resources["Cancel"]}</button>
                        <button className="btn btn-primary" id="btnResetPassword" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                            onClick={this.handleSaveResetedPassword}>{resources["Yes"]}</button>
                    </div>
                    <span className="rodal-close"></span>
                </Rodal>

                {/* new password */}
                <Rodal visible={this.state.showRodalNewPassword} onClose={() => this.handleShowRodalNewPassword(false)} className='rodal'>
                    <div className="modal-header text-center mb-0 pb-0" style={{ borderBottom: "1px solid white" }}>
                        <InputLabel className="text-center"> {resources["NewPassword"]}</InputLabel>
                    </div>

                    <div className="modal-body mt-2 pb-2 " style={{ padding: '0px' }} >
                        <Form.Group className="mt-4 d-flex" style={{ height: '60px' }}>
                            <ThemeProvider theme={SelectTheme}>
                                <div className="col-4 mt-2 ">
                                    <InputLabel className="text-center"> {resources["NewPassword"]}</InputLabel>
                                </div>
                                <TextField className={"col-5 ml-2 helper-text-red"} id="outletuser-password" value={password} label={resources["Password"]}
                                    variant="outlined" name="password" onChange={this.handleChange} inputProps={{ maxLength: 14 }}
                                    type='password' helperText={helperTextPassword} />
                                <span style={{ marginLeft: '5px', marginTop: '5px', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}><Tooltip title={resources["PasswordValidationRules"]}>
                                    <InfoIcon />
                                </Tooltip></span>
                            </ThemeProvider >
                        </Form.Group>
                    </div>

                    <div className="modal-footer" style={{ padding: '0px', display: 'flex', justifyContent: 'flex-end', width: '100%' }}  >
                        <button className="btn btn-secondary" id="btnCancel" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                            onClick={() => this.handleShowRodalNewPassword(false)} >{resources["Cancel"]}</button>
                        <button className="btn btn-primary" id="btnSaveNewPassword" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA", minWidth: "100px" }}
                            onClick={() => this.handleSaveNewPassword()} disabled={disableSavePassword}>{resources["Save"]}</button>
                    </div>
                    <span className="rodal-close"></span>
                </Rodal>
            </div >
        );
    }

    renderMain() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {this.renderForm()}
        </div>;
    }

    render() {
        const { redirect, open, outletUserWasCreated, outletId, requestInProgress } = this.state;
        const { resources } = this.props;

        return (
            <div>
                {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: outletUserWasCreated, outletId } }} delay={250} />}
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky ">
                        <Toolbar>
                            <IconButton id="close-add-outletuser" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["EditOutletUserTitle"]}
                            </Typography>
                            <Button id="outletuser-save" color="inherit" disabled={requestInProgress} onClick={this.saveOutletUser}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        {this.renderMain()}
                    </div>
                </Dialog>
            </div >
        );
    }
}