import { HttpMethods } from "../utils/constants";

export function GetHeaders(){
    return new Headers({
        'PublicKey': localStorage.getItem('publicKey'),
        'Authorization-token': localStorage.getItem('authorizationToken'),
      })
}

export function GetUpdateHeaders(){
  return new Headers({
    'PublicKey': localStorage.getItem('publicKey'),
    'Authorization-token': localStorage.getItem('authorizationToken'),
    'Content-Type': 'application/json'
  })
}

export function UpdateRequest(url, data, method) {
  return fetch(url, {
    method: method,
    headers: GetUpdateHeaders(),
    body: data
  })
}

export function GetRequest(url) {
  return fetch(url, {
      method: HttpMethods.Get,
      headers: GetHeaders(),
    })
}

export function DeleteRequest(url) {
  return fetch(url, {
    method: HttpMethods.Delete,
    headers: GetHeaders(),
  })
}