import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.min.css'
import toastr from 'toastr';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core/styles';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/authConfig";
import { theme } from './theme/theme';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const msalInstance = new PublicClientApplication(msalConfig);

//Interceptor for fetch
const { fetch: origFetch } = window;
window.fetch = async (...args) => {
    const response = await origFetch(...args);
    if (response.headers.get("content-type") && response.headers.get("content-type").includes("application/json")) {
        response
            .clone()
            .json()
            .then(body => {
                if (response.status === 401) {
                    for (var i = 0; i < body.errors.length; i++) {
                        toastr.error(body.errors[i]);
                    }

                    localStorage.removeItem("publicKey");
                    localStorage.removeItem("authorizationToken");

                    if (document.location.pathname !== "/") {
                        document.location.href = "/";
                    }
                }
            })
            .catch(err => console.error(err));
    }

    return response;
};


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename={baseUrl}>
            <ThemeProvider theme={theme}>
                <MsalProvider instance={msalInstance}>
                    <App />
                </MsalProvider>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>,
    rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
