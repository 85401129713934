import React from "react";
import { Avatar, Box, Typography } from "@material-ui/core";
import moment from "moment";
import { DefaultAvatar } from "./../../../constants/medias";

const ModifiedByInfo = ({
  resources,
  modifiedByAvatar,
  modifiedOn,
  modifiedByName,
}) => {
  return (
    <Box display="flex" alignItems="center" className="mb-3" justifyContent="center">
        <Avatar
          alt="user-avatar"
          src={modifiedByAvatar ? modifiedByAvatar : DefaultAvatar}
          style={{
            marginRight: "8px",
            width: "35px",
            height: "35px",
          }}
        />
        <Typography variant="body2">
          {`${resources["ModifiedOn"]} ${moment(modifiedOn).format(
            "DD/MM/YYYY HH:mm"
          )} ${resources["By"]} ${modifiedByName}`}
        </Typography>
    </Box>
  );
};

export default ModifiedByInfo;
