import { Box, Typography } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import React, { useState } from "react";
import { CancelButton, OkButton } from "../../../styles/button/styles.js";
import { CustomModal } from "./../../../styles/modal/styles";
import { ModalContent } from "./../../../styles/box/styles";

const DeleteConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  resources,
  areYouSureMessage,
}) => {
  const [disabled, setDisabled] = useState(false);

  const confirmDelete = async () => {
    setDisabled(true);
    await onConfirm();
    setDisabled(false);
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: "#0000004D" } }}
    >
      <Zoom in={open} timeout={350}>
        <ModalContent
          className="bounce-in"
          style={{
            transform: open ? "scale(1)" : "scale(0.3)",
          }}
        >
          <Box mt={2.5} padding={2.5} minHeight={118} alignContent={"center"}>
            <Typography align="center">{areYouSureMessage}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" padding={1.5}>
            <CancelButton id="btnCancelDelete" onClick={onClose}>
              {resources["Cancel"]}
            </CancelButton>
            <OkButton
              id="btnDelete"
              onClick={confirmDelete}
              disabled={disabled}
            >
              {resources["Yes"]}
            </OkButton>
          </Box>
          <span className="rodal-close" onClick={onClose}></span>
        </ModalContent>
      </Zoom>
    </CustomModal>
  );
};

export default DeleteConfirmationModal;
