import React from 'react';
import { Route, Switch } from 'react-router';
import ChatMessageTriggerCreation from '../components/Triggers/ChatMessages/ChatMessageTriggerCreation';
import ChatMessageTriggerEditing from '../components/Triggers/ChatMessages/ChatMessageTriggerEditing';

export default function ChatMessageTriggersRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <Switch>
                <Route exact path='/chatmessagetriggers/new' render={(props) => (<ChatMessageTriggerCreation outletId={props && props.location && props.location.state && props.location.state.outletId} resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)} />
                <Route exact path='/chatmessagetriggers/:triggerId' render={(props) => {
                    return (<ChatMessageTriggerEditing resources={resources} mobilitySolutions={props && props.location && props.location.state && props.location.state.mobilitySolutions}
                        outletId={props.match.params.outletId} triggerId={props.match.params.triggerId} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)
                }} />
            </Switch>
        </>
    )
}