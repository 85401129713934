import { TextField, Box, FormHelperText } from "@material-ui/core";
import React from "react";

const TextFieldMandatoryComponent = ({
  value = "",
  handleChange,
  labelText,
  id,
  maxLength,
  helperText,
}) => {
  return (
    <Box display={"flex"} marginBottom={2} flexDirection={"column"}>
      <TextField
        className="col-12"
        id={`assetGroup-${id}`}
        value={value}
        label={labelText}
        variant="outlined"
        name={id}
        onChange={handleChange}
        multiline
        inputProps={{ maxLength: maxLength }}
      />
      <FormHelperText error={!value.trim()}>{helperText}</FormHelperText>
    </Box>
  );
};

export default TextFieldMandatoryComponent;
