import React from 'react';
import { RestrictedRoute } from "../utils/RestrictedRoute";
import { Permissions } from "../utils/Permissions";
import DisclaimersTable from "../components/WorkshopPlanning/General/Disclaimers/DisclaimersTable";
import { Route, Switch } from 'react-router';
import DisclaimersCreation from '../components/WorkshopPlanning/General/Disclaimers/DisclaimersCreation';

export default function DisclaimersRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/disclaimers' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<DisclaimersTable resources={resources}
                    accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
                    outletId={props && props.location && props.location.state && props.location.state.outletId}
                    refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid}
                />)
            }} />
            <Switch>
                <Route exact path='/disclaimers/new' render={(props) => {
                    return (<DisclaimersCreation accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
                        resources={resources}
                        language={currentIdentity.culture.slice(0, 2)}
                        outletId={props && props.location && props.location.state && props.location.state.outletId}
                    />)
                }} />
            </Switch>
        </>
    )
}