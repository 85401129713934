import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import LinkIndicatorsTable from '../components/WorkshopPlanning/Planning/LinkIndicators/LinkIndicatorsTable';
import LinkIndicatorsCreation from '../components/WorkshopPlanning/Planning/LinkIndicators/LinkIndicatorsCreation.js';
import { Permissions } from '../utils/Permissions';

export default function LinkedIndicatorsRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
           <RestrictedRoute path='/linkedindicators' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => (<LinkIndicatorsTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} outletId={props && props.location && props.location.state && props.location.state.outletId} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)} />
            <Switch>
                <Route exact path='/linkedindicators/new' render={(props) => (<LinkIndicatorsCreation accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
                    resources={resources} outletId={props && props.location && props.location.state && props.location.state.outletId} />)} />
            </Switch>
        </>
    )
}