import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import TagsTable from '../components/WorkshopPlanning/General/Tags/TagsTable';
import TagCreation from '../components/WorkshopPlanning/General/Tags/TagCreation.js';
import { Permissions } from '../utils/Permissions';

export default function TagsRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/tags' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<TagsTable resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} tagWasCreated={props && props.location && props.location.state && props.location.state.tagWasCreated} outletId={props && props.location && props.location.state && props.location.state.outletId} type={props && props.location && props.location.state && props.location.state.type} />)
            }} />
            <Switch>
                <Route exact path='/tags/new' render={(props) => (<TagCreation resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} outletId={props && props.location && props.location.state && props.location.state.outletId} type={props && props.location && props.location.state && props.location.state.type} tagId={props && props.location && props.location.state && props.location.state.tagId} />)} />
                <Route exact path='/tags/:tagId' render={(props) => {
                    return (<TagCreation resources={resources} tagId={props.match.params.tagId} editedTag={props.match.params.editedTag} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}   {...props} />)
                }} />
            </Switch>
        </>
    )
}