import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage, ExcludeSpecialCharactersFromInputTypeNumber } from '../../../../services/helpers.js';
import DelayedRedirect from '../../../../utils/DelayedRedirect';
import { Transition } from '../../../../utils/ModalHelpers';

import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';

export default class BookingItemsCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            objectType: this.props.objectType !== undefined ? parseInt(this.props.objectType) : 0,
            outletId: this.props.outletId !== undefined ? parseInt(this.props.outletId) : 0,
            language: this.props.language !== undefined ? this.props.language : "nl",
            description: "",
            isVisibleInPlanning: false,
            shortDescription: "",
            validFrom: "",
            validTill: "",
            brandId: 0,
            operationCode: "",
            price: null,
            laborTime: null,
            infoURL: "",
            isMaintenance: false,
            isB2CVisible: false,
            isQuoteItem: false,
            isKeyDropVisible: false,
            brands: [],

            checkedForData: false,
            selectedOutletId: 0,
            loading: false,
            redirect: null,
            accessibleMoboOutlets: [],
            requestInProgress: false,
            open: true,
            bookingItemsWasCreated: false,
        };
    }

    componentDidMount() {
        if (this.props.accessibleMoboOutlets === undefined) return;
        if (this.props.outletId === undefined) {
            this.setState({ outletId: 0 });
        }

        this.getBrands();
    }

    handleClose = () => {
        this.setState({ open: false, redirect: "/bookingitems" });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value }, () => this.handleValidation());
    };

    handleChangeCheckBox = e => {
        this.setState({ [e.target.name]: e.target.checked }, () => this.handleValidation());
    };

    handleTyping = e => {
        this.setState({ requestInProgress: false });
    };

    handleValidation = () => {
        this.setState({ requestInProgress: !this.checkIfRequestIsValid(false) });
    }

    checkIfRequestIsValid(displayErrorMessage) {
        let resources = this.props.resources;
        var isValidRequest = true;

        if (this.state.shortDescription.trim().length <= 0) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidShotDescription"], "error");
            isValidRequest = false;
        }

        if (this.state.validFrom === "" || this.state.validFrom === undefined) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidDateFrom"], "error");
            isValidRequest = false;
        }
        if (this.state.validTill === "" || this.state.validTill === undefined) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidDateTill"], "error");
            isValidRequest = false;
        }

        if (displayErrorMessage && this.state.validFrom > this.state.validTill) {
            DisplayMessage(resources["InvalidDateTill"], "error");
            isValidRequest = false;
        }

        if (displayErrorMessage && this.state.laborTime !== null && this.state.laborTime < 0) {
            DisplayMessage(resources["InvalidLaborTime"], "error");
            isValidRequest = false;
        }

        if (this.state.price !== null && this.state.price !== undefined) {
            var filterPrice = /^\d{0,4}(\.\d{1,2})?$/;
            var isValidPrice = filterPrice.test(this.state.price);
            if (displayErrorMessage && !isValidPrice) DisplayMessage(resources["InvalidPrice"], "error");

            isValidRequest = isValidPrice;
        }

        return isValidRequest;
    }

    handleSubmit = () => {
        this.saveNewBookingItems();
    }

    saveNewBookingItems = () => {
        this.setState({ requestInProgress: true });
        let resources = this.props.resources;

        if (!this.checkIfRequestIsValid(true)) return;

        const { outletId, brandId, language, shortDescription, longDescription, validFrom, validTill, price, operationCode, laborTime, isMaintenance, isB2CVisible, isQuoteItem, infoURL, isKeyDropVisible } = this.state;

        var json = JSON.stringify({
            moboOutletId: outletId === 0 ? null : outletId,
            brandId: brandId === 0 ? null : brandId,
            language: language,
            shortDescription: shortDescription,
            longDescription: longDescription,
            validFrom: validFrom,
            validTill: validTill,
            price: price !== null ? parseFloat(price) : null,
            operationCode: operationCode,
            laborTime: laborTime !== null ? parseInt(laborTime) : null,
            isMaintenance: isMaintenance,
            isB2CVisible: isB2CVisible,
            isQuoteItem: isQuoteItem,
            isKeyDropVisible: isKeyDropVisible,
            infoURL: infoURL === "" ? null : infoURL
        });

        const url = `/api/mobopro/v1/bookingitems`;
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                response.json().then(result => {
                    if (!response.ok) {

                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                    }
                    else {

                        if (result.id !== undefined) {
                            this.setState({
                                updateInProgress: false,
                                bookingItemsWasCreated: true,
                                redirect: "/bookingitems"
                            });

                            DisplayMessage(resources["BookingItemsWasSaved"], "success");
                        }
                    }
                });
            });
    }

    handleChangeOutlet = e => {
        this.setState({ outletId: parseInt(e.target.value) });
    };

    renderOutlets = () => {
        if (this.props.accessibleMoboOutlets === undefined) return;
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)

        outlets = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outlets;
    }

    handleValidFrom = e => {
        this.setState({ validFrom: e.target.value, requestInProgress: false }, () => this.handleValidation());
    };

    handleValidTill = e => {
        this.setState({ validTill: e.target.value, requestInProgress: false }, () => this.handleValidation());
    };

    getBrands = () => {
        if (this.state.brands.length > 0) return;

        const url = `/api/mobopro/v1/brands?objectType=${this.state.objectType}`;
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const brands = [];

                result.forEach(function (item) {

                    brands.push({
                        id: item.id,
                        description: item.description,
                        objectType: item.objectType
                    });

                });
                var brandsSorted = brands.sort((a, b) => (a.description > b.description) ? 1 : -1);
                this.setState({
                    brands: brandsSorted,
                });
            });
    }

    renderBrands = () => {
        if (this.state.brands === undefined) return;
        var brands = this.state.brands.sort((a, b) => (a.description > b.description) ? 1 : -1)

        brands = brands.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return brands;
    }

    handleChangeBrand = e => {
        this.setState({ brandId: parseInt(e.target.value) });
    };

    handleChangeNumber = e => {
        if (e.target.value.toString().charAt(0) === '0' && e.target.value.toString().length > 1 && e.target.value.toString().charAt(1) !== ".") {
            this.setState({ [e.target.name]: e.target.value.toString().slice(1) }, () => this.handleValidation());
        }
        else {
            this.setState({ [e.target.name]: e.target.value }, () => this.handleValidation());
        }
    };

    renderForm() {
        const { validFrom, validTill, isMaintenance, isB2CVisible, isQuoteItem, brandId, operationCode, price, laborTime, outletId, language, shortDescription, longDescription, infoURL, isKeyDropVisible } = this.state;
        var resources = this.props.resources;

        var helperTextShortDescription = shortDescription.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
        var helperTexValidFrom = validFrom === "" ? "helper-text-red" : "helper-text-grey";
        var helperTextValidTill = validTill === "" ? "helper-text-red" : "helper-text-grey";
        return (
            <Paper elevation={3} className="p-4 mt-3 mb-3">
                <Form>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChangeOutlet}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="bookingitems-outlet"
                                native
                                label={resources["Outlet"]}
                                value={outletId}
                                inputProps={{
                                    name: 'outlet',
                                    id: 'outlined-outlet-native-simple',
                                }}>
                                <option key={0} value={0}>{resources["All"]}</option>
                                {this.renderOutlets()}
                            </Select>
                        </FormControl>
                        <div className="col-3"></div>
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChangeBrand}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-brand-native-simple">{resources["Brand"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="bookingitems-brand"
                                native
                                label={resources["Brand"]}
                                value={brandId}
                                inputProps={{
                                    name: 'brand',
                                    id: 'outlined-brand-native-simple',
                                }}>
                                <option key={0} value={0}>{resources["All"]}</option>
                                {this.renderBrands()}
                            </Select>
                        </FormControl>
                        <div className="col-3"></div>
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChange}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-type-native-simple">{resources["Language"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="bookingitems-language"
                                native
                                label={resources["Language"]}
                                value={language}
                                inputProps={{
                                    name: 'language',
                                    id: 'bookingitems-language',
                                }}>
                                <option value={'nl'} key={'nl'}>{"Nederlands"}</option>
                                <option value={'fr'} key={'fr'}>{"Français"}</option>
                                <option value={'en'} key={'en'}>{"English"} </option>
                                <option value={'de'} key={'de'}>{"Deutsch"}</option>
                                <option value={'cs'} key={'cs'}>{"Czech"}</option>
                            </Select>
                        </FormControl>
                        <div className="col-3"></div>
                    </Form.Group>
                    <Form.Group className=" d-flex" >
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className={"col-6 " + helperTextShortDescription} id="bookingitems-shortDescription"
                                value={shortDescription} label={resources["ShortDescription"]}
                                helperText={resources["InputOfShortDescriptionIsMandatory"]}
                                variant="outlined" name="shortDescription" onChange={this.handleChange} inputProps={{ maxLength: 100 }} />
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className="col-6 " id="bookingitems-longDescription"
                                value={longDescription} label={resources["LongDescription"]}
                                variant="outlined" name="longDescription" onChange={this.handleChange} inputProps={{ maxLength: 200 }} />
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <FormControl className={helperTexValidFrom}>
                            <TextField id="dateValidFrom" className="white-date " label={resources["ValidFrom"]}
                                helperText={resources["PleasePickADate"]}
                                name="validFrom" variant="outlined" type="date"
                                required onChange={this.handleValidFrom} value={validFrom} InputLabelProps={{ shrink: true, required: false }} inputProps={{ min: '2000-01-01' }} />
                        </FormControl>
                        <div className="col-1"></div>
                        <FormControl className={helperTextValidTill}>
                            <TextField id="dateValidTill" className="white-date" style={{ marginLeft: "45px" }} label={resources["ValidTill"]}
                                helperText={resources["PleasePickADate"]}
                                name="validTill" variant="outlined" type="date"
                                required onChange={this.handleValidTill} value={validTill} InputLabelProps={{ shrink: true }} inputProps={{ min: '2000-01-01' }} />
                        </FormControl>
                    </Form.Group>

                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className="col-6 helper-text-red" id="bookingitems-operationCode"
                                value={operationCode} label={resources["OperationCode"]}
                                variant="outlined" name="operationCode" onChange={this.handleChange} inputProps={{ maxLength: 25 }} />
                        </ThemeProvider >
                    </Form.Group>

                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className="col-6 helper-text-red" id="bookingitems-price"
                                value={price} label={resources["Price"]}
                                variant="outlined" name="price" onChange={this.handleChangeNumber} type="number"
                                onKeyDown={ExcludeSpecialCharactersFromInputTypeNumber(false)}
                                inputProps={{
                                    maxLength: 6,
                                    step: "0.01",
                                }} />
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className="col-6 helper-text-red" id="bookingitems-laborTime"
                                value={laborTime} label={resources["LaborTime"]}
                                variant="outlined" name="laborTime" onChange={this.handleChangeNumber} type="number"
                                onKeyDown={ExcludeSpecialCharactersFromInputTypeNumber(true)}
                                InputProps={{ inputProps: { min: 0 } }} />
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group className="d-flex">
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className="col-6 helper-text-red" id="bookingitems-infoURL"
                                value={infoURL} label={resources["InfoURL"]}
                                variant="outlined" name="infoURL" onChange={this.handleChange}
                            />
                        </ThemeProvider >
                    </Form.Group>
                    <Form.Group style={{ display: "flex" }} >
                        <div className="col-md-3 "> </div>
                        <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                            control={<Checkbox id="bookingitems-isMaintenance" checked={isMaintenance} onChange={this.handleChangeCheckBox} name="isMaintenance" color="primary" />}
                            label={resources["IsMaintenance"]} />
                        <div className="col-md-4"> </div>
                    </Form.Group>
                    <Form.Group style={{ display: "flex" }} >
                        <div className="col-md-3 "> </div>
                        <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                            control={<Checkbox id="bookingitems-isB2CVisible" checked={isB2CVisible} onChange={this.handleChangeCheckBox} name="isB2CVisible" color="primary" />}
                            label={resources["IsB2CVisible"]} />
                        <div className="col-md-4"> </div>
                    </Form.Group>
                    <Form.Group style={{ display: "flex" }} >
                        <div className="col-md-3 "> </div>
                        <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                            control={<Checkbox id="bookingitems-isQuoteItem" checked={isQuoteItem} onChange={this.handleChangeCheckBox} name="isQuoteItem" color="primary" />}
                            label={resources["IsQuoteItem"]} />
                        <div className="col-md-4"> </div>
                    </Form.Group>
                    <Form.Group style={{ display: "flex" }} >
                        <div className="col-md-3 "> </div>
                        <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                            control={<Checkbox id="bookingitems-isKeyDropVisible" checked={isKeyDropVisible} onChange={this.handleChangeCheckBox} name="isKeyDropVisible" color="primary" />}
                            label={resources["IsKeyDropVisible"]} />
                        <div className="col-md-4"> </div>
                    </Form.Group>
                </Form>
            </Paper>
        );
    }

    renderMain() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {this.renderForm()}
        </div>;
    }

    render() {
        const { redirect, open, bookingItemsWasCreated, outletId, requestInProgress } = this.state;
        const { resources } = this.props;
        return (
            <div>
                {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: bookingItemsWasCreated, outletId } }} delay={250} />}
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton id="close-new-bookingitems" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {resources["NewBookingItems"]}
                            </Typography>
                            <Button id="bookingitems-save" color="inherit" disabled={requestInProgress} onClick={this.handleSubmit}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        {this.renderMain()}
                    </div>
                </Dialog>
            </div >
        );
    }
}