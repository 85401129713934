import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { TextField, Button, Slide, Dialog, AppBar, IconButton, Typography, Toolbar } from '@material-ui/core';
import TeamMembersTable from '../TeamMembers/TeamMembersTable';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'toastr/build/toastr.min.css';
import { DisplayMessage, GetFormattedDate } from '../../../../services/helpers.js';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Redirect } from 'react-router-dom';
import defaultavatar from '../../../../defaultavatar.png';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class TeamNew extends Component {

    constructor(props) {
        super(props);

        var editedTeamId = undefined;
        if (this.props !== undefined) {
            editedTeamId = this.props.teamId;
        }

        this.state = {
            id: 0,
            outletId: 0,
            outletName: "",
            dmsIndicator: "",
            hasWorkPreparation: false,
            description: "",
            activeFrom: "",
            activeTill: "",
            parentTeamId: 0,
            createdOn: "",
            createdByName: "",
            createdByAvatar: "",
            selectedOutletId: 0,
            loading: false,
            redirect: false,
            accessibleMoboOutlets: [],
            parentTeams: [],
            teams: this.props.teams !== undefined ? this.props.teams : [],
            teamMembers: [],
            editedTeamId: editedTeamId,
            requestInProgress: false,
            type: 0,

            openEditPopup: true,
            showEditTeam: false,
            redirect: null,
            teamWasCreated: false,
            teamWasUpdated: false,
        };
    }

    componentWillMount() {
        if (this.state.editedTeamId !== undefined) {
            this.getTeamById(this.state.editedTeamId);
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value, requestInProgress: false });
    };

    handleChangeOutlet = e => {
        this.getParentTeams(e.target.value);
        this.setState({ outletId: e.target.value, requestInProgress: false });
    };

    handleChangeCheckBox = e => {
        this.setState({ [e.target.name]: e.target.checked});
    };

    handleSubmit = () => {
        if (this.state.id === 0) {
            this.saveNewTeam();
        }
        else {
            this.saveEditedTeam();
        }
    }

    getTeamById = (teamId) => {
        const url = "/api/mobopro/v1/planningteams/" + teamId;
        fetch(url, {
            method: 'GET',

            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
        }).then(response => response.json()).then(result => {
            if (result.id !== undefined) {
                this.setState({
                    id: result.id,
                    outletId: result.outletId,
                    outletName: result.outletName,
                    dmsIndicator: result.dmsIndicator != null ? result.dmsIndicator : "",
                    hasWorkPreparation: result.hasWorkPreparation,
                    description: result.description,
                    type: result.type,
                    loading: false,
                    activeFrom: GetFormattedDate(result.activeFrom),
                    activeTill: GetFormattedDate(result.activeTill),
                    parentTeamId: result.parentTeam !== null ? result.parentTeam.id : 0,
                    createdOn: result.createdOn,
                    createdByName: result.createdByName,
                    createdByAvatar: result.createdByAvatar,
                    teamMembers: result.members
                }, () => this.getParentTeams(result.outletId.toString()));

            }
        });
    }

    saveNewTeam = () => {
        let resources = this.props.resources;
        this.setState({ requestInProgress: true });

        let activeFrom = GetFormattedDate(this.state.activeFrom === "" ? undefined : this.state.activeFrom);
        let activeTill = GetFormattedDate(this.state.activeTill === "" ? undefined : this.state.activeTill);
        if (this.state.description.trim().length <= 0) {
            DisplayMessage(resources["InvalidDescripton"], "error");
            return;
        }

        if (activeFrom >= activeTill) {
            DisplayMessage(resources["InvalidEndDate"], "error");
            return;
        }

        if (this.state.dmsIndicator.length > 0 && this.state.dmsIndicator.trim().length <= 0) {
            DisplayMessage(resources["InvalidDmsIndicator"], "error");
            return;
        }

        var json = JSON.stringify({
            outletId: this.state.outletId,
            description: this.state.description !== "" ? this.state.description : null,
            type: this.state.type,
            id: this.state.id,
            dmsIndicator: this.state.dmsIndicator !== "" ? this.state.dmsIndicator : null,
            hasWorkPreparation: this.state.hasWorkPreparation,

            activeFrom: activeFrom,
            activeTill: activeTill,
            parentTeamId: this.state.parentTeamId > 0 ? this.state.parentTeamId : null,
        });

        const url = "/api/mobopro/v1/planningteams";
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            response.json().then(result => {
                if (!response.ok) {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                    this.setState({ requestInProgress: false });
                }
                else {
                    if (result.id !== undefined) {
                        this.setState({
                            id: result.id,
                            outletName: result.outletName,
                            dmsIndicator: result.dmsIndicator,
                            hasWorkPreparation: result.hasWorkPreparation,
                            description: result.description,
                            type: result.type,

                            activeFromsOn: GetFormattedDate(result.activeFrom),
                            activeTill: GetFormattedDate(result.activeTill),
                            parentTeamId: result.parentTeam !== null ? result.parentTeam.id : 0,
                            createdOn: result.createdOn,
                            createdByName: result.createdByName,
                            createdByAvatar: result.createdByAvatar,
                            teamMembers: result.members,
                            editedTeamId: result.id
                        });

                        DisplayMessage(resources["NewTeamWasSaved"], "success");
                        this.setState({ requestInProgress: false, teamWasCreated: true, editedTeamId: result.id });
                    }
                }
            })
        });
    }

    saveEditedTeam = () => {
        let resources = this.props.resources;
        this.setState({ requestInProgress: true });
        let activeFrom = GetFormattedDate(this.state.activeFrom);
        let activeTill = GetFormattedDate(this.state.activeTill);

        if (this.state.description.trim().length <= 0) {
            DisplayMessage(resources["InvalidDescripton"], "error");
            return;
        }
        if (activeFrom >= activeTill) {
            DisplayMessage(resources["InvalidEndDate"], "error");
            return;
        }

        if (this.state.dmsIndicator.length > 0 && this.state.dmsIndicator.trim().length <= 0) {
            DisplayMessage(resources["InvalidDmsIndicator"], "error");
            return;
        }

        var json = JSON.stringify({
            outletId: this.state.outletId,
            description: this.state.description,
            type: this.state.type,
            id: this.state.id,
            dmsIndicator: this.state.dmsIndicator !== "" ? this.state.dmsIndicator : null,
            hasWorkPreparation: this.state.hasWorkPreparation,
            activeTill: activeTill,
            activeFrom: activeFrom,
            parentTeamId: this.state.parentTeamId > 0 ? this.state.parentTeamId : null,
        });

        const url = "/api/mobopro/v1/planningteams/" + this.state.id;
        fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        return response.json();
                    }
                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
                this.setState({ requestInProgress: false });
                return response.json();
            })
            .then(result => {
                if (result.id !== undefined) {
                    this.setState({
                        id: result.id,
                        outletName: result.outletName,
                        dmsIndicator: result.dmsIndicator != null ? result.dmsIndicator : "",
                        hasWorkPreparation: result.hasWorkPreparation,
                        description: result.description,
                        type: result.type,

                        startactiveFromsOn: GetFormattedDate(result.activeFrom),
                        activeTill: GetFormattedDate(result.activeTill),
                        parentTeamId: result.parentTeam !== null ? result.parentTeam.id : 0,
                        createdOn: result.createdOn,
                        createdByName: result.createdByName,
                        createdByAvatar: result.createdByAvatar,
                        teamWasUpdated : true
                    });

                    DisplayMessage(resources["TeamWasSaved"], "success");
                }
            });
    }

    handleActiveFrom = e => {
        this.setState({ activeFrom: e.target.value, requestInProgress: false });
    };

    handleActiveTill = e => {
        this.setState({ activeTill: e.target.value, requestInProgress: false });
    };

    getParentTeams = (outletIdFilter) => {
        if (this.state.teams !== [] && this.state.teams.length > 0) {
            const teams = [];
            const outletId = parseInt(outletIdFilter);

            for (let index = 0; index < this.state.teams.length; index++) {
                var item = this.state.teams[index];

                if (outletIdFilter === 0 || item.outletId === outletId) {
                    teams.push({
                        id: item.id,
                        description: item.description,
                        outletId: item.outletId
                    });
                }
            }

            var teamsSorted = teams.sort((a, b) => (a.description > b.description) ? 1 : -1);
            this.setState({
                parentTeams: teamsSorted,
            });

        }
        else {
            const url = "/api/mobopro/v1/planningteams/";
            fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                })
            })
                .then(response => response.json())
                .then(result => {
                    const teams = [];

                    result.forEach(function (item) {
                        if (outletIdFilter === 0 || item.outletId.toString() === outletIdFilter) {
                            teams.push({
                                id: item.id,
                                description: item.description,
                                outletId: item.outletId
                            });
                        }
                    });
                    var teamsSorted = teams.sort((a, b) => (a.description > b.description) ? 1 : -1);
                    this.setState({
                        parentTeams: teamsSorted,
                    });
                });
        }
    }

    renderOutlets = () => {
        var filteredOutlets = this.props.accessibleMoboOutlets.filter(o => o.hasWorkshopPlanning === true || o.hasWorkshopPlanningLight === true);
        var outlets = filteredOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (filteredOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    renderTeams = () => {
        return this.state.parentTeams.map(x => <option key={x.id} value={x.id.toString()}> {x.description}</option>);
    }

    renderCreatedByInfo = () => {
        let resources = this.props.resources;
        if (this.state.id > 0) {
            return (<Form.Group className="d-flex">
                <div className="w-100 text-center">
                    <span className=" mr-2">
                        <img className="rounded-circle" alt="user-avatar" src={(this.state.createdByAvatar == null || this.state.createdByAvatar == "") ? defaultavatar : this.state.createdByAvatar} width="35" />
                    </span>
                    <span>
                        {`${resources["CreatedOn"]} ${moment(this.state.createdOn).format('DD/MM/YYYY').toLocaleString()} ${resources["By"]} ${this.state.createdByName}`}
                    </span>
                </div>
            </Form.Group>);
        }
        return;
    }

    handleClose = () => {
      this.setState({ openEditPopup: false, redirect: "/teams" });
    }

    renderMembers = () => {
        if (this.state.id > 0) {
            return (
                <Paper elevation={3} className="p-4 mt-3 mb-3">
                    <Form.Group style={{ display: "flex" }}>
                        <div className="col-md-12" style={{ textAlign: "center" }} >
                            <TeamMembersTable
                                teamMembers={this.state.teamMembers}
                                key={this.state.teamMembers}
                                teamId={this.state.id}
                                editedTeamId={this.state.editedTeamId}
                                getTeamById={this.getTeamById}
                                resources={this.props.resources}
                            />
                        </div>
                    </Form.Group>
                </Paper>);
        }
        return;
    }

    formatDate(date) {
        return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
    }

    renderForm() {
        const { redirect, editedTeamId, teamWasCreated, teamWasUpdated, hasWorkPreparation  } = this.state;
        if (redirect) { return <Redirect to={{ pathname: this.state.redirect, state: { refreshGrid: teamWasCreated || teamWasUpdated, teamWasCreated } }} /> }

        var resources = this.props.resources;
        var isDisalbed = editedTeamId > 0;
        var helperTextActiveFrom = this.state.activeFrom === "" ? "helper-text-red" : "helper-text-grey";
        return (
            <div>
                <Paper elevation={3} className="p-4 mt-3 mb-3">
                    <Form.Group className="pt-3" style={{ display: "flex" }} >
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField required className={"col-6 " + (this.state.description.trim().length < 1 ? "helper-text-red" : "helper-text-grey")} id="teams-description"
                                value={this.state.description}
                                label={resources["Description"]}
                                helperText={resources["InputOfDescriptionIsMandatory"]}
                                variant="outlined" name="description"
                                onChange={this.handleChange} inputProps={{ maxLength: 100 }} InputLabelProps={{ required: false }} />
                        </ThemeProvider>
                        <div className="col-3"></div>
                    </Form.Group>

                    <Form.Group style={{ display: "flex" }}>
                        <div className="col-3"></div>
                        <FormControl disabled={isDisalbed} className="col-6" variant="outlined" onChange={this.handleChangeOutlet}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="teams-outlet">{resources["Outlet"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                native
                                label={resources["Outlet"]}
                                value={this.state.outletId}
                                inputProps={{
                                    name: 'outlet',
                                    id: 'teams-outlet',
                                }}>
                                <option value={0}>{resources["DefaultDropdownValue"]}</option>
                                {this.renderOutlets()}
                            </Select>
                            <FormHelperText className={this.state.outletId < 1 ? 'color-red' : 'color-grey'}>{resources["SelectionOfOutletIsMandatory"]}</FormHelperText>
                        </FormControl>
                        <div className="col-3"></div>
                    </Form.Group>
                    <Form.Group style={{ display: "flex" }}>
                        <div className="col-3"></div>
                        <FormControl className="col-6 helper-text-red" variant="outlined" onChange={this.handleChange}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-type-native-simple">{resources["Type"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="teams-type"
                                native
                                label={resources["Type"]}
                                value={this.state.type}
                                inputProps={{
                                    name: 'type',
                                    id: 'teams-type',
                                }}>
                                <option value={0}>{resources["DefaultDropdownValue"]}</option>
                                <option value={1}>{resources["TeamTypeWorkshop"]}</option>
                                <option value={2}>{resources["TeamTypeTyreChanges"]} </option>
                                <option value={3}>{resources["TeamTypePDI"]}</option>
                            </Select>
                            <FormHelperText className={this.state.type < 1 ? 'color-red' : 'color-grey'}>{resources["SelectionOfTypeIsMandatory"]}</FormHelperText>
                        </FormControl>
                        <div className="col-3"></div>
                    </Form.Group>
                    <Form.Group style={{ display: "flex" }}>
                        <div className="col-3"></div>
                        <FormControl className="col-6" variant="outlined" onChange={this.handleChange}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-parent-team-native-simple">{resources["ParentTeam"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                id="teams-parent-team"
                                native
                                label={resources["ParentTeam"]}
                                value={this.state.parentTeamId}
                                inputProps={{
                                    name: 'parentTeamId',
                                    id: 'teams-parent-team',
                                }}>
                                <option value={0}>{resources["DefaultDropdownValue"]}</option>
                                {this.renderTeams()}
                            </Select>
                            <FormHelperText>{resources["OptionallySetAParentTeam"]}</FormHelperText>
                        </FormControl>
                        <div className="col-3"></div>
                    </Form.Group>

                    <Form.Group style={{ display: "flex"}} >
                        <div className="col-3"></div>
                        <ThemeProvider theme={SelectTheme}>
                            <TextField className="col-6" id="teams-dms-indicator" value={this.state.dmsIndicator} label={resources["DmsIndicator"]} variant="outlined" name="dmsIndicator" onChange={this.handleChange} inputProps={{ maxLength: 25 }} />
                        </ThemeProvider>
                        <div className="col-3"></div>
                    </Form.Group>
                    <Form.Group style={{ display: "flex", marginBottom: "38.2px"  }} >
                        <div className="col-md-3 "> </div>
                        <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                            control={<Checkbox id="team-has-work-preparation" checked={hasWorkPreparation} onChange={this.handleChangeCheckBox} name="hasWorkPreparation" color="primary" />}
                            label={resources["HasWorkPreparation"]} />
                        <div className="col-md-4"> </div>
                    </Form.Group>

                    <Form.Group style={{ display: "flex", width: "100%", justifyContent: "center" }}    >
                        <FormControl className={helperTextActiveFrom}>
                            <TextField id="dateActiveFrom" className="white-date mr-4" label={resources["ActiveFrom"]}
                                helperText={resources["PleasePickADate"]}
                                name="activeFrom" variant="outlined" type="date"
                                required onChange={this.handleActiveFrom} value={this.state.activeFrom} InputLabelProps={{ shrink: true, required: false }} />
                        </FormControl>
                        <FormControl>
                            <TextField id="dateActiveTill" className="white-date" label={resources["ActiveTill"]} name="activeTill" variant="outlined" type="date"
                                onChange={this.handleActiveTill} value={this.state.activeTill} InputLabelProps={{ shrink: true, }} />
                        </FormControl>
                    </Form.Group>

                    {this.renderCreatedByInfo()}
                </Paper>
                {this.renderMembers()}
            </div>
        );
    }

    render() {
        const { redirect, requestInProgress, openEditPopup, editedTeamId, teamWasCreated,teamWasUpdated} = this.state;
        if (redirect) { return <Redirect to={{ pathname: this.state.redirect, state: { refreshGrid: teamWasCreated || teamWasUpdated, teamWasCreated } }} /> }
        var resources = this.props.resources;

        const handleSubmit = (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit();
        };

        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>)

        return (
            <Dialog fullScreen open={openEditPopup} onClose={this.handleClose} TransitionComponent={Transition}>
                <Form onSubmit={handleSubmit} onKeyPress={event => {
                    if (event.which === 13) {
                        event.preventDefault();
                    }
                }}>
                    <AppBar className="position-sticky ">
                        <Toolbar>
                            <IconButton edge="start" id="close-edit-team" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {editedTeamId !== undefined ? resources["EditTeam"] : resources["AddNewTeamTitle"]}
                            </Typography>
                            <Button id="team-save" color="inherit" type="submit" disabled={requestInProgress}>{resources["Save"]}</Button>

                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        <div className={"py-4 grey" + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
                            {this.renderForm()}
                        </div>
                    </div>
                </Form >
            </Dialog >
        );
    }
}