import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import WorkPreparationsTable from '../components/WorkshopPlanning/WorkPreparation/WorkPreparationsTable';
import WorkPreparationsNew from '../components/WorkshopPlanning/WorkPreparation/WorkPreparationsNew';
import { Permissions } from '../utils/Permissions';

export default function WorkPreparationsRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/workpreparations' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.AccountManagement, Permissions.WorkshopSettings]} resources={resources} render={(props) =>
                (<WorkPreparationsTable resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} moboOutletId={props && props.location && props.location.state && props.location.state.moboOutletId} />)
            } />
            <Switch>
                <Route exact path='/workpreparations/new' render={(props) => (<WorkPreparationsNew resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} moboOutletId={props && props.location && props.location.state && props.location.state.moboOutletId} />)} />
            </Switch>
        </>
    )
}