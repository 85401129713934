import { DisplayMessage } from '../../../../services/helpers';

export function validateDay(day, errorMessage) {
    const { startOfDay, endOfDay, startOfLunch, endOfLunch } = day;

    var valid = true;

    if (startOfDay === null || endOfDay === null || (startOfDay >= endOfDay)) {
        valid = false;
    }
    if ((startOfLunch !== null && endOfLunch === null) || (startOfLunch === null && endOfLunch !== null)) {
        valid = false;
    }
    if (startOfLunch !== null && endOfLunch !== null && startOfLunch >= endOfLunch) {
        valid = false;
    }

    if (startOfDay > startOfLunch || endOfLunch >= endOfDay) {
        valid = false;
    }

    if (!valid) {
        DisplayMessage(errorMessage, 'error');
    }

    return valid;
}

export function createPayload(description, isActive, monday, tuesday, wednesday, thursday, friday, saturday, sunday) {
    var details = [];

    if (monday.startOfDay !== null) {
        details.push({
            dayOfWeek: 1,
            startOfDay: monday.startOfDay,
            endOfDay: monday.endOfDay,
            startOfLunch: monday.startOfLunch,
            endOfLunch: monday.endOfLunch
        })
    }
    if (tuesday.startOfDay !== null) {
        details.push({
            dayOfWeek: 2,
            startOfDay: tuesday.startOfDay,
            endOfDay: tuesday.endOfDay,
            startOfLunch: tuesday.startOfLunch,
            endOfLunch: tuesday.endOfLunch
        })
    }
    if (wednesday.startOfDay !== null) {
        details.push({
            dayOfWeek: 3,
            startOfDay: wednesday.startOfDay,
            endOfDay: wednesday.endOfDay,
            startOfLunch: wednesday.startOfLunch,
            endOfLunch: wednesday.endOfLunch
        })
    }
    if (thursday.startOfDay !== null) {
        details.push({
            dayOfWeek: 4,
            startOfDay: thursday.startOfDay,
            endOfDay: thursday.endOfDay,
            startOfLunch: thursday.startOfLunch,
            endOfLunch: thursday.endOfLunch
        })
    }
    if (friday.startOfDay !== null) {
        details.push({
            dayOfWeek: 5,
            startOfDay: friday.startOfDay,
            endOfDay: friday.endOfDay,
            startOfLunch: friday.startOfLunch,
            endOfLunch: friday.endOfLunch
        })
    }

    if (saturday.startOfDay !== null) {
        details.push({
            dayOfWeek: 6,
            startOfDay: saturday.startOfDay,
            endOfDay: saturday.endOfDay,
            startOfLunch: saturday.startOfLunch,
            endOfLunch: saturday.endOfLunch
        })
    }

    if (sunday.startOfDay !== null) {
        details.push({
            dayOfWeek: 0,
            startOfDay: sunday.startOfDay,
            endOfDay: sunday.endOfDay,
            startOfLunch: sunday.startOfLunch,
            endOfLunch: sunday.endOfLunch
        })
    }

    return {
        description,
        isActive,
        details
    }
}


export function validateDescription(description, errorMessage) {
    if (description.replace(/^\s+|\s+$/gm, '').length === 0 || description.length > 100) {
        DisplayMessage(errorMessage, 'error');
        return false;
    }
    return true;
}

export function validateForm(description, monday, tuesday, wednesday, thursday, friday, saturday, sunday, resources) {
    var shouldValidateMonday = monday.startOfDay !== null || monday.endOfDay !== null || monday.startOfLunch !== null || monday.endOfLunch !== null;
    var shouldValidateTuesday = tuesday.startOfDay !== null || tuesday.endOfDay !== null || tuesday.startOfLunch !== null || tuesday.endOfLunch !== null;
    var shouldValidateWednesday = wednesday.startOfDay !== null || wednesday.endOfDay !== null || wednesday.startOfLunch !== null || wednesday.endOfLunch !== null;
    var shouldValidateThursday = thursday.startOfDay !== null || thursday.endOfDay !== null || thursday.startOfLunch !== null || thursday.endOfLunch !== null;
    var shouldValidateFriday = friday.startOfDay !== null || friday.endOfDay !== null || friday.startOfLunch !== null || friday.endOfLunch !== null;
    var shouldValidateSaturday = saturday.startOfDay !== null || saturday.endOfDay !== null || saturday.startOfLunch !== null || saturday.endOfLunch !== null;
    var shouldValidateSunday = sunday.startOfDay !== null || sunday.endOfDay !== null || sunday.startOfLunch !== null || sunday.endOfLunch !== null;

    var formValid = validateDescription(description, resources["TimetableInvalidDescription"]);

    if (shouldValidateMonday) {
        formValid = formValid & validateDay(monday, resources["TimetableDayInvalid"].replace("#Day#", resources["Monday"]));
    }
    if (shouldValidateTuesday) {
        formValid = formValid & validateDay(tuesday, resources["TimetableDayInvalid"].replace("#Day#", resources["Tuesday"]));
    }
    if (shouldValidateWednesday) {
        formValid = formValid & validateDay(wednesday, resources["TimetableDayInvalid"].replace("#Day#", resources["Wednesday"]));
    }
    if (shouldValidateThursday) {
        formValid = formValid & validateDay(thursday, resources["TimetableDayInvalid"].replace("#Day#", resources["Thursday"]));
    }
    if (shouldValidateFriday) {
        formValid = formValid & validateDay(friday, resources["TimetableDayInvalid"].replace("#Day#", resources["Friday"]));
    }
    if (shouldValidateSaturday) {
        formValid = formValid & validateDay(saturday, resources["TimetableDayInvalid"].replace("#Day#", resources["Saturday"]));
    }
    if (shouldValidateSunday) {
        formValid = formValid & validateDay(sunday, resources["TimetableDayInvalid"].replace("#Day#", resources["Sunday"]));
    }

    return formValid;
}