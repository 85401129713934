import React from 'react';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import { Permissions } from '../utils/Permissions';
import PaymentConnections from '../components/Payments/PaymentConnections'

export default function PaymentConnectionsRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path="/paymentconnections" userPermissions={currentIdentity.permissions} routePermissions={[Permissions.Payments]} resources={resources} render={(props) => {
                return (<PaymentConnections resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} location={props.location} />)
            }} />

        </>
    )
}