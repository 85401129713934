import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import AgendasTable from '../components/WorkshopPlanning/Planning/Agendas/AgendasTable';
import AgendaNew from '../components/WorkshopPlanning/Planning/Agendas/AgendaNew';
import { Permissions } from '../utils/Permissions';

export default function AgendasRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/agendas' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<AgendasTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} agendaWasCreated={props && props.location && props.location.state && props.location.state.agendaWasCreated} />)
            }} />
            <Switch>
                <Route exact path='/agendas/new' render={(props) => (<AgendaNew resources={resources}  {...props} />)} />
                <Route exact path='/agendas/:agendaId' render={(props) => {
                    return (<AgendaNew resources={resources} agendaId={props.match.params.agendaId} {...props} />)
                }} />
            </Switch>
        </>
    )
}