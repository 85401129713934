import { DisplayMessage } from "../../../../../services/helpers";
import { UpdateRequest } from "../../../../../services/requestHelpers";
import { HttpMethods } from "../../../../../utils/constants";

const baseUrlV2 = "/api/mobopro/v2/snippets";

export const createSnippet = async (data) => {
  const response = await UpdateRequest(`${baseUrlV2}`, data, HttpMethods.Post);
  var result = await response.json();
  if (!response.ok) {
    for (var i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return undefined;
  }
  return result;
}