import React, { useEffect, useState } from 'react';
import toastr from 'toastr';
import "./Login.css";
import sha1 from 'crypto-js/sha1';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../auth/authConfig";

export function LoginAD(props) {
    const { instance, accounts } = useMsal();

    useEffect(() => {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ token: response.accessToken })
                };
                fetch("/api/mobopro/v1/authenticate/ad", requestOptions)
                    .then(response => {
                        response.json().then(data => {
                            if (!response.ok) {
                                for (var i = 0; i < data.errors.length; i++) {
                                    toastr.error(data.errors[i]);
                                    sessionStorage.clear();
                                    setTimeout(function () {
                                        document.location.href = "/";
                                    }, 1000)
                                }
                            }
                            else {
                                var authorizationToken = sha1(data.uniqueKey + "qMymank8Q0rNyJ0RxDXE");
                                props.loginCallback(data.uniqueKey, authorizationToken.toString())
                            }
                        })
                    })
            });
    }, [])

    return (
        <div className="Login">
            <div className="text-center">
                <img src="https://www.autodialog.com/_prototype/dist/assets/images/logo_vertical.svg" className="logo" />
            </div>
            <form className="my-4" >
                <div className="text-center">
                    (<CircularProgress />) :
                </div>
            </form>
        </div >
    )
}