import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import MobilitySolutionsTable from '../components/WorkshopPlanning/General/MobilitySolutions/MobilitySolutionsTable';
import MobilitySolutionsCreation from '../components/WorkshopPlanning/General/MobilitySolutions/MobilitySolutionsCreation';
import MobilitySolutionsEditing from '../components/WorkshopPlanning/General/MobilitySolutions/MobilitySolutionsEditing';
import { Permissions } from '../utils/Permissions';

export default function MobilitySolutionsRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
            <RestrictedRoute path='/mobilitysolutions' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<MobilitySolutionsTable resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
                    refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} outletId={props && props.location && props.location.state && props.location.state.outletId} />)
            }} />
            <Switch>
                <Route exact path='/mobilitysolutions/new' render={(props) => (<MobilitySolutionsCreation outletId={props && props.location && props.location.state && props.location.state.outletId} resources={resources} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)} />
                <Route exact path='/mobilitysolutions/:outletId/:mobilitySolutionId' render={(props) => {
                    return (<MobilitySolutionsEditing resources={resources} mobilitySolutions={props && props.location && props.location.state && props.location.state.mobilitySolutions}
                        outletId={props.match.params.outletId} mobilitySolutionId={props.match.params.mobilitySolutionId} accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)
                }} />
            </Switch>
        </>
    )
}