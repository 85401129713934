import {
  InputLabel,
  Select,
  FormControl,
  Box,
  FormHelperText,
} from "@material-ui/core";
import React from "react";

const DropdownComponent = ({
  value,
  handleChange,
  options,
  labelText,
  id,
  disable,
  helperText,
  error,
}) => {
  return (
    <Box className="d-flex form-group">
      <FormControl className="col-12 main-dropdown" variant="outlined">
        <InputLabel id={id + "_dropdown"}>{labelText}</InputLabel>
        <Select
          disabled={disable ?? false}
          native
          id={id + "_selected"}
          value={value}
          label={labelText}
          onChange={handleChange}
        >
          {options.map(({ key, name }) => (
            <option key={`${id}-${key}`} value={key}>
              {name}
            </option>
          ))}
        </Select>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default DropdownComponent;
