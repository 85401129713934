import { Paper, ThemeProvider } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { TableIcons } from "../../../utils/TableIcons";
import { LocalizationTheme } from "../../../utils/utils";

const TableComponent = ({ data, tableSettings, resources }) => {
  return (
    <Paper elevation={3} className="p-3 mt-3">
      <ThemeProvider theme={LocalizationTheme()}>
        <MaterialTable
          tableLayout="fixed"
          icons={TableIcons}
          title={""}
          localization={{
            header: { actions: resources["Actions"] },
            body: {
              emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
              deleteTooltip: resources["DeleteTooltip"],
              editTooltip: resources["EditTooltip"],
            },
            toolbar: {
              searchPlaceholder: resources["Search"],
              searchTooltip: resources["Search"],
            },
            pagination: {
              firstTooltip: resources["FirstTooltip"],
              lastTooltip: resources["LastTooltip"],
              previousTooltip: resources["PreviousTooltip"],
              nextTooltip: resources["NextTooltip"],
              labelRowsPerPage: resources["LabelRowsPerPage"],
              labelRowsSelect: resources["LabelRowsSelect"],
            },
          }}
          data={data}
          columns={tableSettings.columns}
          actions={tableSettings.actions}
          options={{
            emptyRowsWhenPaging: false,
            pageSize: 10,
            pageSizeOptions: [10, 15, 20],
          }}
        />
      </ThemeProvider>
    </Paper>
  );
};

export default TableComponent;
