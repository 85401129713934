import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../Loader.js';
import Form from 'react-bootstrap/Form';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Paper from '@material-ui/core/Paper';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { SelectTheme, LocalizationTheme } from '../../../utils/utils.js';
import { DisplayMessage } from '../../../services/helpers.js';
import { ThemeProvider } from "@material-ui/core";


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class TaskWorkflowsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            outletId: 0,
            fetchingData: true,
            showDelete: false,
            deletedItem: 0,
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid) {
            this.getData(newProps.outletId);
            this.setState({ outletId: newProps.outletId !== undefined ? newProps.outletId : 0 })
        }

        this.setState({ redirect: null });
    }

    componentDidMount() {
        this.getData();
    }


    handleChangeOutlet = e => {
        var moboOutletDesc = e.target.selectedIndex !== 0 ? this.props.accessibleMoboOutlets[e.target.selectedIndex - 1].description : "-";
        this.setState({ outletId: e.target.selectedIndex === 0 ? 0 : e.target.value, outletDescription: moboOutletDesc }, () => this.getData(this.state.outletId));
    };

    renderOutlets = () => {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: 0 });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleDeleteTaskWorkflow = () => {
        if (this.state.deletedItem > 0) {
            let resources = this.props.resources;
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/eventtasks/workflows/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                        this.handleCloseDelete();
                    }

                }
                else {
                    DisplayMessage(resources["TaskWorkflowDeleted"], "success");
                    this.getData();
                    this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                    this.handleCloseDelete();
                }
            })
        }
    }

    getData = (outletId) => {
        const { accessibleMoboOutlets } = this.props;
        this.setState({ fetchingData: true });

        if (outletId === undefined || outletId === 0) {
            if (accessibleMoboOutlets === undefined) return;
            outletId = null;
        }

        let initialData = {};
        var resources = this.props.resources;
        initialData.columns = [
            { title: resources["Description"], width: "50%", field: 'description', filtering: false, customSort: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()) },
            { title: resources["Outlet"], width: "25%", field: 'outlet', filtering: false, customSort: (a, b) => a.outlet.toLowerCase().localeCompare(b.outlet.toLowerCase()) },
            { title: resources["IsActive"], width: "25%", field: 'isActive', filtering: false, customSort: (a, b) => a.isActive.toLowerCase().localeCompare(b.isActive.toLowerCase()) },
        ];

        initialData.actions = [
            {
                icon: () => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `taskworkflows/${rowData.id}` })
            },
            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.handleShowDelete(rowData.id)
            }
        ];

        if (outletId === 0) return;
        fetch(`/api/mobopro/v1/eventtasks/workflows?outletid=${outletId}`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) { throw new Error(); }
                return response.json();
            }).then(result => {
                const currentRows = [];

                result.forEach(function (rowData) {
                    var outlet = accessibleMoboOutlets.find(x => x.id === rowData.outletId);
                    currentRows.push({
                        id: rowData.id,
                        description: rowData.description,
                        outlet: outlet !== undefined ? outlet.description : "",
                        isActive: rowData.isActive ? resources["Yes"] : resources["No"]
                    });
                });

                initialData.data = currentRows;
                this.setState({
                    dataGrid: initialData,
                    fetchingData: false
                });
            });
    }

    render() {
        const { redirect, fetchingData } = this.state;
        const { resources } = this.props;

        if (redirect) {
            return <Redirect to={{
                pathname: redirect,
                state: { editedOutletUserId: this.state.editedOutletUserId, refreshGrid: true }
            }}
            />
        }

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["TaskWorkflowsTitle"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <Form.Group className="main-dropdown" onClick={this.handleTyping}>
                            <FormControl className="col-md-12" variant="outlined" onChange={this.handleChangeOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    native
                                    label={resources["Outlet"]}
                                    value={this.state.outletId}
                                    inputProps={{
                                        name: 'outlet',
                                        id: 'outlined-outlet-native-simple',
                                    }}>
                                    <option value={0}>{resources["All"]}</option>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <Link to={{ pathname: `/taskworkflows/new`, state: { outletId: this.state.outletId } }} style={{ textDecoration: 'none' }}>
                                <Button id="add-workflow" variant="contained" color="primary">
                                    <i className="fas fa-plus mr-2" title={resources["AddNewWorkflow"]} />
                                    {resources["AddNewWorkflow"]}
                                </Button>
                            </Link >
                        </div>
                        <div style={{ clear: "both", }}></div>
                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={this.state.dataGrid.actions}
                                    options={{ emptyRowsWhenPaging: false, pageSize: 10, pageSizeOptions: [10, 15, 20] }}
                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ margin: "20px" }}>{resources["AreYouSureYouWantToDeleteTaskWorkflow"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleDeleteTaskWorkflow} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>
                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>
                </div>
            </div>
        );
    }
}