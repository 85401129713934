import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, TextField, Typography, Box, Paper, FormGroup, FormLabel, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import moment from 'moment';
import 'toastr/build/toastr.min.css';
import { DisplayMessage, GetDefaultAgendasDetails, GetMondays, GetValidTimetables, SearchTimetable, SetFixedDatetimeFormat,ExcludeSpecialCharactersFromInputTypeNumber } from '../../../../services/helpers.js';
import { Dialog, AppBar, Toolbar, Checkbox, FormControlLabel, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from '@material-ui/core/IconButton';
import { Redirect } from 'react-router-dom';
import FileCopy from '@material-ui/icons/FileCopy';
import Delete from '@material-ui/icons/Delete';
import defaultavatar from '../../../../defaultavatar.png';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class AgendaNew extends Component {
    constructor(props) {
        super(props);
        var agendaId = undefined;

        if (this.props !== undefined) {
            agendaId = this.props.agendaId;
        }

        this.state = {
            id: 0,
            description: "",
            year: (new Date()).getFullYear(),
            isActive: false,
            modifiedOn: "",
            modifiedByName: "",
            modifiedByAvatar: "",
            loading: false,
            editedAgendaId: agendaId,
            requestInProgress: false,
            openEditPopup: true,
            showEditAgenda: false,
            redirect: null,
            agendasWasCreated: false,
            timetables: [],
            agendaDetails: GetDefaultAgendasDetails((new Date()).getFullYear()),
            monthExpended: ""
        };
    }

    componentDidMount() {
        if (this.state.editedAgendaId !== undefined) {
            this.getAgendaById(this.state.editedAgendaId);
        }
        this.getTimetables();
    }

    handleChange = e => {
        if (e.target.name === "year") {
            this.setState({ [e.target.name]: e.target.value, agendaDetails: GetDefaultAgendasDetails(e.target.value), requestInProgress: false });
            return;
        }
        this.setState({ [e.target.name]: e.target.value, requestInProgress: false });
    };

    handleChangeCheckBox = e => {
        this.setState({ [e.target.name]: e.target.checked, requestInProgress: false });
    };

    handleSubmit = () => {
        if (this.state.id == 0) {
            this.saveNewAgenda();
        }
        else {
            this.saveEditedAgenda();
        }
    }

    getAgendaById = (agendaId) => {
        const url = "/api/mobopro/v1/workshopagendas/" + agendaId;
        fetch(url, {
            method: 'GET',

            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
        }).then(response => response.json()).then(result => {
            if (result.id !== undefined) {
                var agendasDetails = GetDefaultAgendasDetails(result.year);

                result.details.map(newItem => {
                    agendasDetails.map(oldItem => {
                        if (oldItem.startOfWeek === SetFixedDatetimeFormat(new Date(newItem.startOfWeek))) oldItem.timetableId = newItem.timetable !== null ? newItem.timetable.id : 0;
                    })
                });

                this.setState({
                    id: result.id,
                    description: result.description,
                    isActive: result.isActive,
                    year: result.year,
                    loading: false,
                    modifiedOn: result.modifiedOn,
                    modifiedByName: result.modifiedBy.firstName + " " + result.modifiedBy.lastName,
                    modifiedByAvatar: result.modifiedBy.avatar,
                    agendaDetails: agendasDetails
                });
            }
        });
    }

    validateData = () => {
        var resources = this.props.resources;
        var isValid = true;

        if (this.state.description.trim().length <= 0) {
            DisplayMessage(resources["InvalidDescripton"], "error");
            return false;
        }

        if (this.state.year < 2000 || this.state.year > 2200) {
            DisplayMessage(resources["InvalidYear"], "error");
            return false;
        }

        return isValid;
    }

    saveNewAgenda = () => {
        this.setState({ requestInProgress: true });

        let resources = this.props.resources;
        if (!this.validateData()) return;

        var json = JSON.stringify({
            description: this.state.description,
            year: this.state.year,
            isActive: this.state.isActive,
            details: GetValidTimetables(this.state.agendaDetails),
        });

        const url = "/api/mobopro/v1/workshopagendas";
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            if (!response.ok) {
                response.json().then(result => {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                });

            }
            else {
                DisplayMessage(resources["NewAgendaWasSaved"], "success");
                this.setState({ requestInProgress: false, openEditPopup: false, redirect: "/agendas", agendasWasCreated: response.ok });
            }
        });
    }

    saveEditedAgenda = () => {
        this.setState({ requestInProgress: true });
        let resources = this.props.resources;
        if (!this.validateData()) return;

        var json = JSON.stringify({
            description: this.state.description,
            year: this.state.year,
            isActive: this.state.isActive,
            details: GetValidTimetables(this.state.agendaDetails),
        });

        const url = "/api/mobopro/v1/workshopagendas/" + this.state.id;
        fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {
                response.json().then(data => {
                    if (!response.ok) {
                        var errors = data.errors
                        for (var i = 0; i < errors.length; i++) {
                            DisplayMessage(errors[i], "error");
                        }
                        this.setState({ updateInProgress: false, redirect: (response.ok ? '/agendas' : null), timetableWasEdited: response.ok, agendasWasCreated:response.ok});
                        this.handleClose();
                    }
                    else {
                        this.setState({ updateInProgress: false, redirect: (response.ok ? '/agendas' : null), timetableWasEdited: response.ok, agendasWasCreated:response.ok});
                        this.handleClose();
                        DisplayMessage(resources["AgendaWasSaved"], "success");
                    }
                })
            })

    }

    renderModifiedByInfo = () => {
        let resources = this.props.resources;
        const { modifiedByName, modifiedOn, modifiedByAvatar } = this.state;

        if (this.state.id > 0) {
            return (<FormGroup row>
                <div className="w-100 text-center  mb-4">
                    <span className=" mr-2">
                        <img className="rounded-circle" alt="user-avatar" src={(modifiedByAvatar == null || modifiedByAvatar == "") ? defaultavatar : modifiedByAvatar} width="35" />
                    </span>
                    <span>
                        {`${resources["ModifiedOn"]} ${moment(modifiedOn).format('DD/MM/YYYY').toLocaleString()} ${resources["By"]} ${modifiedByName}`}
                    </span>
                </div>
            </FormGroup>);
        }

        return;
    }

    handleClose = () => {
      this.setState({ openEditPopup: false, redirect: "/agendas" });
    }

    getTimetables = () => {
        const url = "/api/mobopro/v1/workshoptimetables";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            })
        })
            .then(response => response.json())
            .then(result => {
                const timetables = [];

                result.forEach(function (item) {
                    if (item.isActive === true) {
                        timetables.push({
                            id: item.id,
                            description: item.description,
                        });
                    }
                });
                var timetablesSorted = timetables.sort((a, b) => (a.description > b.description) ? 1 : -1);
                this.setState({ timetables: timetablesSorted });
            });
    }

    handleChangeMonth = (month) => {
        var newPannel = "month" + month
        if (newPannel === this.state.monthExpended) newPannel = "";
        this.setState({ monthExpended: newPannel, requestInProgress: false });
    };

    handleChangeTimetable = e => {
        var existingTimeTables = this.state.agendaDetails;

        for (var i = 0; i < existingTimeTables.length; i++) {
            if (existingTimeTables[i].startOfWeek === e.target.name) {
                existingTimeTables[i].timetableId = e.target.value;
                this.setState({ agendaDetails: existingTimeTables, requestInProgress: false });
                return;
            }
        }
    };

    handleTimatablesCopy = () => {
        let agendaDetails = this.state.agendaDetails;
        let firstItem = agendaDetails[0];
        agendaDetails.map(item => {
            if (item.startOfWeek !== firstItem.startOfWeek) item.timetableId = firstItem.timetableId;
        });

        this.setState({ agendaDetails: agendaDetails, requestInProgress: false });
    }

    handleTimatablesReset = (val) => {
        let agendaDetails = this.state.agendaDetails;
        agendaDetails.map(item => {
            if (item.startOfWeek === val) {
                item.timetableId = 0;
                this.setState({ agendaDetails: agendaDetails, requestInProgress: false });
                return;
            }
        });
    }

    renderAgendasDetails = () => {
        var resources = this.props.resources;
        var timetables = this.state.timetables.map(x => <MenuItem value={x.id}> {x.description}</MenuItem>);

        const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        const monthsName = [resources["January"], resources["February"], resources["March"], resources["April"], resources["May"], resources["June"],
        resources["July"], resources["August"], resources["September"], resources["October"], resources["November"], resources["December"]];
        return (
            <div className="col-md-12  m-0 p-0">
                {
                    months.map(month => {
                        var currentYear = this.state.year;
                        var monthFix = month < 9 ? "0" + (month + 1) : (month + 1);
                        var mondaysOfMonth = GetMondays(month, this.state.year);
                        return (
                            <Accordion id={`month${month}`} name={`month${month}`}
                                expanded={this.state.monthExpended === `month${month}`}
                                onChange={() => this.handleChangeMonth(month)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="month1bh-content">
                                    <Typography style={{ textAlign: "left" }}>{monthsName[month]}</Typography>
                                    <Typography >
                                        {resources["FirstMondayOf"]} {monthsName[month]}  {resources["is"]} {mondaysOfMonth[0].getDate()}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {mondaysOfMonth.map(monday => {
                                            var fixedDatetime = SetFixedDatetimeFormat(monday);
                                            var dayNumber = monday.getDate();
                                            var labelMonth = `${dayNumber}.${monthFix}.${currentYear}`;

                                            return (
                                                <div className="col-md-12">
                                                    <FormControl style={{ minWidth: "120" }} className="mb-4 col-md-11" variant="outlined" >
                                                        <InputLabel id={`outlined-${dayNumber}`}>{labelMonth} </InputLabel>
                                                        <Select
                                                            label={labelMonth}
                                                            labelId={`label-select-${dayNumber}`}
                                                            id={`select-${dayNumber}`}
                                                            name={fixedDatetime}
                                                            value={SearchTimetable(monday, this.state.agendaDetails)}
                                                            className=""
                                                            onChange={this.handleChangeTimetable}>
                                                            <MenuItem value={0}>{resources["DefaultDropdownValue"]}</MenuItem>
                                                            {timetables}
                                                        </Select>

                                                    </FormControl>
                                                    <Delete name={fixedDatetime} className="" style={{ color: "#53B3EA", margin: "5px", cursor: "pointer" }} onClick={() => this.handleTimatablesReset(fixedDatetime)} />

                                                    {
                                                        fixedDatetime === this.state.agendaDetails[0].startOfWeek ?
                                                            <FileCopy className="" style={{ color: "#53B3EA", margin: "5px", cursor: "pointer" }} onClick={() => this.handleTimatablesCopy()} /> :
                                                            <div></div>
                                                    }
                                                </div>

                                            )
                                        }
                                        )}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
            </div >
        );
    }

    renderForm() {

        const { redirect, requestInProgress, isActive, openEditPopup, editedAgendaId, agendasWasCreated, description, year } = this.state;
        const { resources } = this.props;

        if (redirect) { return <Redirect to={{ pathname: this.state.redirect, state: { agendasWasCreated, refreshGrid:agendasWasCreated } }} /> }

        var helperTextYear = year === "" ? "helper-text-red" : "helper-text-grey";
        var helperTextDescription = description === "" ? "helper-text-red" : "helper-text-grey";
        var isDisabled = editedAgendaId > 0;

        const handleSubmit = (event) => {
            const form = event.currentTarget;
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit();
        };
        return (

            <Dialog fullScreen open={openEditPopup} onClose={this.handleClose} TransitionComponent={Transition}>
                <Form onSubmit={handleSubmit} >

                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton edge="start" className="float-left " color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {editedAgendaId !== undefined ? resources["EditAgenda"] : resources["AddNewAgendaTitle"]}
                            </Typography>
                            <Button autoFocus color="inherit" id="saveItem" type="submit" disabled={requestInProgress} onClick={this.saveTimetable}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>

                    <div className="container">
                        <div className="py-4 grey">
                            <Paper elevation={3} className="p-4 mt-3 mb-3">
                                <FormGroup display="flex" flexDirection="column" style={{ flexWrap: "nowrap" }}>
                                    <ThemeProvider theme={SelectTheme}>
                                        <TextField className={"mb-4 " + helperTextDescription}
                                            id="description"
                                            inputProps={{ maxLength: 100 }}
                                            value={description}
                                            name="description"
                                            label={resources["Description"]}
                                            helperText={resources["DescriptionHelperText"]}
                                            variant="outlined" onChange={this.handleChange} />
                                        <TextField className={"col-md-2 " + helperTextYear} id="year" inputProps={{ maxLength: 4 }} value={year} name="year" type="number"
                                            onKeyDown={ExcludeSpecialCharactersFromInputTypeNumber(false)} 
                                            label={resources["Year"]} disabled={isDisabled} helperText={resources["YearHelperText"]} variant="outlined" onChange={this.handleChange} />
                                    </ThemeProvider>
                                    <FormGroup row>
                                        <FormControlLabel control={<Checkbox checked={isActive} onChange={this.handleChangeCheckBox} name="isActive" color="primary" />} label={resources["IsActive"]} />
                                    </FormGroup>

                                    {this.renderModifiedByInfo()}
                                    <FormGroup row >
                                        {this.renderAgendasDetails()}
                                    </FormGroup>
                                </FormGroup>
                            </Paper>
                        </div>
                    </div>
                </Form>
            </Dialog>
        );
    }

    render() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>)

        return (
            <div className={"pt-4 grey text-center" + (this.state.loading ? 'fadeOut' : 'fadeIn')} >

                <div className="container">
                    {this.renderForm()}
                </div>
            </div>
        );
    }
}