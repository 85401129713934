import React from "react";
import { RestrictedRoute } from "../utils/RestrictedRoute";
import { Permissions } from "../utils/Permissions";
import { Switch, Route } from "react-router";
import AssetGroupItem from "../components/Drive/AssetGroups/AssetGroupItem";
import AssetGroupsTable from "../components/Drive/AssetGroups/AssetGroupsTable";
import { NewAssetGroupsRoute } from "../constants/routePaths";

export default function AssetGroupsRouting(props) {
  const { currentIdentity, resources } = props;
  return (
    <>
      <RestrictedRoute
        path="/assetgroups"
        userPermissions={currentIdentity.permissions}
        routePermissions={[Permissions.WorkshopSettings]}
        resources={resources}
        render={(props) => {
          return (
            <AssetGroupsTable
              resources={resources}
              refreshGrid={
                props &&
                props.location &&
                props.location.state &&
                props.location.state.refreshGrid
              }
            />
          );
        }}
      />
      <Switch>
        <Route
          exact
          path={NewAssetGroupsRoute}
          render={(props) => (
            <AssetGroupItem
              resources={resources}
              defaultGenericType={currentIdentity.objectType}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/assetgroups/:assetGroupId"
          render={(props) => (
            <AssetGroupItem
              resources={resources}
              defaultGenericType={currentIdentity.objectType}
              assetGroupId={props.match.params.assetGroupId}
            />
          )}
        />
      </Switch>
    </>
  );
}
