import React from 'react';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import ChatMessagesTable from '../components/Triggers/ChatMessages/ChatMessagesTable';
import { Permissions } from '../utils/Permissions';

export default function ChatMessagesRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
             <RestrictedRoute path='/chatmessages' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<ChatMessagesTable resources={resources}
                    refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} />)
            }} />
        </>
    )
}