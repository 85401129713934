import React from 'react';
import { Route, Switch } from 'react-router';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import AbsenceTypesTable from '../components/WorkshopPlanning/Planning/AbsenceTypes/AbsenceTypesTable';
import AbsenceTypeNew from '../components/WorkshopPlanning/Planning/AbsenceTypes/AbsenceTypeNew';
import AbsenceTypeEdit from '../components/WorkshopPlanning/Planning/AbsenceTypes/AbsenceTypeEdit';
import { Permissions } from '../utils/Permissions';

export default function AbsenceTypesRouting(props) {
    const { currentIdentity, resources } = props;
    return (
        <>
             <RestrictedRoute path='/absencetypes' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
                return (<AbsenceTypesTable resources={resources} refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid} />)
            }} />
            <Switch>
                <Route exact path='/absencetypes/new' render={(props) => (<AbsenceTypeNew resources={resources}  {...props} />)} />
                <Route exact path='/absencetypes/:absenceTypeId' render={(props) => {
                    return (<AbsenceTypeEdit resources={resources} absenceTypeId={props.match.params.absenceTypeId} {...props} />)
                }} />
            </Switch>
        </>
    )
}