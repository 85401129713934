import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Paper from '@material-ui/core/Paper';
import { DisplayMessage } from '../../../../services/helpers';
import CircularProgress from '@material-ui/core/CircularProgress';

import { TimePicker } from 'antd';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Transition } from '../../../../utils/ModalHelpers';
import DelayedRedirect from '../../../../utils/DelayedRedirect';

import { createPayload, validateForm } from './TimetableHelpers';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

const format = 'HH:mm';

export default class TimetableEditing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchingData: true,
            redirect: null,
            modalOpened: true,
            description: '',
            isActive: false,
            updateInProgress: false,
            timetableWasEdited: false,
            monday: {
                startOfDay: null,
                endOfDay: null,
                startOfLunch: null,
                endOfLunch: null
            },
            tuesday: {
                startOfDay: null,
                endOfDay: null,
                startOfLunch: null,
                endOfLunch: null
            },
            wednesday: {
                startOfDay: null,
                endOfDay: null,
                startOfLunch: null,
                endOfLunch: null
            },
            thursday: {
                startOfDay: null,
                endOfDay: null,
                startOfLunch: null,
                endOfLunch: null
            },
            friday: {
                startOfDay: null,
                endOfDay: null,
                startOfLunch: null,
                endOfLunch: null
            },
            saturday: {
                startOfDay: null,
                endOfDay: null,
                startOfLunch: null,
                endOfLunch: null
            },
            sunday: {
                startOfDay: null,
                endOfDay: null,
                startOfLunch: null,
                endOfLunch: null
            }
        }
    }

    componentWillMount() {
        this.retrieveTimetable(this.props.timetableId);
    }

    retrieveTimetable(id) {
        this.setState({ fetchingData: true }, () => {
            var publicKey = localStorage.getItem('publicKey');
            var authorizationToken = localStorage.getItem('authorizationToken');
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'PublicKey': publicKey, 'Authorization-token': authorizationToken }
            };

            fetch(`/api/mobopro/v1/workshoptimetables/${id}`, requestOptions)
                .then(response => {
                    response.json().then(data => {
                        if (!response.ok) {
                            for (var i = 0; i < data.errors.length; i++) {
                                DisplayMessage(data.errors[i], 'error', 0);
                            }
                            this.setState({ fetchingData: false });
                            this.handleClose();
                        }
                        else {
                            var week = data.details;

                            var mondayDetails = week.filter(function (item) { return item.dayOfWeek === 1 })[0] || null;
                            var tuesdayDetails = week.filter(function (item) { return item.dayOfWeek === 2 })[0] || null;
                            var wednesdayDetails = week.filter(function (item) { return item.dayOfWeek === 3 })[0] || null;
                            var thursdayDetails = week.filter(function (item) { return item.dayOfWeek === 4 })[0] || null;
                            var fridayDetails = week.filter(function (item) { return item.dayOfWeek === 5 })[0] || null;
                            var saturdayDetails = week.filter(function (item) { return item.dayOfWeek === 6 })[0] || null;
                            var sundayDetails = week.filter(function (item) { return item.dayOfWeek === 0 })[0] || null;
                            this.setState({
                                fetchingData: false,
                                description: data.description,
                                isActive: data.isActive,
                                monday: {
                                    startOfDay: mondayDetails !== null ? mondayDetails.startOfDay : null,
                                    endOfDay: mondayDetails !== null ? mondayDetails.endOfDay : null,
                                    startOfLunch: mondayDetails !== null ? mondayDetails.startOfLunch : null,
                                    endOfLunch: mondayDetails !== null ? mondayDetails.endOfLunch : null
                                },
                                tuesday: {
                                    startOfDay: tuesdayDetails !== null ? tuesdayDetails.startOfDay : null,
                                    endOfDay: tuesdayDetails !== null ? tuesdayDetails.endOfDay : null,
                                    startOfLunch: tuesdayDetails !== null ? tuesdayDetails.startOfLunch : null,
                                    endOfLunch: tuesdayDetails !== null ? tuesdayDetails.endOfLunch : null,
                                },
                                wednesday: {
                                    startOfDay: wednesdayDetails !== null ? wednesdayDetails.startOfDay : null,
                                    endOfDay: wednesdayDetails !== null ? wednesdayDetails.endOfDay : null,
                                    startOfLunch: wednesdayDetails !== null ? wednesdayDetails.startOfLunch : null,
                                    endOfLunch: wednesdayDetails !== null ? wednesdayDetails.endOfLunch : null,
                                },
                                thursday: {
                                    startOfDay: thursdayDetails !== null ? thursdayDetails.startOfDay : null,
                                    endOfDay: thursdayDetails !== null ? thursdayDetails.endOfDay : null,
                                    startOfLunch: thursdayDetails !== null ? thursdayDetails.startOfLunch : null,
                                    endOfLunch: thursdayDetails !== null ? thursdayDetails.endOfLunch : null,
                                },
                                friday: {
                                    startOfDay: fridayDetails !== null ? fridayDetails.startOfDay : null,
                                    endOfDay: fridayDetails !== null ? fridayDetails.endOfDay : null,
                                    startOfLunch: fridayDetails !== null ? fridayDetails.startOfLunch : null,
                                    endOfLunch: fridayDetails !== null ? fridayDetails.endOfLunch : null,
                                },
                                saturday: {
                                    startOfDay: saturdayDetails !== null ? saturdayDetails.startOfDay : null,
                                    endOfDay: saturdayDetails !== null ? saturdayDetails.endOfDay : null,
                                    startOfLunch: saturdayDetails !== null ? saturdayDetails.startOfLunch : null,
                                    endOfLunch: saturdayDetails !== null ? saturdayDetails.endOfLunch : null,
                                },
                                sunday: {
                                    startOfDay: sundayDetails !== null ? sundayDetails.startOfDay : null,
                                    endOfDay: sundayDetails !== null ? sundayDetails.endOfDay : null,
                                    startOfLunch: sundayDetails !== null ? sundayDetails.startOfLunch : null,
                                    endOfLunch: sundayDetails !== null ? sundayDetails.endOfLunch : null,
                                }
                            })
                        }
                    })
                });
        })
    }

    clearEntireDay = (day) => {
        var entireDay = this.state[day];

        entireDay.startOfDay = null;
        entireDay.endOfDay = null;
        entireDay.startOfLunch = null;
        entireDay.endOfLunch = null;

        this.setState({ [day]: entireDay });
    }

    copyMonday = () => {
        var monday = this.state.monday;

        this.setState({ tuesday: { ...monday }, wednesday: { ...monday }, thursday: { ...monday }, friday: { ...monday } });
    }

    handleChangeTimetableInput = (day, value, inputType) => {
        var entireDay = this.state[day];
        entireDay[inputType] = value === '' ? null : value;

        this.setState({ [day]: entireDay });
    }


    handleClose = () => {
      this.setState({ modalOpened: false, redirect: "/timetables" });
    };

    handleChangeDescription = (value) => {
        this.setState({ description: value });
    }

    handleChangeIsActive = () => {
        const { isActive } = this.state;

        this.setState({ isActive: !isActive });
    }

    validateInputs = () => {
        const { description, monday, tuesday, wednesday, thursday, friday, saturday, sunday } = this.state;
        const { resources } = this.props;

        return validateForm(description, monday, tuesday, wednesday, thursday, friday, saturday, sunday, resources);
    }

    saveTimetable = () => {

        var formValid = this.validateInputs();
        if (!formValid) return;

        const { description, isActive, monday, tuesday, wednesday, thursday, friday, saturday, sunday } = this.state;
        const { timetableId, resources } = this.props;

        var payload = createPayload(description, isActive, monday, tuesday, wednesday, thursday, friday, saturday, sunday);

        this.setState({ updateInProgress: true }, () => {
            fetch(`/api/mobopro/v1/workshoptimetables/${timetableId}`, {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
                body: JSON.stringify(payload)
            }).then(response => {
                response.json().then(data => {
                    if (!response.ok) {
                        var errors = data.errors
                        for (var i = 0; i < errors.length; i++) {
                            DisplayMessage(errors[i], "error", 0);
                        }
                    }
                    else {
                        DisplayMessage(resources["TimetableEditedSuccessfully"], "success", 0)
                    }
                    this.setState({ updateInProgress: false, redirect: (response.ok ? '/timetables' : null), timetableWasEdited: response.ok });
                })
            });
        })

    }

    renderDay = (day, data, hasCopyFunction) => {
        const { resources } = this.props;

        return (
            <React.Fragment>
                <Grid item xs={2} style={{ margin: "auto" }}>
                    <Typography variant="subtitle1">{resources[day.charAt(0).toUpperCase() + day.slice(1)]}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} format={format} value={data.startOfDay !== null ? moment(data.startOfDay, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(day, timeString, "startOfDay")} />
                </Grid>
                <Grid item xs={2}>
                    <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} format={format} value={data.endOfDay !== null ? moment(data.endOfDay, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(day, timeString, "endOfDay")} />
                </Grid>
                <Grid item xs={2}>
                    <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} format={format} value={data.startOfLunch !== null ? moment(data.startOfLunch, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(day, timeString, "startOfLunch")} />
                </Grid>
                <Grid item xs={2}>
                    <TimePicker minuteStep={5} placeholder={resources["SelectTime"]} format={format} value={data.endOfLunch !== null ? moment(data.endOfLunch, format) : null} onChange={(time, timeString) => this.handleChangeTimetableInput(day, timeString, "endOfLunch")} />
                </Grid>
                <Grid item xs={2} style={{ textAlign: "left" }}>
                    <IconButton size="small"><DeleteIcon onClick={() => this.clearEntireDay(day)} color="primary" /></IconButton>
                    {hasCopyFunction && <IconButton size="small"> <FileCopyIcon onClick={() => this.copyMonday()} color="primary" className="ml-2" /></IconButton>}
                </Grid>
            </React.Fragment>
        )
    }

    render() {
        const { redirect, fetchingData, timetableWasEdited, modalOpened, description, isActive, monday, tuesday, wednesday, thursday, friday, saturday, sunday } = this.state;
        const { resources } = this.props;
        var helperTextDescription = description === "" ? "helper-text-red" : "helper-text-grey";

        return (
            <div>
                {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: timetableWasEdited } }} delay={250} />}
                <Dialog fullScreen open={modalOpened} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>{resources["EditTimetable"]}</Typography>
                            <Button autoFocus color="inherit" onClick={this.saveTimetable}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="grey text-center">
                        {fetchingData ? (<div className="centered-loader"><CircularProgress /></div>) :
                            (<div className="container">
                                <div className="py-4 grey">
                                    <Paper elevation={3} className="p-4 mt-3 mb-3">
                                        <FormGroup display="flex" flexDirection="column">
                                            <ThemeProvider theme={SelectTheme}>
                                                <TextField className={helperTextDescription} id="timetable-description-create-input" inputProps={{ maxLength: 100 }}
                                                    value={description} label={resources["Description"]} helperText={resources["InputOfDescriptionIsMandatory"]} variant="outlined" onChange={(e) => this.handleChangeDescription(e.target.value)} />
                                            </ThemeProvider>                                              <FormGroup row>
                                                <FormControlLabel control={<Checkbox checked={isActive} onChange={this.handleChangeIsActive} name="timetable-is-active-edit-checkbox" color="primary" />} label={resources["IsActive"]} />
                                            </FormGroup>
                                        </FormGroup>
                                        <Divider className="mt-2 mb-4" />
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <Typography variant="h6"> </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="h6" variant="caption" display="block">{resources["TimetableCreationDayStartsAt"]}<span className="validation-red"> *</span></Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="h6" variant="caption" display="block">{resources["TimetableCreationDayEndsAt"]}<span className="validation-red"> *</span></Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="h6" variant="caption" display="block">{resources["TimetableCreationLunchStartsAt"]}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="h6" variant="caption" display="block">{resources["TimetableCreationLunchEndsAt"]}</Typography>
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                            {this.renderDay("monday", monday, true)}
                                            {this.renderDay("tuesday", tuesday, false)}
                                            {this.renderDay("wednesday", wednesday, false)}
                                            {this.renderDay("thursday", thursday, false)}
                                            {this.renderDay("friday", friday, false)}
                                            {this.renderDay("saturday", saturday, false)}
                                            {this.renderDay("sunday", sunday, false)}
                                        </Grid>
                                    </Paper>
                                </div>
                            </div>)}
                    </div>
                </Dialog>
            </div>
        )

    }
}