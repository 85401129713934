import { DisplayMessage } from "../helpers";
import { DeleteRequest, GetRequest, UpdateRequest } from "../requestHelpers";
import { HttpMethods } from "../../utils/constants";
import {
  DeleteAssetGroupUrl,
  GetAssetGroupsUrl,
  CreateAssetGroupUrl,
  UpdateAssetGroupUrl,
  GetAssetGroupByIdUrl,
} from "../../constants/apiUrls";

export const getAssetGroups = async () => {
  const response = await GetRequest(GetAssetGroupsUrl);
  let result = await response.json();
  if (!response.ok) {
    for (let i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return null;
  }
  return result;
};

export const getAssetGroupById = async (id) => {
  const response = await GetRequest(GetAssetGroupByIdUrl(id));
  let result = await response.json();
  if (!response.ok) {
    for (let i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return null;
  }
  return result;
};

export const updateAssetGroup = async (id, data) => {
  const response = await UpdateRequest(
    UpdateAssetGroupUrl(id),
    data,
    HttpMethods.Patch
  );
  let result = await response.json();
  if (!response.ok) {
    for (let i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return undefined;
  }
  return result;
};

export const deleteAssetGroup = async (id) => {
  const response = await DeleteRequest(DeleteAssetGroupUrl(id));

  if (!response.ok) {
    response.json().then((result) => {
      for (let i = 0; i < result.errors.length; i++) {
        DisplayMessage(result.errors[i], "error");
      }
    });
  }

  return response.ok;
};

export const createAssetGroup = async (data) => {
  const response = await UpdateRequest(
    `${CreateAssetGroupUrl}`,
    data,
    HttpMethods.Post
  );
  let result = await response.json();
  if (!response.ok) {
    for (let i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return undefined;
  }
  return result;
};
