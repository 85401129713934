import React, { Component, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form';
import 'rodal/lib/rodal.css';
import Paper from '@material-ui/core/Paper';
import { Redirect } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Edit from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { SelectTheme, LocalizationTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

const tableIcons = {
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
};

export default class OutletWaitSlotsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            outletId: 0,
            fetchingData: false,
            data: [],
        };
        this.DaysNames = [this.props.resources["Sunday"], this.props.resources["Monday"], this.props.resources["Tuesday"],
        this.props.resources["Wednesday"], this.props.resources["Thursday"], this.props.resources["Friday"], this.props.resources["Saturday"]];
    }

    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid) {
            this.setState({ outletId: newProps.outletId !== undefined ? newProps.outletId : 0 });
            this.getBookingWaitSlots();
        }

        this.setState({ redirect: null });
    }

    componentDidMount() {
        this.getBookingWaitSlots();
    }

    handleChangeOutlet = e => {
        var moboOutletDesc = e.target.selectedIndex !== 0 ? this.props.accessibleMoboOutlets[e.target.selectedIndex - 1].description : "-";
        this.setState({ outletId: e.target.selectedIndex === 0 ? 0 : e.target.value, outletDescription: moboOutletDesc }, () => this.getTableData(this.state.outletId));
    };

    renderOutlets = () => {
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()))
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    getBookingWaitSlots = () => {
        this.setState({ fetchingData: true, data: [] });
        fetch(`/api/mobopro/v1/bookingwaitslots`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) { throw new Error(); }
                return response.json();
            }).then(result => {
                const currentRows = result
                    .sort((x, y) => x.slotHour > y.slotHour ? 1 : -1)
                    .reduce((outletsData, currentWaitSlot) => {
                        const id = currentWaitSlot.moboOutlet.moboOutletId;
                        const day = currentWaitSlot.slotDay;
                        const hour = currentWaitSlot.slotHour;

                        if (!outletsData[id]) {
                            outletsData[id] = {
                                outletId: id,
                                waitSlots: [[], [], [], [], [], [], []]
                            }
                        }

                        outletsData[id].waitSlots[day].push({ hour, numberOfSlots: currentWaitSlot.numberOfSlots, slotId: currentWaitSlot.id });
                        return outletsData;
                    }, {})

                this.setState({
                    data: currentRows,
                    fetchingData: false
                });

                this.getTableData(this.state.outletId);
            });
    }

    getTableData = (outletId) => {
        const { accessibleMoboOutlets } = this.props;

        if (outletId === undefined || outletId === 0) {
            if (accessibleMoboOutlets === undefined) return;
            outletId = null;
        }

        this.setState({ fetchingData: true });

        let initialData = {};
        var resources = this.props.resources;
        initialData.columns = [
            { title: "", width: "5%" }, // added for spacing
            { title: resources["Day"], field: 'day', width: "30%" },
            { title: resources["Hours"], field: 'hours', width: "60%" },
        ];

        initialData.actions = [
            {
                icon: () => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `bookingwaitslots/${this.state.outletId}`, day: rowData.day })
            }
        ];

        if (outletId > 0) {
            var tableData = this.state.data[outletId]?.waitSlots.map((slotData, index) => {
                return { day: this.DaysNames[index], hours: slotData.length > 0 ? slotData.map(x => x.hour).join(", ") : "-" }
            }) ?? this.DaysNames.map((day, index) => {return {day: this.DaysNames[index], hours:"-"}});
            
            initialData.data = tableData;
        }

        this.setState({
            dataGrid: initialData,
            fetchingData: false
        });

    }

    render() {
        const { redirect, fetchingData, outletId } = this.state;
        const { resources } = this.props;

        if (redirect) {
            const dayId = this.DaysNames.indexOf(this.state.day);

            return <Redirect to={{
                pathname: redirect,
                state: { outletId: this.state.outletId, dayId, waitSlots: this.state.data[outletId]?.waitSlots[dayId] ?? [] },
            }}
            />
        }

        return <div>
            <div className="position-relative py-4">
                <h3>{resources["WaitOnPremiseCapacity"]}</h3>
                <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                    <Form.Group className="main-dropdown" onClick={this.handleTyping}>
                        <FormControl className="col-md-12" variant="outlined" onChange={this.handleChangeOutlet}>
                            <ThemeProvider theme={SelectTheme}>
                                <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                            </ThemeProvider>
                            <Select
                                native
                                label={resources["Outlet"]}
                                value={this.state.outletId}
                                inputProps={{
                                    name: 'outlet',
                                    id: 'outlined-outlet-native-simple',
                                }}>
                                <option value={0}>{resources["Select"]}</option>
                                {this.renderOutlets()}
                            </Select>
                        </FormControl>
                    </Form.Group>

                    <div style={{ clear: "both", }}></div>

                    <Paper elevation={3} className="p-3 mt-3">
                        <MuiThemeProvider theme={LocalizationTheme()}>
                            <MaterialTable
                                icons={tableIcons}
                                title={""}
                                localization={{
                                    header: { actions: resources["Actions"] },
                                    body: {
                                        emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                        editTooltip: resources["EditTooltip"],
                                    }
                                }}
                                data={this.state.dataGrid.data}
                                columns={this.state.dataGrid.columns}
                                actions={this.state.dataGrid.actions}
                                options={{ emptyRowsWhenPaging: false, pageSize: 7, filtering: false, search: false, sorting: false, debounceInterval: 700, paging: false }}
                            />
                        </MuiThemeProvider>
                    </Paper>
                </div>
            </div>

        </div>
    }

}