import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage } from '../../../../services/helpers.js';
import DelayedRedirect from '../../../../utils/DelayedRedirect';
import { Transition } from '../../../../utils/ModalHelpers';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';

import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

export default class PauseReasonCreation extends Component {
    constructor(props) {
        super(props);

        var editedPauseReason = undefined;
        var type = undefined;
        var id = 0;

        if (this.props.location !== undefined && this.props.location.state !== undefined) {
            editedPauseReason = this.props.location.state.editedPauseReason;
            id = parseInt(this.props.editedPauseReasonId);
        }
        else if (this.props != undefined) {
            if (this.props.type !== undefined) type = this.props.type;
            if (this.props.editedPauseReasonId !== undefined) id = parseInt(this.props.editedPauseReasonId);
        }

        this.state = {
            id: id,
            type: type,
            description: "",
            modifiedByAvatar: "",
            modifiedByName: "",
            modifiedOn: "",
            editedPauseReason: editedPauseReason,
            loading: this.props.editedPauseReasonId !== undefined,
            redirect: false,
            requestInProgress: true,
            redirect: null,
            open: true,
            pauseReasonWasCreated: false,

        };
    }

    componentDidMount() {
        if (this.props.location !== undefined && this.props.location.state !== undefined && this.props.location.state.editedPauseReason !== undefined) {
            this.getEditegPauseReasonDetails(this.props.location.state.editedPauseReason);
        } else if (this.state.id != undefined && this.state.id > 0) {
            this.getPauseReasonDetails();
        }
    }

    handleClose = () => {
        this.setState({ open: false, redirect: "/pausereasons" });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value }, () => { this.checkIfRequestIsValid(false) });
    };

    handleKeyUp = e => {
        this.setState({ [e.target.name]: e.target.value, requestInProgress: !this.checkIfRequestIsValid(false) })
    };


    checkIfRequestIsValid(displayErrorMessage) {
        let resources = this.props.resources;
        var isValidRequest = true;

        if (this.state.description.trim().length <= 0) {
            if (displayErrorMessage) DisplayMessage(resources["InvalidDescription"], "error");
            isValidRequest = false;
        }

        if (this.state.type === "0") {
            DisplayMessage(resources["InvalidType"], "error");
            return;
        }

        this.setState({ requestInProgress: !isValidRequest });
        return isValidRequest;
    }

    handleSubmit = () => {
        if (this.state.id == 0) {
            this.setState({ requestInProgress: true }, () => this.saveNewPauseReason());
        }
        else {
            this.setState({ requestInProgress: true }, () => this.saveEditedPauseReason());
        }
    }

    saveNewPauseReason = () => {
        this.setState({ requestInProgress: true });

        let resources = this.props.resources;

        if (!this.checkIfRequestIsValid(true)) return;

        var json = JSON.stringify({
            type: this.state.type,
            description: this.state.description,
        });

        fetch('/api/mobopro/v1/pausereasons', {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            if (!response.ok) {
                response.json().then(data => {
                    for (var i = 0; i < data.errors.length; i++) {
                        DisplayMessage(data.errors[i], "error");
                    }
                });
            }
            else {
                DisplayMessage(resources["NewPauseReasonWasSaved"], "success");
                this.setState({ pausereasonWasCreated: true, redirect: "/pausereasons" });
            }

        });

    }

    saveEditedPauseReason = () => {
        this.setState({ requestInProgress: true });

        let resources = this.props.resources;

        if (!this.checkIfRequestIsValid(true)) return;

        var json = JSON.stringify({
            description: this.state.description,
            type: this.state.type
        });

        fetch('/api/mobopro/v1/pausereasons/' + this.state.id, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        }).then(response => {
            response.json().then(result => {
                if (!response.ok) {
                    for (var i = 0; i < result.errors.length; i++) {
                        DisplayMessage(result.errors[i], "error");
                    }
                }
                else {
                    DisplayMessage(resources["PauseReasonEditedSuccessfully"], "success");
                    this.setState({ pausereasonWasCreated: true, redirect: "/pausereasons" });
                }
            })
        });
    }

    getPauseReasonDetails = () => {
        const { id } = this.state;

        let url = `/api/mobopro/v1/pausereasons/`;
        fetch(url,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {
                if (!response.ok) { throw new Error(); }
                return response.json();
            }).then(result => {

                var existsPauseReason = false;
                var editedPauseReason = undefined;

                result.forEach(function (row) {

                    if (row.id === id) {
                        existsPauseReason = true;
                        editedPauseReason = row;
                    }
                });
              
                if (existsPauseReason) {
                    this.setState({
                        id: editedPauseReason.id,
                        type: editedPauseReason.type,
                        description: editedPauseReason.description,
                        modifiedOn: !editedPauseReason.modifiedOn?editedPauseReason.createdOn:editedPauseReason.modifiedOn,
                        modifiedByAvatar: editedPauseReason.modifiedBy?editedPauseReason.modifiedBy.avatar:editedPauseReason.createdBy.avatar,
                        modifiedByName: editedPauseReason.modifiedBy?`${ editedPauseReason.modifiedBy.firstName} ${editedPauseReason.modifiedBy.lastName}`:`${ editedPauseReason.createdBy.firstName} ${editedPauseReason.createdBy.lastName}`,
                        requestInProgress: false,
                        loading: false
                    });
                } else {
                    this.setState({ open: false, redirect: "/pausereasons" });
                }
            });
    }

    getEditegPauseReasonDetails = (editedPuaseReason) => {
        this.setState({
            id: editedPuaseReason.id,
            type: editedPuaseReason.type,
            description: editedPuaseReason.description,
            modifiedOn: !editedPuaseReason.modifiedOn?editedPuaseReason.createdOn:editedPuaseReason.modifiedOn,
            modifiedByAvatar: editedPuaseReason.modifiedBy?editedPuaseReason.modifiedBy.avatar:editedPuaseReason.createdBy.avatar,
            modifiedByName: editedPuaseReason.modifiedBy?`${ editedPuaseReason.modifiedBy.firstName} ${editedPuaseReason.modifiedBy.lastName}`:`${ editedPuaseReason.createdBy.firstName} ${editedPuaseReason.createdBy.lastName}`,
            requestInProgress: false,
            loading: false,
        });
    }



    renderModifiedByInfo = () => {
        let resources = this.props.resources;
        const { modifiedOn, modifiedByName, modifiedByAvatar } = this.state;

        if (this.state.id > 0) {
            return (<Form.Group style={{ display: "flex" }}>
                <div className="w-100">
                    <span className=" mr-2">
                        <img className="rounded-circle" alt="user-avatar" src={modifiedByAvatar != null ? modifiedByAvatar : "/static/media/defaultavatar.02624da3.png"} width="35" />
                    </span>
                    <span>
                        {`${resources["ModifiedOn"]} ${moment(modifiedOn).format('DD/MM/YYYY HH:mm').toLocaleString()} ${resources["By"]} ${modifiedByName}`}
                    </span>
                </div>
            </Form.Group>);
        }

        return;
    }

    renderForm() {
        const handleSubmit = (event) => {
            const form = event.currentTarget;
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit();
        };
        const { description, type } = this.state;

        var resources = this.props.resources;
        var helperTextDescription = description.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";


        return (
            <div>

                <Paper elevation={3} className="p-4 mt-3 mb-3">
                    <Form onSubmit={handleSubmit} >
                        <Form.Group className="d-flex">
                            <div className="col-3"></div>
                            <FormControl className="col-6 helper-text-red" disabled={this.state.id > 0} variant="outlined" onChange={this.handleChange}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-type-native-simple">{resources["Type"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    id="pausereason-type-dropdown"
                                    native
                                    label={resources["Type"]}
                                    value={type}
                                    inputProps={{
                                        name: 'type',
                                        id: 'pausereason-type',
                                    }}>
                                    <option value={0}>{resources["SelectType"]}</option>
                                    <option value={3} key={3}>{resources["Dossiers"]}</option>
                                    <option value={4} key={4}>{resources["RepairOrderItems"]}</option>

                                </Select>
                            </FormControl>
                            <div className="col-3"></div>
                        </Form.Group>

                        <Form.Group className="pt-3" style={{ display: "flex" }} onClick={this.handleTyping}>
                            <div className="col-3"></div>
                            <ThemeProvider theme={SelectTheme}>
                                <TextField className={"col-6 " + helperTextDescription} id="pausereason-description" label={resources["Description"]}
                                    helperText={resources["InputOfDescriptionIsMandatory"]} value={description}
                                    variant="outlined" name="description" onChange={this.handleChange} onKeyUp={this.handleKeyUp} inputProps={{ maxLength: 25 }} />
                            </ThemeProvider >
                        </Form.Group>

                        {this.renderModifiedByInfo()}
                    </Form>
                </Paper>
            </div >
        );
    }

    renderMain() {
        const { loading } = this.state;

        if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

        return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
            {this.renderForm()}
        </div>;
    }

    render() {
        const { redirect, open, pausereasonWasCreated, outletId, id, requestInProgress, type } = this.state;
        const { resources } = this.props;

        return (
            <div>
                {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: pausereasonWasCreated, outletId, type } }} delay={250} />}
                <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="position-sticky ">
                        <Toolbar>
                            <IconButton id="close-add-pausereason" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: 1 }}>
                                {id != undefined && id > 0 ? resources["EditPauseReasonTitle"] : resources["AddNewPauseReasonTitle"]}
                            </Typography>
                            <Button id="pausereason-save" color="inherit" disabled={requestInProgress} onClick={this.handleSubmit}>{resources["Save"]}</Button>
                        </Toolbar>
                    </AppBar>
                    <div className="container">
                        {this.renderMain()}
                    </div>
                </Dialog>
            </div >
        );
    }
}