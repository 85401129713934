import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import DelayedRedirect from '../../../../utils/DelayedRedirect';
import { Transition } from '../../../../utils/ModalHelpers';
import { SelectTheme, } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { TimePicker } from 'antd';
import { DisplayMessage, ValidateIntegerFromInputTypeNumber } from '../../../../services/helpers';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import moment from 'moment';
import 'toastr/build/toastr.min.css';

import DeleteIcon from '@material-ui/icons/Delete';

const format = 'HH:mm';

export default class OutletWaitSlotsEditing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      open: true,
      outletDescription: "",
      addedWaitSlotHour: null,
      addedWaitSlotNumberOfSlots: null,
      showAddWaitSlot: false,
      waitSlots: [],
      refreshGrid: false,
      showDelete: false,
      requestInProgress: false,
      deletedWaiSlot: 0
    }

    this.DaysNames = [this.props.resources["Sunday"], this.props.resources["Monday"], this.props.resources["Tuesday"],
    this.props.resources["Wednesday"], this.props.resources["Thursday"], this.props.resources["Friday"], this.props.resources["Saturday"]];
  }


  componentDidMount() {
    this.setState({
      outletDescription: this.props.accessibleMoboOutlets?.find(x => x.id == this.props.outletId)?.description ?? "",
      day: this.DaysNames[this.props.dayId],
      waitSlots: this.props.waitSlots ?? []
    });

  }

  handleClose = () => {
    this.setState({ open: false, redirect: "/bookingwaitslots" });
  };

  saveWaitSlot = () => {
    const { addedWaitSlotHour, addedWaitSlotNumberOfSlots } = this.state;
    const { outletId, dayId, resources } = this.props;

    const payload = { moboOutletId: outletId, slotDay: dayId, slotHour: addedWaitSlotHour, numberOfSlots: addedWaitSlotNumberOfSlots };

    this.setState({ updateInProgress: true }, () => {
      fetch(`/api/mobopro/v1/bookingwaitslot`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'PublicKey': localStorage.getItem('publicKey'),
          'Authorization-token': localStorage.getItem('authorizationToken'),
        }),
        body: JSON.stringify(payload)
      }).then(response => {
        response.json().then(data => {
          if (!response.ok) {
            var errors = data.errors
            for (var i = 0; i < errors.length; i++) {
              DisplayMessage(errors[i], "error", 0);
            }
          }
          else {
            DisplayMessage(resources["WaitSlotAddedSuccessfully"], "success", 0);
            this.setState({
              waitSlots: [...this.state.waitSlots, { slotId: data.id, hour: data.slotHour, numberOfSlots: data.numberOfSlots }]
            });
          }
          this.setState({ refreshGrid: true, updateInProgress: false, showAddWaitSlot: false, addedWaitSlotHour: null, addedWaitSlotNumberOfSlots: null });
        })
      });
    })
  }

  closeAddWaitSlot = () => {
    this.setState({ showAddWaitSlot: false, addedWaitSlotHour: null, addedWaitSlotNumberOfSlots: null });
  }

  handleCloseDelete = () => this.setState({ showDelete: false })

  patchNumberOfSlots = (slotId, numberOfSlots) => {
    const { resources } = this.props;
    const payload = { numberOfSlots: parseInt(numberOfSlots, 10) };
    this.setState({ requestInProgress: true })
    fetch(`/api/mobopro/v1/bookingwaitslots/${slotId}`, {
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/json',
        'PublicKey': localStorage.getItem('publicKey'),
        'Authorization-token': localStorage.getItem('authorizationToken'),
      }),
      body: JSON.stringify(payload)
    }).then(response => {
      response.json().then(data => {
        if (!response.ok) {
          var errors = data.errors
          for (var i = 0; i < errors.length; i++) {
            DisplayMessage(errors[i], "error", 0);
          }
        }
        else {
          DisplayMessage(resources["WaitSlotEditedSuccessfully"], "success", 0);

          var updatedWaitSlot = this.state.waitSlots.find(item => item.slotId == slotId);
          updatedWaitSlot.numberOfSlots = parseInt(numberOfSlots);
          var updatedWaitSlots = this.state.waitSlots.filter(item => item.slotId != slotId);
          var sortedWaitSlots = [...updatedWaitSlots, updatedWaitSlot].sort((x, y) => x.hour > y.hour ? 1 : -1);

          this.setState({ waitSlots: sortedWaitSlots, requestInProgress: false });
        }
      })
    });
  }

  handleChangeNumberOfSlots = (slotId, numberOfSlots) => {
    if (!numberOfSlots || !slotId || this.state.requestInProgress) return;

    const delayInputTimeoutId = setTimeout(() => {
      this.patchNumberOfSlots(slotId, numberOfSlots);
    }, 300);
    return () => clearTimeout(delayInputTimeoutId);
  }

  deleteWaitSlot = () => {
    if (this.state.deletedWaiSlot > 0) {
      const { resources } = this.props;

      this.setState({ requestInProgress: true });
      const url = `/api/mobopro/v1/bookingwaitslot/${this.state.deletedWaiSlot}`;
      fetch(url, {
        method: 'DELETE',
        headers: new Headers({
          'PublicKey': localStorage.getItem('publicKey'),
          'Authorization-token': localStorage.getItem('authorizationToken'),
        }),
      }).then(response => {
        if (!response.ok) {
          if (response.statusText.length > 0) {
            DisplayMessage(response.statusText, "error");
            return response.json();
          }

          for (var i = 0; i < response.errors.length; i++) {
            DisplayMessage(response.errors[i], "error");
          }
        }
      }).then(result => {
        DisplayMessage(resources["WaitSlotWasDeleted"], "success");
        this.setState({ waitSlots: this.state.waitSlots.filter(item => item.slotId !== this.state.deletedWaiSlot), refreshGrid: true, requestInProgress: false });
        this.handleCloseDelete();
      });
    }
  }

  renderAddWaitSlot = () => {
    const { resources } = this.props;
    const { showAddWaitSlot } = this.state;
    if (showAddWaitSlot)
      return (
        <Form.Group className="d-flex" >
          <ThemeProvider theme={SelectTheme}>
            <div className="col-3"></div>

            <TimePicker minuteStep={5}
              placeholder={resources["SelectTime"]} format={format} className={"col-2 mx-1"}
              variant="outlined" defaultValue={this.state.addedWaitSlotHour !== null ? moment(this.state.addedWaitSlotHour, format) : null}
              onChange={(time, timeString) => this.setState({ addedWaitSlotHour: timeString })} />

            <TextField className={"col-2 mx-1"} id="numberOfSlots-new" inputProps={{ pattern: "[0-9]*", min: 0, inputMode: "numeric", className: "fontSizeOverride" }} name="nrOfSlots" type="number"
              onKeyDown={ValidateIntegerFromInputTypeNumber(true)}
              variant="outlined" onChange={(e) => this.setState({ addedWaitSlotNumberOfSlots: e.target.value })} />

            <div className="col-2 px-2 text-left">
              <IconButton size="small"><CheckIcon onClick={() => this.saveWaitSlot()} /></IconButton>
              <IconButton size="small"><CloseIcon onClick={() => this.closeAddWaitSlot()} /></IconButton>
            </div>
          </ThemeProvider>
        </Form.Group>)
    else return;
  }

  toggleAddWaitSlot = () => {
    this.setState({ showAddWaitSlot: true, addedWaitSlotHour: null, addedWaitSlotNumberOfSlots: null });
  }

  renderWaitSlots = () => {
    var waitSlots = this.state.waitSlots.map((waitSlot, index) =>
      <Form.Group className="d-flex" >
        <ThemeProvider theme={SelectTheme}>
          <div className="col-3"></div>

          <TimePicker format={format} value={moment(waitSlot.hour, format)} disabled className="col-2 mx-1" variant="outlined" />

          <TextField className="col-2 mx-1"
            id={`numberOfSlots-${waitSlot.slotId}`}
            inputProps={{ pattern: "[0-9]*", min: 0, inputMode: "numeric", className: "fontSizeOverride" }}
            defaultValue={waitSlot.numberOfSlots}
            name="nrOfSlots"
            type="number"
            variant="outlined"
            onChange={(e) => this.handleChangeNumberOfSlots(waitSlot.slotId, e.target.value)}
            onKeyDown={ValidateIntegerFromInputTypeNumber(true)}
            key={`numberOfSlots-${waitSlot.slotId}-${waitSlot.numberOfSlots}`}
          />

          <IconButton size="small" onClick={() => this.setState({ showDelete: true, deletedWaiSlot: waitSlot.slotId })}><DeleteIcon /></IconButton>

        </ThemeProvider>
      </Form.Group>
    )
    return waitSlots;
  }

  render() {
    const { redirect, open, refreshGrid } = this.state;
    const { resources, outletId } = this.props;

    return (
      <div>
        {redirect && <DelayedRedirect to={{ pathname: redirect, state: { outletId, refreshGrid } }} delay={250} />}
        <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
          <AppBar className="position-sticky">
            <Toolbar>
              <IconButton id="close-add-waitslots" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ flex: 1 }}>
                {resources["EditCapacity"]}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="container">
            <div>
              <div className="d-flex mt-5">
                <div className="col-3"></div>
                <div className="col-6 px-0 mx-1 text-left">
                  <h6>{resources["Outlet"]} : {this.state.outletDescription}</h6>
                  <h6>{resources["Day"]} : {this.state.day}</h6>
                </div>
              </div>
              <div className="d-flex my-2">
                <div className="col-3"></div>
                <div className="col-2 mx-1 px-0">{resources["Hour"]}</div>
                <div className="col-2 mx-1 px-0">{resources["NumberOfSlots"]}</div>
              </div>
              {this.renderWaitSlots()}
              {this.renderAddWaitSlot()}
              {this.state.showAddWaitSlot ? "" :
                <div className="d-flex my-2">
                  <div className="col-9 px-0 text-right">
                    <Button id="add-language" variant="contained" color="primary" onClick={(e) => { this.toggleAddWaitSlot(e) }}>
                      <i className="fas fa-plus mr-2" title={resources["AddTimeSlot"]} />
                      {resources["AddTimeSlot"]}
                    </Button>
                  </div>
                </div>
              }
            </div>
          </div>

          <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
          }}>
            <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteWaitSlot"]}</div>
            <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
              <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                onClick={this.deleteWaitSlot} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>

            </div>
            <span className="rodal-close"></span>
          </Rodal>

        </Dialog>
      </div >
    );
  }
}