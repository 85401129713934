import React from 'react';
import { RestrictedRoute } from '../utils/RestrictedRoute';
import OutletWaitSlotsTable from '../components/WorkshopPlanning/General/BookingWaitSlots/OutletWaitSlotsTable';
import OutletWaitSlotsEditing from '../components/WorkshopPlanning/General/BookingWaitSlots/OutletWaitSlotsEditing';
import { Permissions } from '../utils/Permissions';
import { Route, Switch } from 'react-router';

export default function OutletWaitSlotsRouting(props) {
  const { currentIdentity, resources } = props;

  return (
    <>
      <RestrictedRoute path='/bookingwaitslots' userPermissions={currentIdentity.permissions} routePermissions={[Permissions.WorkshopSettings]} resources={resources} render={(props) => {
        return (<OutletWaitSlotsTable outletId={props && props.location && props.location.state && props.location.state.outletId}
          resources={resources}
          refreshGrid={props && props.location && props.location.state && props.location.state.refreshGrid}
          accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets} />)
      }} />
      <Switch>
        <Route exact path='/bookingwaitslots/:outletId' render={(props) => {
          return (<OutletWaitSlotsEditing resources={resources}
            outletId={props && props.location && props.location.state && props.location.state.outletId}
            dayId={props && props.location && props.location.state && props.location.state.dayId}
            accessibleMoboOutlets={currentIdentity.accessibleMoboOutlets}
            waitSlots={props && props.location && props.location.state && props.location.state.waitSlots}
            {...props} />)
        }} />
      </Switch>

    </>
  )
}