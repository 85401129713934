import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import 'toastr/build/toastr.min.css';
import { DisplayMessage, GetPickerCss } from '../../../../services/helpers.js';
import DelayedRedirect from '../../../../utils/DelayedRedirect';
import { Transition } from '../../../../utils/ModalHelpers';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import { SketchPicker } from 'react-color';

import 'toastr/build/toastr.min.css';
import Select from '@material-ui/core/Select';


import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { SelectTheme } from '../../../../utils/utils.js';
import { ThemeProvider } from "@material-ui/core";

export default class TagCreation extends Component {
  constructor(props) {
    super(props);

    var editedTag = undefined;
    var outletId = 0;
    var type = 3;
    var editedTagId = 0;
    var id = 0;

    if (this.props.location !== undefined && this.props.location.state !== undefined) {
      editedTag = this.props.location.state.editedTag;
      editedTagId = this.props.location.state.tagId;
      id = parseInt(this.props.tagId);
    }
    else if (this.props !== undefined) {
      outletId = this.props.outletId;
      if (this.props.type !== undefined) type = this.props.type;
      if (this.props.tagId !== undefined) id = parseInt(this.props.tagId);
    }

    this.state = {
      id: id,
      type: type,
      description: "",
      outletId: outletId,
      isPause: false,
      isVisibleInPlanning: this.props.isVisibleInPlanning,
      color: "#FFFFFF",
      modifiedByAvatar: "",
      modifiedByName: "",
      modifiedOn: "",
      editedTag: editedTag,
      editedTagId: editedTagId,

      loading: this.props.editedTagId !== undefined,
      redirect: false,
      accessibleMoboOutlets: [],
      requestInProgress: true,
      redirect: null,
      open: true,
      tagWasCreated: false,
      displayColorPicker: false,
    };
  }

  componentDidMount() {
    if (this.props.location !== undefined && this.props.location.state !== undefined && this.props.location.state.editedTag !== undefined) {
      this.getEditegTagDetails(this.props.location.state.editedTag);
    } else if (this.state.id != undefined && this.state.id > 0) {
      this.getTagDetails();
    }
  }

  handleClose = () => {
    this.setState({ open: false, redirect: "/tags", type: this.state.type, outletId: this.state.outletId });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value, requestInProgress: !this.checkIfRequestIsValid(false) });
  };

  handleTypeChange = e => {
    this.setState({ [e.target.name]: parseInt(e.target.value), requestInProgress: !this.checkIfRequestIsValid(false) });
  };

  handleChangeCheckBox = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleClickColorPicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleCloseColorPicker = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChangeColorPicker = (color) => {
    this.setState({ color: color.hex })
  };

  handleKeyUp = e => {
    this.setState({ [e.target.name]: e.target.value, requestInProgress: !this.checkIfRequestIsValid(false) })
  };

  handleChangeOutlet = e => {
    this.setState({ outletId: parseInt(e.target.value), requestInProgress: !this.checkIfRequestIsValid(false) });
  };

  checkIfRequestIsValid(displayErrorMessage) {
    let resources = this.props.resources;
    var isValidRequest = true;

    if (this.state.description.trim().length <= 0) {
      if (displayErrorMessage) DisplayMessage(resources["InvalidDescription"], "error");
      isValidRequest = false;
    }

    if (this.state.type === 0) {
      DisplayMessage(resources["InvalidType"], "error");
      return;
    }

    if (this.state.outletId < 0) {
      DisplayMessage(resources["InvalidOutlet"], "error");
      return;
    }

    return isValidRequest;
  }

  handleSubmit = () => {
    if (this.state.id == 0) {
      this.setState({ requestInProgress: true }, () => this.saveNewtag());
    }
    else {
      this.setState({ requestInProgress: true }, () => this.saveEditedTag());
    }
  }

  saveNewtag = () => {
    this.setState({ requestInProgress: true });

    let resources = this.props.resources;

    if (!this.checkIfRequestIsValid(true)) return;

    var json = JSON.stringify({
      type: this.state.type,
      moboOutletId: this.state.outletId > 0 ? this.state.outletId : null,
      colorHex: this.state.color.replace("#", ""),
      description: this.state.description,
      isPause: this.state.isPause,
      isVisibleInPlanning: this.state.isVisibleInPlanning
    });

    fetch('/api/mobopro/v2/tags', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'PublicKey': localStorage.getItem('publicKey'),
        'Authorization-token': localStorage.getItem('authorizationToken'),
      }),
      body: json
    }).then(response => {
      response.json().then(result => {
        if (!response.ok) {
          response.json().then(result => {
            for (var i = 0; i < result.errors.length; i++) {
              DisplayMessage(result.errors[i], "error");
            }
          });
        }
        else {
          DisplayMessage(resources["NewTagWasSaved"], "success");
          this.setState({ tagWasCreated: true, redirect: "/tags" });
        }
      })
    });
  }

  saveEditedTag = () => {
    this.setState({ requestInProgress: true });

    let resources = this.props.resources;

    if (!this.checkIfRequestIsValid(true)) return;

    var json = JSON.stringify({
      colorHex: this.state.color.replace("#", ""),
      description: this.state.description,
      isPause: this.state.isPause,
      isVisibleInPlanning: this.state.isVisibleInPlanning
    });

    fetch('/api/mobopro/v2/tags/' + this.state.id, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'PublicKey': localStorage.getItem('publicKey'),
        'Authorization-token': localStorage.getItem('authorizationToken'),
      }),
      body: json
    }).then(response => {
      response.json().then(result => {
        if (!response.ok) {
          for (var i = 0; i < result.errors.length; i++) {
            DisplayMessage(result.errors[i], "error");
          }
        }
        else {
          DisplayMessage(resources["TagEditedSuccessfully"], "success");
          this.setState({ tagWasCreated: true, redirect: "/tags" });
        }
      })
    });
  }

  getTagDetails = () => {
    const { id } = this.state;

    let url = `/api/mobopro/v2/tags`;
    fetch(url,
      {
        method: 'get',
        headers: new Headers({
          'PublicKey': localStorage.getItem('publicKey'),
          'Authorization-token': localStorage.getItem('authorizationToken'),
        }),
      })
      .then(response => {
        if (!response.ok) { throw new Error(); }
        return response.json();
      }).then(result => {

        var existsTag = false;
        var editedTag = undefined;

        result.forEach(function (row) {

          if (row.id === id) {
            existsTag = true;
            editedTag = row;
          }
        });

        if (existsTag) {
          this.setState({
            id: editedTag.id,
            type: editedTag.type,
            description: editedTag.description,
            outletId: editedTag.moboOutletId,
            isPause: editedTag.isPause,
            color: "#" + editedTag.colorHex,
            modifiedOn: editedTag.modifiedOn,
            modifiedByAvatar: editedTag.modifiedByAvatar,
            modifiedByName: editedTag.modifiedByName,
            requestInProgress: false,
            isVisibleInPlanning: editedTag.isVisibleInPlanning
          });
        } else {
          this.setState({ open: false, redirect: "/tags" });
        }
      });
  }

  getEditegTagDetails = (editedTag) => {
    this.setState({
      id: editedTag.id,
      type: editedTag.type,
      description: editedTag.description,
      outletId: editedTag.moboOutletId,
      isPause: editedTag.isPause,
      color: "#" + editedTag.colorHex,
      modifiedOn: editedTag.modifiedOn,
      modifiedByAvatar: editedTag.modifiedByAvatar,
      modifiedByName: editedTag.modifiedByName,
      requestInProgress: false,
      isVisibleInPlanning: editedTag.isVisibleInPlanning
    });
  }

  renderOutlets = () => {

    var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)
    if (this.props.accessibleMoboOutlets === undefined) return;

    var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
    return outletOptions;
  }

  renderModifiedByInfo = () => {
    let resources = this.props.resources;
    const { editedTag, modifiedOn, modifiedByName, modifiedByAvatar } = this.state;

    if (this.state.id > 0) {
      return (<Form.Group style={{ display: "flex" }}>
        <div className="w-100">
          <span className=" mr-2">
            <img className="rounded-circle" alt="user-avatar" src={modifiedByAvatar != null ? modifiedByAvatar : "/static/media/defaultavatar.02624da3.png"} width="35" />
          </span>
          <span>
            {`${resources["ModifiedOn"]} ${moment(modifiedOn).format('DD/MM/YYYY HH:mm').toLocaleString()} ${resources["By"]} ${modifiedByName}`}
          </span>
        </div>
      </Form.Group>);
    }

    return;
  }

  renderForm() {
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    };
    const { description, isPause, outletId, type, isVisibleInPlanning } = this.state;

    var resources = this.props.resources;
    var helperTextDescription = description.trim().length <= 0 ? "helper-text-red" : "helper-text-grey";
    const styles = GetPickerCss(this.state.color);

    return (
      <div>

        <Paper elevation={3} className="p-4 mt-3 mb-3">
          <Form onSubmit={handleSubmit} >
            <Form.Group className="d-flex">
              <div className="col-3"></div>
              <FormControl className="col-6 helper-text-red" disabled={this.state.id > 0} variant="outlined" onChange={this.handleTypeChange}>
                <ThemeProvider theme={SelectTheme}>
                  <InputLabel htmlFor="outlined-type-native-simple">{resources["Type"]}</InputLabel>
                </ThemeProvider>
                <Select
                  id="tag-type-dropdown"
                  native
                  label={resources["Type"]}
                  value={type}
                  inputProps={{
                    name: 'type',
                    id: 'tag-type',
                  }}>
                  <option value={3} key={3}>{resources["Dossiers"]}</option>
                  <option value={4} key={4}>{resources["RepairOrderItems"]}</option>
                  <option value={5} key={5}>{resources["Warranty"]}</option>
                </Select>
              </FormControl>
              <div className="col-3"></div>
            </Form.Group>

            <Form.Group className="pt-3" style={{ display: "flex" }} onClick={this.handleTyping}>
              <div className="col-3"></div>
              <ThemeProvider theme={SelectTheme}>
                <TextField className={"col-6 " + helperTextDescription} id="tag-description" label={resources["Description"]}
                  helperText={resources["InputOfDescriptionIsMandatory"]} value={description}
                  variant="outlined" name="description" onChange={this.handleChange} onKeyUp={this.handleKeyUp} inputProps={{ maxLength: 25 }} />
              </ThemeProvider >
            </Form.Group>

            <Form.Group className="d-flex" >
              <div className="col-3"></div>
              <FormControl className="col-6 helper-text-red" disabled={this.state.id > 0} variant="outlined" onChange={this.handleChangeOutlet}>
                <ThemeProvider theme={SelectTheme}>
                  <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                </ThemeProvider >
                <Select
                  id="tag-outlets-dropdown"
                  native
                  label={resources["Outlet"]}
                  value={outletId !== undefined ? outletId : 0}
                  inputProps={{
                    name: 'outlet',
                    id: 'outlined-outlet-native-simple',
                  }}>
                  <option value={0}>{resources["All"]}</option>
                  {this.renderOutlets()}
                </Select>
              </FormControl>
              <div className="col-3"></div>
            </Form.Group>
            <Form.Group style={{ display: "flex" }} >
              <div className="col-md-3 "> </div>
              <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                control={<Checkbox id="tag-is-pause" checked={isPause} onChange={this.handleChangeCheckBox} name="isPause" color="primary" />}
                label={resources["IsPause"]} />
              <div className="col-md-4"> </div>
            </Form.Group>

            {type === 3 ? (<Form.Group style={{ display: "flex" }} >
              <div className="col-md-3 "> </div>
              <FormControlLabel className="col-md-6 text-right label-mt my-auto p-0"
                control={<Checkbox id="tag-is-visible-in-planning" checked={isVisibleInPlanning} onChange={this.handleChangeCheckBox} name="isVisibleInPlanning" color="primary" />}
                label={resources["IsVisibleInPlanning"]} />
              <div className="col-md-4"> </div>
            </Form.Group>) : ""}

            <Form.Group style={{ display: "flex" }} onClick={this.handleTyping}>
              <div className="col-md-3"></div>
              <div>
                <div style={{ display: "flex" }}>
                  <div style={styles.swatch} onClick={this.handleClickColorPicker}>
                    <div style={styles.color} id="color-picker" />
                  </div>
                  <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 align-center" style={{ paddingTop: "8px", paddingLeft: "3px" }} >{resources["ColorHex"]}
                    <em className="text-danger">*</em></span>
                </div>
                {this.state.displayColorPicker ? <div style={styles.popover}>
                  <div style={styles.cover} onClick={this.handleCloseColorPicker} />
                  <SketchPicker color={this.state.color} onChange={this.handleChangeColorPicker} />
                </div> : null}
              </div>
            </Form.Group>
            {this.renderModifiedByInfo()}
          </Form>
        </Paper>
      </div >
    );
  }

  renderMain() {
    const { loading } = this.state;

    if (loading) return (<div className="centered-loader"><CircularProgress /></div>);

    return <div className={"py-4 grey " + (this.state.loading ? 'fadeOut' : 'fadeIn')} style={{ textAlign: "center" }}>
      {this.renderForm()}
    </div>;
  }

  render() {
    const { redirect, open, tagWasCreated, outletId, id, requestInProgress, type } = this.state;
    const { resources } = this.props;

    return (
      <div>
        {redirect && <DelayedRedirect to={{ pathname: redirect, state: { refreshGrid: tagWasCreated, outletId, type } }} delay={250} />}
        <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
          <AppBar className="position-sticky ">
            <Toolbar>
              <IconButton id="close-add-tag" edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ flex: 1 }}>
                {id != undefined && id > 0 ? resources["EditTagTitle"] : resources["AddNewTagTitle"]}
              </Typography>
              <Button id="tag-save" color="inherit" disabled={requestInProgress} onClick={this.handleSubmit}>{resources["Save"]}</Button>
            </Toolbar>
          </AppBar>
          <div className="container">
            {this.renderMain()}
          </div>
        </Dialog>
      </div >
    );
  }
}