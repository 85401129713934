import { Box } from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { DisplayMessage } from "../../../services/helpers";
import { useStyles } from "../../../styles/styles";
import TableComponent from "../../Common/Table/TableComponent.js";
import DeleteConfirmationModal from "../../Common/Modals/DeleteConfirmationModal.js";
import { getAssetGroups } from "../../../services/AssetGroups/assetGroupApisService.js";
import AddItemButton from "../../Common/Buttons/AddItemButton.js";
import { deleteAssetGroup } from "../../../services/AssetGroups/assetGroupApisService";
import Header from "../../Common/Headers/Header.js";
import { useHistory } from "react-router-dom";

const AssetGroupsTable = ({ resources, refreshGrid }) => {
  const classes = useStyles();
  const [assetGroups, setAssetGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedAssetGroupId, setSelectedAssetGroupId] = useState(null);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const history = useHistory();
  const tableSettings = {};

  tableSettings.actions = [
    {
      icon: () => <Edit />,
      tooltip: resources["Edit"],
      onClick: (event, rowData) => {
        history.push({
          pathname: `/assetgroups/${rowData.id}`,
          state: { assetGroupId: rowData.id },
        });
      },
    },
    {
      icon: () => <DeleteOutline />,
      tooltip: resources["Delete"],
      onClick: (event, rowData) => handleDelete(rowData.id),
    },
  ];

  tableSettings.columns = [
    {
      title: resources["Description"],
      field: "description",
      cellStyle: {
        width: "80%",
        minWidth: "500px",
      },
      headerStyle: {
        width: "80%",
        minWidth: "500px",
      },
    },
    {
      title: resources["IsActive"],
      field: "isActive",
      cellStyle: {
        width: "10%",
        minWidth: "150px",
      },
      headerStyle: {
        width: "10%",
        minWidth: "150px",
      },
    },
  ];

  const handleDelete = (id) => {
    setSelectedAssetGroupId(id);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedAssetGroupId(null);
    setDeleteModalOpen(false);
  };

  const confirmDelete = async () => {
    if (selectedAssetGroupId) {
      var result = await deleteAssetGroup(selectedAssetGroupId);

      if (result) {
        setAssetGroups((prevAssetGroups) =>
          prevAssetGroups.filter((group) => group.id !== selectedAssetGroupId)
        );
        DisplayMessage(resources["AssetGroupWasDeletedWithSuccess"], "success");
      }
    }
    setSelectedAssetGroupId(null);
    closeDeleteModal();
  };
  const fetchAssetGroups = async () => {
    setLoading(true);
    const data = await getAssetGroups();
    if (data) {
      const formattedData = data.map(({ id, description, isActive }) => ({
        id,
        description,
        isActive,
      }));
      setAssetGroups(formattedData);
    } else {
      setAssetGroups([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isFirstTime || refreshGrid) {
      fetchAssetGroups();
      setIsFirstTime(false);
    }
  }, [isFirstTime, refreshGrid]);

  return (
    <Box>
      <Box className="position-relative py-4">
        <Header title={resources["AssetGroups"]} loading={loading} />
        {!loading && (
          <>
            <AddItemButton
              title={resources["AddAssetGroup"]}
              linkPath={"/assetgroups/new"}
            />
            <TableComponent
              resources={resources}
              data={assetGroups}
              tableSettings={tableSettings}
            />
          </>
        )}
        <DeleteConfirmationModal
          onConfirm={confirmDelete}
          resources={resources}
          areYouSureMessage={resources["AreYouSureYouWantToDeleteAssetGroup"]}
          open={deleteModalOpen}
          onClose={closeDeleteModal}
          className={classes.modal}
        />
      </Box>
    </Box>
  );
};

export default AssetGroupsTable;
