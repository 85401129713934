import { DisplayMessage } from "../../../../../services/helpers";
import { DeleteRequest, GetRequest, UpdateRequest } from "../../../../../services/requestHelpers";
import { HttpMethods } from "../../../../../utils/constants";

const baseUrl = "/api/mobopro/v1/mobooutletdisclaimers";

export const getDisclaimers = async (moboOutletId) => {
  const response = await GetRequest(`${baseUrl}?moboOutletId=${moboOutletId !== 0 ? `${moboOutletId}` : ""}`);
  var result = await response.json();
  if (!response.ok) {
    for (var i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return undefined;
  }
  return result;
}

export const copyDisclaimer = async (data) => {
  const response = await UpdateRequest(`${baseUrl}/copy`, data, HttpMethods.Post);
  var result = await response.json();
  if (!response.ok) {
    for (var i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return undefined;
  }
  return result;
}

export const deleteDisclaimer = async (id) => {
  const response = await DeleteRequest(`${baseUrl}/${id}`);

  if (!response.ok) {
    response.json().then(result => {
      for (var i = 0; i < result.errors.length; i++) {
        DisplayMessage(result.errors[i], "error");
      }
    });
  }

  return response.ok;
}

export const createDisclaimer = async (data) => {
  const response = await UpdateRequest(`${baseUrl}`, data, HttpMethods.Post);
  var result = await response.json();
  if (!response.ok) {
    for (var i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return undefined;
  }
  return result;
}