import { Box, Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const AddItemButton = ({ linkPath, title }) => {
  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" sx={{ mb: "10px" }}>
        <Link
          to={{
            pathname: linkPath,
          }}
          style={{ textDecoration: "none" }}
        >
          <Button id="add-asset-group" variant="contained" color="primary">
            <i className="fas fa-plus mr-2" title={title} />
            {title}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default AddItemButton;
