import React, { Component, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Loader from '../../../Loader.js';
import Form from 'react-bootstrap/Form';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Paper from '@material-ui/core/Paper';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { DisplayMessage, GetOutletUserDescription } from '../../../../services/helpers.js';
import FontDownloadRoundedIcon from '@material-ui/icons/FontDownloadRounded';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { SelectTheme, LocalizationTheme } from '../../../../utils/utils.js';
import { Checkbox, FormControlLabel, ThemeProvider } from "@material-ui/core";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    FontDownloadRoundedIcon: forwardRef((props, ref) => <FontDownloadRoundedIcon {...props} ref={ref} />)
};

export default class BookingItemsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            outletId: 0,
            objectType: this.props.objectType,
            outletDescription: "",
            shortDescription: "",
            fetchingData: true,
            showDelete: false,
            showCopy: false,
            deletedItem: 0,
            brands: [],
            copiedBookingItem: undefined,
            selectedOutletsChecked: [],
            selectedOutletsCheckedName: "",
            accessibleMoboOutlets: [],
            editedBookingItemId: undefined,
            editedBookingItem: undefined,
            showEditBookingItem: false,
            requestInProgress: false,
            checkedForData: false,
            bookingItemWasCreated: false

        };
    }
    componentWillReceiveProps(newProps) {
        if (newProps.refreshGrid) {
            this.getData(newProps.outletId === 0 ? null : newProps.outletId);
        }
        this.setState({ redirect: null });
    }

    componentWillMount() {
        this.getData();
    }

    handleChangeOutlet = e => {
        var moboOutletDesc = e.target.selectedIndex !== 0 ? this.props.accessibleMoboOutlets[e.target.selectedIndex - 1].description : "-";
        this.setState({ outletId: e.target.selectedIndex === 0 ? 0 : e.target.value, outletDescription: moboOutletDesc }, () => this.getData(this.state.outletId));
    };

    renderOutlets = () => {
        if (this.props.accessibleMoboOutlets === undefined) return;
        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    getData = (outletId) => {
        this.setState({ fetchingData: true });

        if (outletId === undefined || outletId === 0) {
            if (this.props.accessibleMoboOutlets === undefined) return;
            outletId = null;
        }

        let initialData = {};
        var resources = this.props.resources;
        initialData.columns = [
            { title: resources["Language"], width: "30%", field: 'language', filtering: true, customSort: (a, b) => a.language.toLowerCase().localeCompare(b.language.toLowerCase()) },
            { title: resources["Brand"], width: "50%", field: 'brandDescription', filtering: false, customSort: (a, b) => a.brandDescription.toLowerCase().localeCompare(b.brandDescription.toLowerCase()) },
            { title: resources["Description"], width: "50%", field: 'description', filtering: false, customSort: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()) },
            {
                title: resources["IsExpired"], width: "50%", field: 'isExpired', customSort: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
                filtering: true,
                lookup: { true: resources["Yes"], false: resources["No"] }
            },
        ];

        initialData.actions = [
            {
                icon: () => <Edit />,
                tooltip: resources["Edit"],
                onClick: (event, rowData) => this.setState({ redirect: `bookingitems/${rowData.moboOutletId}/${rowData.id}` })
            },
            {
                icon: () => <DeleteOutline />,
                tooltip: resources["Delete"],
                onClick: (event, rowData) => this.handleShowDelete(rowData.id)
            },

            {
                icon: () => <FileCopyIcon />,
                tooltip: resources["Copy"],
                onClick: (event, rowData) => this.handleShowCopy(rowData)
            },
        ]
        var today = new Date();

        var outletIdParam = outletId > 0 ? outletId : null;
        fetch(`/api/mobopro/v1/bookingItems?moid=${outletIdParam}`,
            {
                method: 'get',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            })
            .then(response => {

                if (!response.ok) {
                    response.json().then(data => {
                        for (var i = 0; i < data.errors.length; i++) {
                            DisplayMessage(data.errors[i], "error");
                        }
                    })
                }
                else {
                    response.json().then(result => {

                        const currentRows = [];
                        result.forEach(function (rowData) {

                            currentRows.push({
                                id: rowData.id,
                                language: rowData.language,
                                description: rowData.shortDescription,
                                moboOutletId: rowData.outlet != null ? rowData.outlet.moboOutletId : 0,
                                brandDescription: rowData.brand != null ? rowData.brand.description : "",
                                isExpired: new Date(rowData.validTill) <= today ? true : false,

                                brandId: rowData.brand != null ? rowData.brand.brnadId : 0,
                                brand: rowData.brand,
                                shortDescription: rowData.shortDescription,
                                longDescription: rowData.longDescription,
                                validFrom: rowData.validFrom,

                                validTill: rowData.validTill,
                                price: rowData.price,
                                operationCode: rowData.operationCode,
                                laborTime: rowData.laborTime,
                                infoURL: rowData.infoURL,
                                isMaintenance: rowData.isMaintenance,
                                isB2CVisible: rowData.isB2CVisible,
                                isQuoteItem: rowData.isQuoteItem,
                                isKeyDropVisible: rowData.isKeyDropVisible,
                                createdOn: rowData.createdOn,
                                createdBy: rowData.createdBy,
                                updatedOn: rowData.updatedOn,
                                updatedBy: rowData.updatedBy,
                            });
                        });

                        initialData.data = currentRows;

                        this.setState({
                            dataGrid: initialData,
                            fetchingData: false,
                            checkedForData: true,
                            outletId: outletId > 0 ? parseInt(outletId) : 0
                        });
                    });
                }
            });
    }

    handleCloseDelete = () => {
        this.setState({ showDelete: false, deletedItem: undefined });
    }

    handleCloseCopy = () => {
        this.setState({
            showCopy: false, copiedBookingItem: undefined, copiedDescription: "", selectedChatGroupsCheckedName: "",
            selectedOutletsChecked: [], requestInProgress: false
        });
    }

    handleEditBookingItem = (itemId, showEditBookingItemDialog) => {
        if (showEditBookingItemDialog === false) { this.getData(this.state.outletId); }
        this.setState({ showEditBookingItem: showEditBookingItemDialog, editedBookingItemId: itemId });
    }

    handleShowDelete = (itemId) => {
        this.setState({ showDelete: true, deletedItem: itemId });
    }

    handleDeleteBookingItem = () => {
        if (this.state.deletedItem > 0) {
            let resources = this.props.resources;
            this.setState({ requestInProgress: true });
            const url = "/api/mobopro/v1/bookingitems/" + this.state.deletedItem;
            fetch(url, {
                method: 'DELETE',
                headers: new Headers({
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                }),
            }).then(response => {
                if (!response.ok) {
                    if (response.statusText.length > 0) {
                        DisplayMessage(response.statusText, "error");
                        return response.json();
                    }

                    for (var i = 0; i < response.errors.length; i++) {
                        DisplayMessage(response.errors[i], "error");
                    }
                }
            }).then(result => {
                DisplayMessage(resources["BookingItemWasDeleted"], "success");
                this.getData(this.state.outletId);
                this.setState({ showDelete: false, deletedItem: 0, requestInProgress: false });
                this.handleCloseDelete();
            });
        }
    }

    handleShowCopy = (item) => {
        var outletDescription = GetOutletUserDescription(this.props.accessibleMoboOutlets, item.moboOutletId);
        this.setState({
            showCopy: true, selectedChatGroupsCheckedName: "", selectedOutletsChecked: [],
            copiedBookingItem: item, toOutletId: 0, outletDescription: outletDescription, shortDescription: item.shortDescription
        });
    }

    handleCopyBookingItem = () => {
        if (this.state.copiedBookingItem === undefined) return;

        this.setState({ requestInProgress: true });
        let resources = this.props.resources;

        var json = JSON.stringify({
            id: this.state.copiedBookingItem.id,
            moboOutletIds: this.state.selectedOutletsChecked
        });

        const url = `/api/mobopro/v1/bookingitems/copy`;
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'PublicKey': localStorage.getItem('publicKey'),
                'Authorization-token': localStorage.getItem('authorizationToken'),
            }),
            body: json
        })
            .then(response => {

                response.json().then(result => {
                    if (!response.ok) {
                        for (var i = 0; i < result.errors.length; i++) {
                            DisplayMessage(result.errors[i], "error");
                        }
                        this.setState({ requestInProgress: false });
                    }
                    else {
                        this.setState({
                            updateInProgress: false,
                            bookingItemWasCreated: true,
                            redirect: "/bookingitems",
                            showCopy: false,
                            copiedBookingItem: undefined,
                            outletDescription: "",
                            toOutletId: 0,
                            requestInProgress: false
                        });

                        DisplayMessage(resources["BookingItemWasCopied"], "success");
                    }
                });
            });
    }

    renderSelectOutlets = () => {
        if (this.props.accessibleMoboOutlets === undefined) return;

        var outlets = this.props.accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1);
        outlets = outlets.map((x, index) =>

            <Form.Group key={index} className=" mb-0 text-left" style={{ display: `${this.state.copiedBookingItem === undefined || x.id !== this.state.copiedBookingItem.moboOutletId ? "block" : 'none'}` }} >
                <FormControlLabel className="col-md-11 text-right label-mt my-auto p-0"
                    control={<Checkbox id="bookingItems-is-active" style={{ padding: "5px 9px!important" }}
                        checked={this.state.selectedOutletsChecked.indexOf(x.id) !== -1}
                        onChange={this.handleSelectOutlet} key={index} name={x.id.toString()} value={x.id} color="primary" />}
                    label={x.description} />
            </Form.Group>
        );
        return outlets;
    }

    // Mobo Outlets
    handleSelectOutlet = e => {
        var { outletId, selectedOutletsChecked, selectedOutletsCheckedName } = this.state;
        var selectedOutletId = parseInt(e.target.name);

        var outletDesc = this.props.accessibleMoboOutlets.find(x => x.id === selectedOutletId).description;

        if (e.target.checked) {
            selectedOutletsCheckedName = selectedOutletsCheckedName + (selectedOutletsCheckedName !== "" ? ", " + outletDesc : outletDesc);
            this.setState({
                selectedOutletsChecked: [...this.state.selectedOutletsChecked, selectedOutletId], selectedOutletsCheckedName, selectedChatGroupsCheckedName: ""
            });
        }
        else {
            var index = selectedOutletsChecked.indexOf(selectedOutletId);
            if (selectedOutletId !== outletId && index !== -1) {
                selectedOutletsChecked = selectedOutletsChecked.filter((_, i) => i !== index);
                selectedOutletsCheckedName = selectedOutletsCheckedName.replace(", " + outletDesc, "").replace(outletDesc + ", ", "").replace(outletDesc, "");
                this.setState({ selectedOutletsChecked, selectedOutletsCheckedName });
            }
        }
    };

    render() {
        const { redirect, fetchingData, outletId, dataGrid, selectedOutletsChecked, shortDescription, outletDescription } = this.state;
        const { resources } = this.props;

        var isOutletChecked = selectedOutletsChecked.length > 0;
        if (redirect) { return <Redirect to={{ pathname: redirect, state: { outletId: outletId === undefined || isNaN(outletId) ? 0 : outletId, bookingItems: dataGrid.data, refreshGrid: true } }} /> }

        return (
            <div>
                <div className="position-relative py-4">
                    <h3>{resources["BookingItems"]}</h3>
                    <div className={"offcentered-loader " + (fetchingData ? 'fadeIn' : 'fadeOut')}><CircularProgress /></div>
                    <div className={"mt-4 " + (fetchingData ? 'fadeOut' : 'fadeIn')}>
                        <Form.Group className="main-dropdown" onClick={this.handleTyping}>
                            <FormControl className="col-md-12" variant="outlined" onChange={this.handleChangeOutlet}>
                                <ThemeProvider theme={SelectTheme}>
                                    <InputLabel htmlFor="outlined-outlet-native-simple">{resources["Outlet"]}</InputLabel>
                                </ThemeProvider>
                                <Select
                                    native
                                    label={resources["Outlet"]}
                                    value={this.state.outletId}
                                    inputProps={{
                                        name: 'outlet',
                                        id: 'outlined-outlet-native-simple',
                                    }}>
                                    <option value={0}>{resources["All"]}</option>
                                    {this.renderOutlets()}
                                </Select>
                            </FormControl>
                        </Form.Group>
                        <div style={{ float: "right", marginBottom: "10px" }}>
                            <Link to={{ pathname: `/bookingitems/new`, state: { outletId: this.state.outletId } }} style={{ textDecoration: 'none' }}>
                                <Button id="add-bookingitem" variant="contained" color="primary">
                                    <i className="fas fa-plus mr-2" title={resources["AddNewBookingItem"]} />
                                    {resources["AddNewBookingItem"]}
                                </Button>
                            </Link >
                        </div>
                        <div style={{ clear: "both", }}></div>

                        <Paper elevation={3} className="p-3 mt-3">
                            <MuiThemeProvider theme={LocalizationTheme()}>
                                <MaterialTable
                                    icons={tableIcons}
                                    title={""}
                                    localization={{
                                        header: { actions: resources["Actions"] },
                                        body: {
                                            emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                                            deleteTooltip: resources["DeleteTooltip"],
                                            editTooltip: resources["EditTooltip"],
                                        },
                                        toolbar: {
                                            searchPlaceholder: resources["Search"],
                                            searchTooltip: resources["Search"]
                                        },
                                        pagination: {
                                            firstTooltip: resources["FirstTooltip"],
                                            lastTooltip: resources["LastTooltip"],

                                            previousTooltip: resources["PreviousTooltip"],
                                            nextTooltip: resources["NextTooltip"],

                                            labelRowsPerPage: resources["LabelRowsPerPage"],
                                            labelRowsSelect: resources["LabelRowsSelect"],
                                        }
                                    }}
                                    data={this.state.dataGrid.data}
                                    columns={this.state.dataGrid.columns}
                                    actions={this.state.dataGrid.actions}
                                    options={{
                                        emptyRowsWhenPaging: false,
                                        pageSize: 10,
                                        filtering: true,
                                        pageSizeOptions: [10, 15, 20],
                                        debounceInterval: 700
                                    }}
                                />
                            </MuiThemeProvider>
                        </Paper>
                    </div>
                    <div className={"loader-overlap position-absolute " + (this.state.fetchingData ? 'fadeIn' : 'fadeOut')}><div className="container"><Loader /></div></div>

                    <Rodal visible={this.state.showDelete} onClose={this.handleCloseDelete} className="rodal" customStyles={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <div className="modal-body" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>{resources["AreYouSureYouWantToDeleteBookingItem"]}</div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <button className="btn btn-secondary" id="btnCancelDelete" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseDelete} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnDelete" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleDeleteBookingItem} disabled={this.state.requestInProgress}>{resources["Yes"]}</button>

                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                    <Rodal visible={this.state.showCopy} onClose={this.handleCloseCopy} height={550}>

                        <div className="modal-body" >{resources["CopyBookingItem"]}</div>

                        <Form.Group style={{ display: "flex" }} >
                            <Form.Label className="col-md-4 text-left label-mt my-auto" >{resources["Description"]}:  </Form.Label>
                            <Form.Label className="col-md-7 text-left label-mt my-auto pl-0 form-label" >{shortDescription}  </Form.Label>
                        </Form.Group>
                        <Form.Group style={{ display: "flex" }} >
                            <Form.Label className="col-md-4 text-left label-mt my-auto" >{resources["From"]}:  </Form.Label>
                            <Form.Label className="col-md-7 text-left label-mt my-auto pl-0 form-label" >{outletDescription}  </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ display: "flex" }} >
                            <Form.Label className="col-md-3 text-left label-mt my-auto" >{resources["To"]}:  </Form.Label>
                        </Form.Group>

                        <Form.Group className=" overflow-auto" style={{ maxHeight: "250px" }}  >
                            <div className="ml-5">
                                {this.renderSelectOutlets()}
                            </div>

                        </Form.Group>

                        <div className="modal-footer" style={{ borderTop: "1px solid white" }}>
                            <button className="btn btn-secondary" id="btnCancelCopy" style={{ backgroundColor: "white", color: "#53B3EA", borderColor: "#53B3EA" }}
                                onClick={this.handleCloseCopy} disabled={this.state.requestInProgress}>{resources["Cancel"]}</button>
                            <button className="btn btn-primary" id="btnCopy" style={{ backgroundColor: "#53B3EA", color: "white", borderColor: "#53B3EA" }}
                                onClick={this.handleCopyBookingItem} disabled={this.state.requestInProgress || !isOutletChecked} >{resources["Ok"]} </button>
                        </div>
                        <span className="rodal-close"></span>
                    </Rodal>

                </div>
            </div >
        )
    };
}